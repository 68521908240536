import React from 'react';
import styled from '@emotion/styled';

import {space, colors} from '@workday/canvas-kit-react/tokens';

import {Table} from '../Table';

interface PropsTableProps {
  of: {
    __PROPS__: [
      {
        defaultValue: null | {value: string};
        description: string;
        name: string;
        required: boolean;
        type: {
          name: string;
        };
        parent: {
          fileName: string;
          name: string;
        };
      }
    ];
    name?: string;
    displayName?: string;
  };
}

const RequiredAstrisk = styled('abbr')({
  color: colors.cinnamon500,
  fontWeight: 700,
  paddingLeft: space.xxxs,
  textDecoration: 'unset',
  position: 'relative',
  top: '-2px',
});

const PropsTable = ({of}: PropsTableProps) => {
  const props = of.__PROPS__ || [];
  if (!of.__PROPS__) {
    console.warn(`Could not find props for ${of.name || of.displayName}`);
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Type</th>
          <th>Default</th>
          <th>Description</th>
        </tr>
      </thead>
      <tbody>
        {props.map(prop => {
          return (
            <tr key={prop.name}>
              <td>
                <code className="blue bold">{prop.name}</code>
                {prop.required && <RequiredAstrisk title="required">*</RequiredAstrisk>}
              </td>
              <td>
                <code>{prop.type.name}</code>
              </td>
              <td>{prop.defaultValue && <code>{prop.defaultValue?.value || null}</code>}</td>
              <td>{prop.description}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default PropsTable;
