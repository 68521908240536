import React from 'react';
import {colors, space} from '@workday/canvas-kit-react/tokens';
import {Box} from '@workday/canvas-kit-react/layout';

import {
  ViewportSize,
  bentoRadius,
  combineMediaQueries,
  contentBottomPadding,
  contentHSpace,
  maxBespokeContentWidth,
  maxContentWidth,
  mqAt,
} from '../../utils/breakpoints';

export interface ContentBodyProps {
  children?: React.ReactNode;
  /**
   * Some pages display the ContentBody at full width (i.e., bespoke width)
   * without AnchorNav and TopJumpLink (e.g., Pattern Detail).
   */
  fullWidth?: boolean;
  hasTabs?: boolean;
}

const baseContentBodyStyles = {
  // Tabs require additional styling
  '.nav-tabs[role=tablist]': {
    background: colors.soap100,
    borderBottom: 0,
    borderRadius: bentoRadius,
    // Override margins built into CKR Tabs
    margin: `0 ${space.xxs} 0 0`,
    // Override gradient built into CKR Tabs (which assumes Tabs are shown on
    // a white background)
    '::after': {
      background: `linear-gradient(to right,rgba(255,255,255,0),${colors.soap100});`,
    },
  },

  '& > [role=tabpanel]': {
    // Set the maxWidth on the tabpanel since we cannot set it on the
    // ContentBody if navigation tabs are present (see below)
    maxWidth: maxContentWidth,
  },
};

const getContentBodyMediaQuery = (size: ViewportSize, hasTabs?: boolean) => {
  // The horizontal space around the main content
  const hSpace = contentHSpace[size];
  const hMargin = hasTabs ? 0 : hSpace;

  return {
    [mqAt(size)]: {
      marginRight: hMargin,
      marginLeft: hMargin,

      '.nav-tabs[role=tablist]': {
        padding: `0 ${hSpace}px`,
      },

      '& > [role=tabpanel]': {
        margin: `${space.xxl} ${hSpace}px 0`,
      },
    },
  };
};

export const ContentBody = ({children, fullWidth, hasTabs}: ContentBodyProps) => {
  const styles = {
    ...baseContentBodyStyles,
    ...combineMediaQueries(getContentBodyMediaQuery, hasTabs),
  };

  return (
    <Box
      // Using a top margin here instead of a top padding so the top margin of
      // ContentBody collapses with the top margin of the first heading in the
      // main content
      marginTop={hasTabs ? 0 : space.xxxl}
      // If navigational tabs (.nav-tabs) are present on the page, we cannot
      // set maxWidth on ContentBody since nav-tabs (the actual tabs which
      // share the gray background color of ContentPreamble) is a child of
      // ContentBody and we need nav-tabs to span the full width of
      // ContentBody's PARENT (in order for the gray background of nav-tabs to
      // match up with the gray background of ContentPreamble). Instead, we set
      // the maxWidth on the tabpanels (see styles above).
      maxWidth={hasTabs ? undefined : fullWidth ? maxBespokeContentWidth : maxContentWidth}
      paddingBottom={contentBottomPadding}
      css={styles}
    >
      {children}
    </Box>
  );
};
