import React from 'react';
import styled from '@emotion/styled';

import {AccentIcon} from '@workday/canvas-kit-react/icon';
import {Hyperlink} from '@workday/canvas-kit-react/button';
import {accessibleHide} from '@workday/canvas-kit-react/common';
import {borderRadius, colors, space} from '@workday/canvas-kit-react/tokens';
import {arrowUpCircleIcon} from '@workday/canvas-accent-icons-web';

import {useSecondaryNav} from '../../utils/useSecondaryNav';
import {contentBottomPadding} from '../../utils/breakpoints';
import {getVisibleFooterHeight} from '../../utils/htmlElements';
import {throttle} from '../../utils/throttle';
import {getHeadingLink} from '../../utils/urlFragment';

export interface TopJumpLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: React.ReactNode;
  /**
   * Whether or not to suppress the TopJumpLink.
   */
  suppress?: boolean;
  /**
   * The point at which the TopJumpLink becomes visible (based on how many
   * pixels the user has scrolled down the page).
   */
  visibleThreshold?: number;
}

interface LinkProps {
  leftPosition: number;
  visibleThresholdMet: boolean;
}

const Link = styled(Hyperlink)<LinkProps>(
  {
    borderRadius: borderRadius.m,
    textDecoration: 'none',
    padding: `${space.xxxs} ${space.xxs} ${space.xxxs} ${space.xxxs}`,
    transition: 'opacity .2s ease-out 0s',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
  },
  ({leftPosition}) => ({
    left: leftPosition,
  }),
  ({visibleThresholdMet}) =>
    !visibleThresholdMet
      ? {
          '&:not(:focus):not(:active)': {
            ...accessibleHide,
            opacity: 0,
          },
        }
      : {}
);

const Content = styled('span')({
  color: colors.blackPepper300,
  marginLeft: space.xxxs,
});

// The base bottom position of the TopJumpLink (modified by the presence of the
// Footer to prevent TopJumpLink from colliding with the Footer)
const baseBottomPosition = 80;

const TopJumpLink = ({
  children,
  suppress,
  visibleThreshold = 300,
  ...elemProps
}: TopJumpLinkProps) => {
  if (suppress) {
    return null;
  }

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [bottomPosition, setBottomPosition] = React.useState(baseBottomPosition);

  const {leftPosition} = useSecondaryNav();

  const handleClick = () => {
    // We need to attach a click handler to TopJumpLink because Firefox
    // triggers popstate differently than Chrome. Clicking TopJumpLink twice
    // consecutively (for example, if you click the TopJumpLink, scroll down
    // the page, and then click the TopJumpLink again) triggers a popstate
    // event on the second click in Chrome (which kicks off the desired focus
    // and scrolling behavior in Layout), but not in Firefox. This is due to
    // the URL not changing between the first and second clicks. In order to
    // trigger the desired behavior on the second click in Firefox, we need to
    // focus and scroll to the #top element here in the click handler.
    const topElem = document.getElementById('top');
    if (topElem) {
      topElem.focus();
    }
    window.scrollTo({
      left: 0,
      top: 0,
      behavior: 'smooth',
    });
  };

  // Handler for setting scrollPosition (determines whether or not TopJumpLink
  // is visible). Throttled to improve performance.
  const handleScrollForScrollPosition = throttle(() => {
    setScrollPosition(window.scrollY);
  }, 100);

  // Handler for setting the bottom position of TopJumpLink. NOT throttled to
  // keep the positioning of TopJumpLink smooth as the user scrolls the page
  // (potential performance issues are mitigated by the fact that this handler
  // only results in a re-render of the component when scrolling at the very
  // bottom of the page as the Footer becomes visible).
  const handleScrollForBottomPosition = () => {
    const visibleFooterHeight = getVisibleFooterHeight();

    if (visibleFooterHeight === null) {
      setBottomPosition(baseBottomPosition);
    } else {
      // If the Footer is visible, increase the bottom position of TopJumpLink
      // by how much of the Footer's height is visible to prevent TopJumpLink
      // from colliding with the Footer. We add the difference between the
      // bottom padding of the main content area and the base bottom position
      // of TopJumpLink to calculate the final offset (to align the bottom of
      // TopJumpLink to the bottom of the main content (usually an
      // FAQNoticeCard).
      const linkBottomOffset = visibleFooterHeight + (contentBottomPadding - baseBottomPosition);
      setBottomPosition(
        linkBottomOffset > 0 ? baseBottomPosition + linkBottomOffset : baseBottomPosition
      );
    }
  };

  React.useEffect(() => {
    // Set the scroll position and bottom position when TopJumpLink mounts (in
    // case it previously wasn't being rendered at a small viewport size and
    // the viewport size has since increased and should now render TopJumpLink)
    setScrollPosition(window.scrollY);
    handleScrollForBottomPosition();

    window.addEventListener('scroll', handleScrollForScrollPosition);
    window.addEventListener('scroll', handleScrollForBottomPosition);

    return () => {
      window.removeEventListener('scroll', handleScrollForScrollPosition);
      window.removeEventListener('scroll', handleScrollForBottomPosition);
    };
  }, []);

  const visibleThresholdMet = scrollPosition >= visibleThreshold;

  return (
    <Link
      href={getHeadingLink('top')}
      leftPosition={leftPosition}
      // Use style instead of a prop for bottom to prevent Emotion from
      // generating a new CSS class every time bottom changes as the user
      // scrolls the page
      style={{bottom: bottomPosition}}
      onClick={handleClick}
      visibleThresholdMet={visibleThresholdMet}
      {...elemProps}
    >
      <AccentIcon icon={arrowUpCircleIcon} color={colors.blackPepper300} size={28} />
      <Content>{children}</Content>
    </Link>
  );
};

export default TopJumpLink;
