import {CSSObject} from '@emotion/styled';
import {contentHSpace, mqAt} from './breakpoints';

/**
 * Returns a CSS calc which calculates the width of a full-width element minus
 * a horizontal space (doubled to account for left and right) AND a flat
 * reduction. Useful for ContentOverflowBox and calculating widths of popups.
 */
export const calcFullWidth = (hSpace: number, reduction = 0) =>
  `calc(100vw - ${2 * hSpace + reduction}px)`;

/**
 * Returns a CSSObject with styles for text which needs to overflow at small
 * viewport sizes.
 */
export const textOverflowStyles: CSSObject = {
  // Assuming overflow styles will only be necssary at the s viewport size
  [mqAt('s')]: {
    overflowX: 'hidden',
    textOverflow: 'ellipsis',
    width: `calc(100vw - ${2 * contentHSpace.s}px)`,
  },
};
