import queryString from 'query-string';
import {Index} from 'lunr';

export type SearchItem = {
  id: string;
  path: string;
  title: string;
  description?: string;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/class-name-casing
  interface Window {
    __LUNR__: {
      en: {
        index: Index;
        store: {
          [key: string]: SearchItem;
        };
      };
    };
  }
}

/**
 * Match all words in query if they're off by one character.
 * https://lunrjs.com/guides/searching.html#fuzzy-matches
 */
export const makeQueryFuzzy = (query: string, fuzziness = 1) => {
  if (!query.length) {
    return query;
  }
  return query.trim().replace(' ', `~${fuzziness} `) + `~${fuzziness}`;
};

/**
 * Search the lunr index defined on the window object.
 */
export const getSearchResults = (query: string, minLength = 3, fuzzy = true) => {
  if (!query || !window.__LUNR__ || query.length < minLength) {
    return [];
  }
  const lunrIndex = window.__LUNR__['en'];

  // lunr will crash on certain sequences of special characters unless we catch
  // the error gracefully.
  // Source: https://github.com/olivernn/lunr.js/issues/274#issuecomment-306506377
  //
  // TODO: Figure out how to escape the special characters so we can
  // legitimately search for "--whatever". Currently, there doesn't appear to
  // be a way to do so.
  // Source: https://github.com/olivernn/lunr.js/issues/479
  try {
    // You can customize your search, see https://lunrjs.com/guides/searching.html
    const results = lunrIndex.index.search(fuzzy ? makeQueryFuzzy(query) : query);
    return results.map(({ref}) => lunrIndex.store[ref]);
  } catch (e) {
    // Rather than log the error and clutter the console, we choose to silently
    // ignore it. Subsequent searches will continue to work fine, provided they
    // don't use the problematic special characters.
    return [];
  }
};

/**
 * Returns the value of the given key in the browser's current URL query string.
 */
export const getQueryValue = (key: string) => {
  // Check if window is defined in case we're using server side rendering
  if (typeof window === 'undefined') {
    return;
  }

  const parsed = queryString.parse(window.location.search);

  // Assert value as a string (we don't expect to see an array
  // of strings here)
  return parsed[key] as string;
};
