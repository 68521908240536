/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import {Helmet} from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';

type MetaProp = JSX.IntrinsicElements['meta'][];
interface OGImage {
  original: {
    src: string;
  };
}

export interface SEOProps {
  description?: string;
  lang?: string;
  meta?: MetaProp;
  title?: string;
  path?: string;
}

const SEO = ({description = '', lang = 'en', meta = [], title, path}: SEOProps) => {
  const {
    site,
    allImageSharp: {nodes: ogImages},
  } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
        allImageSharp(filter: {fixed: {originalName: {glob: "*-open-graph.png"}}}) {
          nodes {
            original {
              src
            }
          }
        }
      }
    `
  );

  const findImage = (name: string | undefined): OGImage =>
    name &&
    ogImages.find(({original}: OGImage) => original.src.includes(name !== '' ? name : 'home'));

  const ogImageName: string | undefined = path && path.split('/')[1];
  const {
    original: {src: ogImageLink},
  }: OGImage = findImage(ogImageName) || findImage('home');

  const metaDescription = description || site.siteMetadata.description;
  const baseMeta: MetaProp = [
    {
      name: `description`,
      content: metaDescription,
    },
    {
      property: `og:title`,
      content: title,
    },
    {
      property: `og:description`,
      content: metaDescription,
    },
    {
      property: `og:type`,
      content: 'website',
    },
    {
      property: `og:site_name`,
      content: 'Workday Canvas Design System',
    },
    {
      property: `og:image`,
      content: ogImageLink,
    },
    {
      property: `og:image:width`,
      content: '1200',
    },
    {
      property: `og:image:height`,
      content: '630',
    },
    {
      name: `twitter:card`,
      content: `summary`,
    },
    {
      name: `twitter:creator`,
      content: site.siteMetadata.author,
    },
    {
      name: `twitter:title`,
      content: title,
    },
    {
      name: `twitter:description`,
      content: metaDescription,
    },
    {
      name: `twitter:image`,
      content: ogImageLink,
    },
  ];

  return (
    <Helmet
      htmlAttributes={{lang}}
      title={title}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={baseMeta.concat(meta)}
    />
  );
};

export default SEO;
