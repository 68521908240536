import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';

import {SystemIcon} from '@workday/canvas-kit-react/icon';
import {colors, space, type} from '@workday/canvas-kit-react/tokens';
import {arrowRightSmallIcon} from '@workday/canvas-system-icons-web';

import AbsoluteToFixed from './AbsoluteToFixed';

import {useSecondaryNav} from '../../utils/useSecondaryNav';
import {getElementScrollY, getVisibleFooterHeight} from '../../utils/htmlElements';
import {throttle} from '../../utils/throttle';
import {getHeadingLink} from '../../utils/urlFragment';

export interface AnchorNavProps {
  /**
   * The minimum number of qualifying headings (according to `headingDepth`) in
   * the `content` in order for the AnchorNav to be displayed.
   */
  headingCountThreshold?: number;
  /**
   * The depth of heading levels that will be represented in the AnchorNav. For
   * example, a value of `3` would configure the AnchorNav to include all h1,
   * h2, and h3 headings in the `content`. A value of `0` would prevent the
   * AnchorNav from rendering at all.
   */
  headingDepth?: number;
  /**
   * The content from which the headings in the AnchorNav will be populated
   * from.
   */
  content: HTMLElement | null;
}

interface ListItemProps extends React.LiHTMLAttributes<HTMLLIElement> {
  /**
   * If true, indicate the ListItem is selected (i.e., visible).
   */
  selected: boolean;
}

/**
 * An object representing a heading to be displayed in the AnchorNav.
 */
type Heading = {
  /**
   * The HTML id of the Heading.
   */
  id: string;
  /**
   * The text content of the Heading.
   */
  text: string;
  /**
   * The start position of the Heading region (the Heading and its associated
   * content) in the overall content element. Combined with `regionEndPosition`
   * below, defines a vertical region of the content that corresponds to this
   * Heading being visible.
   */
  regionStartPosition: number;
  /**
   * The end position of the Heading region.
   */
  regionEndPosition?: number;
};

/**
 * An object used to map heading HTML ids to their corresponding Heading
 * object.
 */
type HeadingsMap = {
  [id: string]: Heading;
};

/**
 * The default distance (between the top of the AnchorNav and the top of the
 * browser viewport) at which the AnchorNav switches to fixed positioning.
 */
const defaultOffset = 192;

const Container = styled(AbsoluteToFixed, {
  shouldForwardProp: prop => isPropValid(prop),
})({
  position: 'absolute',
  transition: 'opacity .2s ease-out 0s',
});

const Contents = styled('div')({
  ...type.levels.subtext.large,
  paddingRight: space.l,
});

const Heading = styled('h2')({
  ...type.levels.subtext.medium,
  color: colors.licorice300,
  fontWeight: 500,
  marginBottom: space.xs,
  textTransform: 'uppercase',
});

const List = styled('ul')({
  borderLeft: `1px solid ${colors.soap500}`,
  listStyle: 'none',
});

const ListItem = styled('li')<ListItemProps>(
  {
    alignItems: 'center',
    display: 'flex',
    fontWeight: 500,
    margin: `${space.xxxs} 0`,
  },
  ({selected}) => ({
    paddingLeft: selected ? '6px' : '36px',

    a: {
      color: selected ? colors.blueberry500 : colors.blackPepper300,
      minHeight: space.m,
      paddingTop: '2px',
      paddingBottom: '2px',
    },
  })
);

/**
 * Extracts an array of heading JSX Elements from the AnchorNav's
 * `content`.
 */
const getHeadings = (content: HTMLElement, headingDepth: number) => {
  if (headingDepth === 0) {
    return [];
  }

  const qualifyingHeadings = [...Array(headingDepth)].map((_, i) => `h${i + 1}`);

  // Find all qualifying headings. Convert the returned NodeList into a JS
  // array using `Array.prototype.slice.call` so we can filter it later.
  const allSelector = qualifyingHeadings.join(',');
  const allHeadings = Array.prototype.slice.call(content.querySelectorAll(allSelector));

  // Find all qualifying headings which should be excluded from the AnchorNav
  const excludedSelector = qualifyingHeadings
    .map(headingTag => `.exclude-anchor-nav ${headingTag}`)
    .join(',');
  const excludedHeadings = Array.prototype.slice.call(content.querySelectorAll(excludedSelector));

  // Return all qualifying headings minus the headings to be excluded
  return allHeadings.filter((elem: HTMLElement) => excludedHeadings.indexOf(elem) === -1);
};

/**
 * Generates a map of heading ids to Heading objects.
 */
const mapHeadings = (headings: HTMLElement[]) => {
  const headingsMap: HeadingsMap = {};

  // Adds a cushion before a heading is marked as visible. Without this
  // cushion, we would only mark a heading as visible when it reaches the top
  // of the viewport as the user is scrolling down. With this cushion, we can
  // mark it as visible earlier.
  const regionCushion = 100;

  let prevHeadingId = '';
  headings.forEach(headingElem => {
    if (headingElem) {
      const id = headingElem.getAttribute('id') || '';
      const text = headingElem.innerText;
      const regionStartPosition = Object.keys(headingsMap).length
        ? getElementScrollY(headingElem) - regionCushion
        : 0;

      // Set the regionEndPosition of the previous heading 1px away from the
      // regionStartPosition of the current heading
      if (prevHeadingId) {
        headingsMap[prevHeadingId].regionEndPosition = regionStartPosition - 1;
      }

      headingsMap[id] = {
        id,
        text,
        regionStartPosition,
      };

      // Store the heading we just processed so we can set its
      // regionEndPosition based on the regionStartPosition of the next heading
      // we're about to process
      prevHeadingId = id;
    }
  });

  // TODO: Figure out how/if to handle the last heading(s) on a page if they're
  // so short they don't occupy enough space to be scrolled to.
  const main = document.querySelector('main');
  if (prevHeadingId && headingsMap[prevHeadingId] && main) {
    headingsMap[prevHeadingId].regionEndPosition = main.scrollHeight;
  }

  return headingsMap;
};

/**
 * Returns whether or not a heading region is currently visible given its id
 * and the provided scrollPosition of its scrollable container.
 */
const isHeadingVisible = (id: string, headingsMap: HeadingsMap, scrollPosition: number) => {
  const heading = headingsMap[id];
  if (heading && heading.regionEndPosition) {
    return (
      scrollPosition >= heading.regionStartPosition && scrollPosition <= heading.regionEndPosition
    );
  }
  return false;
};

/**
 * Calculates the top position of the AnchorNav.
 */
const getTopPosition = (content: HTMLElement) => {
  // Figure out where to align the top of the AnchorNav. If an h1 is present,
  // align with the h1...
  let elements = content.querySelectorAll('h1');

  // ...otherwise, align with the first qualifying element specified by the
  // selector below. Note that we don't want to align against an h1 if it's
  // contained within a codeblock (all codeblocks are contained within an
  // element with .exclude-anchor-nav)
  if (!elements.length || elements[0].closest('.exclude-anchor-nav')) {
    elements = content.querySelectorAll(
      '.info-highlight, h2, h3, h4, h5, h6, p, ul, .notice-card, .package-info'
    );
  }

  if (elements.length) {
    return (elements[0] as HTMLElement).offsetTop;
  }

  // Return 0 if the page has no headings or paragraphs. This will prevent the
  // AnchorNav from rendering at all.
  return 0;
};

/**
 * Returns whether or not the AnchorNav should be visible based on whether or
 * not the Footer is currently visible in the viewport. AnchorNav should not
 * be visible when the Footer is within 100px (of scrolling) away from being
 * visible to prevent the AnchorNav from colliding with TopJumpLink and/or the
 * Footer.
 */
const isAnchorNavVisible = () => {
  const visibleFooterHeight = getVisibleFooterHeight();

  if (visibleFooterHeight === null) {
    return true;
  }

  return visibleFooterHeight <= -100;
};

const AnchorNav = ({headingCountThreshold = 2, headingDepth = 2, content}: AnchorNavProps) => {
  // Do not render if headingDepth is falsey (0 or false)
  if (!headingDepth) {
    return null;
  }

  const [headingsMap, setHeadingsMap] = React.useState<HeadingsMap>({});
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [topPosition, setTopPosition] = React.useState(0);
  const [visible, setVisible] = React.useState(false);

  const {leftPosition} = useSecondaryNav();

  const handleScroll = throttle(() => {
    setScrollPosition(window.scrollY);
    setVisible(isAnchorNavVisible());
  }, 100);

  React.useEffect(() => {
    // Use rAF to ensure proper measurements
    const animateId = requestAnimationFrame(() => {
      if (!content) {
        return;
      }

      const headings = getHeadings(content, headingDepth);

      setHeadingsMap(mapHeadings(headings));
      setTopPosition(getTopPosition(content));
    });

    return () => {
      cancelAnimationFrame(animateId);
    };
  }, [content]);

  React.useEffect(() => {
    // Set the visibility when AnchorNav mounts (in case it previously wasn't
    // being rendered at a small viewport size and the viewport size has since
    // increased and should now render AnchorNav)
    setVisible(isAnchorNavVisible());

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const headingIds = Object.keys(headingsMap);
  const headingCountThresholdMet = headingIds.length >= headingCountThreshold;

  // Override the defaultOffset if the AnchorNav is already positioned higher
  // up on the page than the defaultOffset (i.e., the AnchorNav is starting off
  // in its fixed spot, snap to fixed positioning immediately as soon as the
  // user begins to scroll).
  const offset = topPosition < defaultOffset ? topPosition : defaultOffset;

  // Container is a styled AbsoluteToFixed component. In order for
  // AbsoluteToFixed to work properly (it relies on its `top` style being set
  // correctly when it's rendered), we must delay rendering it until
  // topPosition has been properly calculated.
  return headingCountThresholdMet && topPosition ? (
    <Container
      offset={offset}
      css={{left: leftPosition, top: topPosition, opacity: visible ? 1 : 0}}
    >
      <Contents>
        <Heading>On this Page:</Heading>
        <List>
          {headingIds.map(id => {
            const selected = isHeadingVisible(id, headingsMap, scrollPosition);
            return (
              <ListItem key={id} selected={selected}>
                {selected && (
                  <SystemIcon
                    icon={arrowRightSmallIcon}
                    color={colors.blueberry500}
                    css={{alignSelf: 'flex-start', marginRight: '6px'}}
                  />
                )}
                <a href={getHeadingLink(id)}>{headingsMap[id].text}</a>
              </ListItem>
            );
          })}
        </List>
      </Contents>
    </Container>
  ) : null;
};

export default AnchorNav;
