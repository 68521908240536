import styled from '@emotion/styled';
import {borderRadius, colors, space, type} from '@workday/canvas-kit-react/tokens';

/**
 * Container element needed to wrap ContentPreamble and ContentBody
 */
const ContentContainer = styled('main')({
  ...type.levels.body.small,

  // TODO: Normally, the styles for the <code> element would be defined
  // in StyledElements.ts, but for some reason the styled('code')
  // component wasn't being used with the `code` syntax in MDX. It
  // rendered a plain <code>...</code> element without the
  // Emotion-generated class. Leaving the code styles here until we
  // figure this out.
  code: {
    background: colors.soap200,
    borderRadius: borderRadius.s,
    padding: `2px ${space.xxxs}`,
    fontSize: '12px',
    fontFamily: type.properties.fontFamilies.monospace,

    '&.blue': {
      background: colors.blueberry100,
      color: colors.blueberry400,
    },

    '&.bold': {
      fontWeight: 700,
    },
  },

  '.lead': {
    ...type.levels.body.large,
    ...type.variants.hint,
    marginTop: space.m,
  },

  '.gatsby-resp-image-wrapper': {
    marginTop: space.xl,
    marginBottom: space.xl,
  },

  '.gatsby-resp-image-link': {
    // Remove hover background color applied by CK Hyperlink
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export default ContentContainer;
