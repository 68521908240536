import React from 'react';

import {spaceNumbers} from '@workday/canvas-kit-react/tokens';

import {viewportRanges} from './breakpoints';

/**
 * Pure helper function for useSecondaryNav.
 */
const getSecondaryNavLeftPosition = () => {
  // Horizontally position Secondary Nav using H1 (or the skeleton loader
  // serving as placeholder for the H1) present on every page
  const h1 = document.querySelector('h1') || document.querySelector('.skeleton-h1');
  if (h1) {
    const leftSpace = window.innerWidth >= viewportRanges.xxl[0] ? spaceNumbers.xl : spaceNumbers.l;
    const rect = h1.getBoundingClientRect();
    return rect.x + rect.width + leftSpace;
  }

  return 0;
};

/**
 * Calculates the correct positon of Secondary Nav elements (AnchorNav,
 * TopJumpLink, etc).
 */
export const useSecondaryNav = () => {
  const [leftPosition, setLeftPosition] = React.useState(0);

  const handleResize = () => {
    setLeftPosition(getSecondaryNavLeftPosition());
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);

    const animateId = requestAnimationFrame(() => {
      setLeftPosition(getSecondaryNavLeftPosition());
    });

    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animateId);
    };
  }, []);

  return {leftPosition};
};
