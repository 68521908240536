import React from 'react';
import {withPrefix} from 'gatsby';

import {ExternalHyperlink, Hyperlink} from '@workday/canvas-kit-react/button';
import {createComponent} from '@workday/canvas-kit-react/common';
import {SystemIcon} from '@workday/canvas-kit-react/icon';
import {colors, type} from '@workday/canvas-kit-react/tokens';
import {Tooltip} from '@workday/canvas-kit-react/tooltip';

import {extLinkIcon} from '@workday/canvas-system-icons-web';
import {CanvasSystemIcon} from '@workday/design-assets-types';

import useSiteMetadata from '../../utils/useSiteMetadata';

export interface LinkProps {
  children: React.ReactNode;
  href: string;
  target?: string;
}

export interface LinkWithIconProps extends LinkProps {
  icon: CanvasSystemIcon;
  iconSize?: string | number;
}

export const LinkWithIcon = createComponent(Hyperlink)({
  displayName: 'LinkWithIcon',
  Component: ({children, href, icon, iconSize, ...elemProps}: LinkWithIconProps, ref, Element) => {
    return (
      <Element
        ref={ref}
        href={withPrefix(href)}
        css={{
          alignItems: 'center',
          display: 'inline-flex',

          // Remove paddingRight built into Hyperlink since SystemIcon on the right
          // already has empty space on the right built in
          paddingRight: 0,
        }}
        {...elemProps}
      >
        {children}
        <SystemIcon
          icon={icon}
          color={colors.blueberry400}
          colorHover={colors.blueberry500}
          size={iconSize}
        />
      </Element>
    );
  },
});

export const Link = ({children, href, ...elemProps}: LinkProps) => {
  // SSR
  if (typeof window === 'undefined') {
    return (
      <Hyperlink href={href} {...elemProps}>
        {children}
      </Hyperlink>
    );
  }

  const siteRoot = window.location.origin + withPrefix('/');
  const isExternal =
    (href.match(/https?:\/\//g) || href.startsWith('//')) && !href.includes(siteRoot);

  // TODO: This logic needs to be updated to consider versioned sites as
  // external links
  if (isExternal) {
    // TODO: We should be able to use LinkWithIcon here...
    //
    // <LinkWithIcon href={href} icon={extLinkIcon} target="_blank" {...elemProps}>
    //   {children}
    // </LinkWithIcon>
    //
    // ...but this doesn't work in the PR preview and staging environments.
    // Visit a page with an external link (e.g., Type) and refresh the page.
    // Note the incorrect placement of extLinkIcon. This is probably an
    // SSR/hydration issue. Using the old code (before LinkWithIcon) for now.
    return (
      <Hyperlink href={href} target="_blank" {...elemProps}>
        {children}
        <SystemIcon
          icon={extLinkIcon}
          color={colors.blueberry400}
          colorHover={colors.blueberry500}
          css={{
            margin: '-2px 0',
            verticalAlign: 'text-top',
          }}
        />
      </Hyperlink>
    );
  }

  // TODO: Figure out why using the Gatsby Link component results in multiple path prefixes. It doesn't seem to be the gatsby-plguin-catch-links plugin.
  return (
    <Hyperlink href={href} {...elemProps}>
      {children}
    </Hyperlink>
  );
};

// Hyperlink styled with qualities of default (medium) sized TertiaryButton
// (has an icon, font smoothing)
export const TertiaryButtonLink = ({
  children,
  href,
  icon,
  iconSize,
  ...elemProps
}: LinkWithIconProps) => (
  <LinkWithIcon
    href={href}
    icon={icon}
    iconSize={iconSize}
    css={{
      fontSize: type.levels.subtext.large.fontSize,
      fontWeight: type.properties.fontWeights.bold,

      // Apply font smoothing used for Canvas Kit buttons (see Canvas Kit
      // BaseButton). This Card link was previously rendered as a
      // TertiaryButton.
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    }}
    {...elemProps}
  >
    {children}
  </LinkWithIcon>
);

export interface LegacyPatternLinkProps {
  children: React.ReactNode;
  href: string;
}

export const LegacyPatternLink = ({children, href}: LegacyPatternLinkProps) => {
  const config = useSiteMetadata();
  const fullHref = config.internal
    ? `https://canvas.workdaydesign.com/v9${href}`
    : `https://canvas.workday.com/v9${href}`;

  return (
    <Tooltip title="Current Canvas patterns are available on the v9 website as we transition them to an updated format.">
      <ExternalHyperlink href={fullHref}>{children}</ExternalHyperlink>
    </Tooltip>
  );
};
