import React from 'react';
import styled, {CSSObject} from '@emotion/styled';
import {StyledType} from '@workday/canvas-kit-react/common';
import {Box} from '@workday/canvas-kit-react/layout';
import {type, space, colors} from '@workday/canvas-kit-react/tokens';
import {HeadingTag} from '../../utils/types';

// Using StyledType from CKR because TS complains about use of `as` for
// styled components without it (even though Emotion supports it)
export const Keyline = styled('h5')<StyledType>({
  ...type.levels.body.small,
  fontSize: '14px',
  fontWeight: 700,
  letterSpacing: '1px',
  lineHeight: '24px',
  textTransform: 'uppercase',
  display: 'inline-block',
  marginTop: space.l,
  marginBottom: space.s,
  '&:after': {
    content: "''",
    height: '2px',
    display: 'block',
    minWidth: '96px',
    margin: `${space.xxs} auto 0`,
    background:
      `linear-gradient(to right, ${colors.blueberry400}, ` +
      `${colors.sourLemon400} 0%, ${colors.sourLemon400} 33%, ` +
      `${colors.cantaloupe400} 33%, ${colors.cantaloupe400} 66%, ` +
      `${colors.blueberry400} 66%, ${colors.blueberry400} 100%)`,
  },
});

export interface HeadingWithKeylineProps {
  centered?: boolean;
  children: React.ReactNode;
  headingLevel?: HeadingTag;
  headingStyles?: CSSObject;
  keylineText: string;
}

export const HeadingWithKeyline = ({
  centered = true,
  children,
  headingLevel = 'h2',
  headingStyles = {},
  keylineText,
  ...elemProps
}: HeadingWithKeylineProps) => (
  <Box css={centered ? {textAlign: 'center'} : {}} marginBottom="xl" {...elemProps}>
    <Keyline as="p" css={{marginTop: 0}}>
      {keylineText}
    </Keyline>
    <Box
      as={headingLevel}
      css={{
        ...type.levels.heading.large,
        ...headingStyles,
      }}
    >
      {children}
    </Box>
  </Box>
);

export default Keyline;
