import React from 'react';
import {TertiaryButton} from '@workday/canvas-kit-react/button';
import {mediaTopicsIcon, extLinkIcon, checkIcon} from '@workday/canvas-system-icons-web';
import {
  Popup,
  usePopupModel,
  useAlwaysCloseOnOutsideClick,
  useReturnFocus,
  useCloseOnEscape,
} from '@workday/canvas-kit-react/popup';
import {colors, type} from '@workday/canvas-kit-react/tokens';
import {
  DeprecatedMenu,
  DeprecatedMenuItem,
  DeprecatedMenuItemProps,
} from '@workday/canvas-kit-preview-react/menu';
import {createComponent} from '@workday/canvas-kit-react/common';

export const SiteSwitcher = createComponent('div')({
  displayName: 'SiteSwitcher',
  Component: ({
    invertIconButton,
    children,
  }: {
    invertIconButton: boolean;
    children: React.ReactElement[];
  }) => {
    const model = usePopupModel();

    useAlwaysCloseOnOutsideClick(model);
    useCloseOnEscape(model);
    useReturnFocus(model);

    const isOpen = model.state.visibility !== 'hidden';

    const handleButtonKeyDown = (event: React.KeyboardEvent) => {
      let isShortcut = false;
      if (event.key === `Spacebar` || event.key === ` ` || event.key === `Enter`) {
        isShortcut = true;
        if (isOpen) {
          model.events.hide();
        } else {
          model.events.show();
        }
      } else if (event.key === `ArrowDown` || event.key === `ArrowUp`) {
        isShortcut = true;
        model.events.show();
      }

      if (isShortcut) {
        // Prevent ArrowDown and ArrowUp keys from scrolling the entire page
        event.preventDefault();
      }
    };

    return (
      <Popup model={model}>
        <Popup.Target
          as={TertiaryButton}
          icon={mediaTopicsIcon}
          variant={invertIconButton ? 'inverse' : undefined}
          onKeyDown={handleButtonKeyDown}
          aria-expanded={isOpen}
          aria-haspopup={true}
          aria-controls={isOpen ? 'site-switcher' : undefined}
          aria-label="Site Switcher"
        />
        <Popup.Popper placement={'bottom-start'}>
          <DeprecatedMenu
            id="site-switcher"
            initialSelectedItem={0}
            isOpen={isOpen}
            onClose={model.events.hide}
          >
            {children}
          </DeprecatedMenu>
        </Popup.Popper>
      </Popup>
    );
  },
  subComponents: {
    Link: ({children, isFocused, onClick, isDisabled, ...elemProps}: DeprecatedMenuItemProps) => (
      <DeprecatedMenuItem
        {...elemProps}
        isFocused={isFocused}
        secondaryIcon={isDisabled ? checkIcon : extLinkIcon}
        isDisabled={isDisabled}
        onClick={onClick}
        css={isDisabled && {'.wd-icon-fill': {fill: `${colors.blueberry400} !important`}}}
      >
        <span
          css={{
            ...type.levels.body.small,
            fontWeight: type.properties.fontWeights.medium,
            color: isDisabled
              ? colors.blueberry400
              : isFocused
              ? colors.frenchVanilla100
              : type.levels.body.small.color,

            ...(!isDisabled && {
              [`[data-whatinput='mouse'] &,
              [data-whatinput='touch'] &,
              [data-whatinput='pointer'] &`]: {color: type.levels.body.small.color},
            }),
          }}
        >
          {children}
        </span>
      </DeprecatedMenuItem>
    ),
  },
});
