import { ExternalHyperlink } from '@workday/canvas-kit-react/button';
import { InformationHighlight } from '@workday/canvas-kit-docs';
import { ShapeTokens } from "../../../../src/components/content/tokens/TokenShapeGrid";
import { getInternalExternalURL } from "../../../../src/utils/url";
import * as React from 'react';
export default {
  ExternalHyperlink,
  InformationHighlight,
  ShapeTokens,
  getInternalExternalURL,
  React
};