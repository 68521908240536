import React from 'react';
import styled from '@emotion/styled';
import {withPrefix} from 'gatsby';

import {StyledType, useListModel} from '@workday/canvas-kit-react';
import {Flex} from '@workday/canvas-kit-react/layout';
import {Heading, Subtext} from '@workday/canvas-kit-react/text';
import {space, depth, colors, type} from '@workday/canvas-kit-react/tokens';
import {SidePanel, useSidePanel} from '@workday/canvas-kit-preview-react/side-panel';

import {SidebarSection} from './SidebarSection';
import {SelectionGroup} from './SelectionGroup';

import {InternalContent} from '../LimitedContent';
import {platformStorageKey, usePlatformSwitcher} from '../PlatformSwitcher';

import {Hierarchy, useHierarchy} from '../../utils/useHierarchy';
import {
  NavHierarchy,
  getPageFromPath,
  updateNavExpandedState,
  generateStatelessNav,
  useNavHierarchy,
  useNavSessionStorage,
} from '../../utils/navigation';
import {persistToSessionStorage, restoreFromSessionStorage} from '../../utils/sessionStorage';
import {useiOSHierarchy} from '../../utils/useIOSHierarchy';
import {useAndroidHierarchy} from '../../utils/useAndroidHierarchy';
import {useWebHierarchy} from '../../utils/useWebHierarchy';

// TODO-P2-RESPONSIVE: Remove Sidebar and all related components once we no
// longer need it for responsive web

export interface SidebarProps {
  currentPath: string;
  hidePopup?: () => void;
}

const StyledSidePanel = styled(SidePanel)<StyledType>({
  ...depth[2],
  paddingTop: space.m,
  paddingBottom: space.m,
  position: 'fixed',
  top: space.xxl,
  bottom: 0,
  left: 0,
  height: 'auto',
  overflowY: 'auto',
  zIndex: 2,
});

/**
 * The sections corresponding to each category in the sidebar.
 * This is where you can specify the order/rank for each category.
 */
const sectionMap = {};

export const Sidebar = ({currentPath, hidePopup, ...elemProps}: SidebarProps) => {
  const hierarchy = useHierarchy();
  const iOSHierarchy = useiOSHierarchy();
  const androidHierarchy = useAndroidHierarchy();
  const webHierarchy = useWebHierarchy();
  const localPath = currentPath.replace(withPrefix('/'), '/');
  const currentPage = getPageFromPath(localPath, hierarchy);
  const [nav, setNav] = useNavHierarchy(sectionMap, hierarchy);
  const platformSwitcherContext = usePlatformSwitcher();

  useNavSessionStorage(nav);

  const toggleNavItemState = (
    {children, path}: NavHierarchy,
    e: React.MouseEvent<HTMLAnchorElement>
  ) => {
    // Don't follow the link if it's a parent (not a real page)
    if (children) {
      e.preventDefault();
    }

    // If Sidebar nav is being rendered in a popup, hide the popup when a
    // non-parent item is clicked
    if (!children && hidePopup) {
      hidePopup();
    }

    setNav(updateNavExpandedState(nav, [path]));
  };

  const {panelProps} = useSidePanel();

  const model = useListModel({
    orientation: 'horizontal',
    shouldVirtualize: false,
    id: 'cs-platform-switcher',
    items: [
      {id: 'all', value: 'All'},
      {id: 'web', value: 'Web'},
      {id: 'ios', value: 'iOS'},
      {id: 'android', value: 'Android'},
    ],

    onSelect: data => {
      platformSwitcherContext.selectPlatform(data.id);
      persistToSessionStorage(platformStorageKey, data.id);
      if (data.id === 'ios') {
        setNav(generateStatelessNav(sectionMap, iOSHierarchy));
      } else if (data.id === 'android') {
        setNav(generateStatelessNav(sectionMap, androidHierarchy));
      } else if (data.id === 'web') {
        setNav(generateStatelessNav(sectionMap, webHierarchy));
      } else {
        setNav(generateStatelessNav(sectionMap, hierarchy));
      }
    },
  });

  // TODO: Setting the list model initialSelectedIds to the platform in
  // sessionStorage did not result in the platform being selected from
  // sessionStorage on page load in production (SSR). Instead, we had to use
  // this useEffect to set the platform on Sidebar mount. Investigate why
  // setting initialSelectedIds didn't work.
  React.useEffect(() => {
    const platform = restoreFromSessionStorage(platformStorageKey);
    if (platform) {
      model.events.select({id: platform});
    } else {
      model.events.select({id: 'all'});
    }
  }, []);

  // Expand navigation to reveal current path (if necessary). This must go
  // after the useEffect to select the platform from sessionStorage (in order
  // for the nav hierarchy state to update properly).
  React.useEffect(() => {
    const pathsToUpdate = [];
    const workingParts = currentPage.path.split('/');
    while (workingParts.length > 2) {
      workingParts.splice(-1, 1);
      pathsToUpdate.push(workingParts.join('/'));
    }
    setNav(updateNavExpandedState(nav, pathsToUpdate, true));
  }, [currentPage, platformSwitcherContext.platformValue]);

  const L1MenuOrder = [
    'get-started',
    'styles',
    'components',
    'patterns',
    'frameworks',
    'guidelines',
    'help',
  ];

  return (
    <nav role="navigation" aria-label="Site">
      <StyledSidePanel
        as="aside"
        variant="alternate"
        {...panelProps}
        role="region"
        expanded
        {...elemProps}
      >
        {/*
          This Sidebar is only being used for the responsive web navigation post
          Unified Phase 2. The platform switcher here does not play nicely with
          the Phase 2 work -- remove it for now. This is unlikely to affect
          many users; the platform switcher is internal-only and not many users
          access the site behind the VPN on their mobile devices.
          
          We will add it back in when we implement a proper responsive web nav.
        */}
        {false && (
          <InternalContent>
            <Flex flexDirection="column" borderBottom={`1px solid ${colors.soap500}`}>
              <Heading
                {...type.levels.subtext.large}
                as="h3"
                size="small"
                variant="hint"
                fontWeight={500}
                textTransform="uppercase"
                paddingTop={space.xxs}
                margin={`0 ${space.m}`}
              >
                Platforms
              </Heading>
              <Subtext margin={`8px ${space.m}`} size="medium">
                Choose platform specific documentation
              </Subtext>
              <SelectionGroup model={model} paddingX="m" paddingBottom="s">
                {item => <SelectionGroup.Item data-id={item.id}>{item.value}</SelectionGroup.Item>}
              </SelectionGroup>
            </Flex>
          </InternalContent>
        )}
        {Object.keys(nav).map((section, index) => {
          // reorder the items within nav
          const items = L1MenuOrder.map(name =>
            nav[section].find((navItem: Hierarchy) => navItem.category === name)
          );
          return (
            <SidebarSection
              title={section}
              items={items}
              currentPage={currentPage}
              onItemClick={toggleNavItemState}
              key={`section-${index}`}
            />
          );
        })}
      </StyledSidePanel>
    </nav>
  );
};

export default Sidebar;
