/* eslint-disable @typescript-eslint/no-var-requires */

const React = require('react');
const Apollo = require('@apollo/client');

const client = new Apollo.ApolloClient({
  uri: process.env.AWS_GRAPHQL_URL,
  connectToDevTools: true,
  cache: new Apollo.InMemoryCache(),
  headers: {
    'x-api-key': process.env.AWS_API_KEY,
  },
});

export const wrapRootElement = ({element}) => {
  return <Apollo.ApolloProvider client={client}>{element}</Apollo.ApolloProvider>;
};
