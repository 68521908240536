import React from 'react';

import {Card} from '@workday/canvas-kit-react/card';
import {SecondaryButton} from '@workday/canvas-kit-react/button';
import {accessibleHide} from '@workday/canvas-kit-react/common';
import {chevron2xDownSmallIcon} from '@workday/canvas-system-icons-web';

const SkipToContent = ({...elemProps}) => {
  const [visible, setVisible] = React.useState(false);

  const handleClick = () => {
    const firstHeading = document.querySelector('main h1') as HTMLElement;

    if (firstHeading) {
      firstHeading.setAttribute('tabIndex', -1);

      /* 350 millisecond delay needed for VoiceOver */
      setTimeout(function () {
        firstHeading.focus();
      }, 350);

      const removeTabIndex = () => {
        firstHeading.removeAttribute('tabindex');
        firstHeading.removeEventListener('blur', removeTabIndex);
      };

      firstHeading.addEventListener('blur', removeTabIndex);
    }
  };

  const handleFocus = () => {
    setVisible(true);
  };
  const handleBlur = () => {
    setVisible(false);
  };

  return (
    <div
      css={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 100,
        marginLeft: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        ...(visible ? {} : accessibleHide),
      }}
    >
      <Card css={{borderTopLeftRadius: 0, borderTopRightRadius: 0}} padding="s">
        <SecondaryButton
          icon={chevron2xDownSmallIcon}
          iconPosition="start"
          onClick={handleClick}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...elemProps}
        >
          Skip to Content
        </SecondaryButton>
      </Card>
    </div>
  );
};

export default SkipToContent;
