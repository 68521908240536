import {CSSObject} from '@emotion/styled';
import {spaceNumbers} from '@workday/canvas-kit-react/tokens';

/*
Approach to Responsive Work

Use media queries where possible. Applying responsive styles based on
inspecting the window object is problematic with SSR (1). window does not
exist during Gatsby's SSR build, so we must assume a viewport size. If the
assumption is wrong, the user will see an incorrectly styled page when the
site first loads (before the site is rehydrated). Even after rehydration,
the user may still see incorrect styles unless the window is resized to a
different viewport size (e.g., s to l) (2).

We're not able to use media queries for conditional rendering (e.g.,
rendering different versions of the Header based on viewport size). In these
cases, we use @artsy/fresnel (https://www.npmjs.com/package/@artsy/fresnel).

(1) https://blog.logrocket.com/developing-responsive-layouts-with-react-hooks/#comment-2249
(2) https://www.joshwcomeau.com/react/the-perils-of-rehydration/
*/

// ============================================================================
// Viewport Ranges and Media Query Helpers

export const viewportRanges = {
  s: [0, 767], // Mobile
  m: [768, 1023], // Tablet
  l: [1024, 1439], // Laptop
  xl: [1440, 1919], // Desktop
  xxl: [1920, 10000], // Desktop XL
};

export type ViewportSize = keyof typeof viewportRanges;

export const viewportSizes = Object.keys(viewportRanges);

export type MQFnSizeParam = ViewportSize | number;

export const mqAt = (size: MQFnSizeParam) => {
  const minWidth = typeof size === 'number' ? size : viewportRanges[size][0];
  const maxWidth = typeof size === 'number' ? size : viewportRanges[size][1];
  return `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
};

export const mqLessThan = (size: MQFnSizeParam) => {
  const maxWidth = typeof size === 'number' ? size - 1 : viewportRanges[size][0] - 1;

  return `@media (max-width: ${maxWidth}px)`;
};

export const mqGreaterThan = (size: MQFnSizeParam) => {
  const minWidth = typeof size === 'number' ? size + 1 : viewportRanges[size][1] + 1;
  return `@media (min-width: ${minWidth}px)`;
};

export const mqGreaterThanOrEqual = (size: MQFnSizeParam) => {
  const minWidth = typeof size === 'number' ? size : viewportRanges[size][0];
  return `@media (min-width: ${minWidth}px)`;
};

export const mqBetween = (startSize: MQFnSizeParam, endSize: MQFnSizeParam) => {
  const minWidth = typeof startSize === 'number' ? startSize : viewportRanges[startSize][0];
  const maxWidth = typeof endSize === 'number' ? endSize : viewportRanges[endSize][1];
  return `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type MediaStylesMapFn = (size: ViewportSize, ...args: any[]) => CSSObject;

/**
 * Calls the given mapFn for every viewport size (which generates media styles
 * for each size) and combines them into a single object which looks something
 * like this:
 *
 * ```
 * {
 *   '@media (min-width: 320px) and (max-width: 767px)': {
 *     marginRight: 16,
 *     marginLeft: 16,
 *   },
 *   '@media (min-width: 768px) and (max-width: 1023px)': {
 *     marginRight: 40,
 *     marginLeft: 40,
 *   },
 *   '@media (min-width: 1024px) and (max-width: 1439px)': {
 *     marginRight: 40,
 *     marginLeft: 40,
 *   },
 *   '@media (min-width: 1440px) and (max-width: 1919px)': {
 *     marginRight: 80,
 *     marginLeft: 80,
 *   },
 *   '@media (min-width: 1920px) and (max-width: 10000px)': {
 *     marginRight: 160,
 *     marginLeft: 160,
 *   }
 * }
 * ```
 *
 * @param mapFn A callback function which takes a ViewportSize (and optionally
 * some additional args) and returns a CSS object like so:
 * ```
 * {
 *   '@media (min-width: 320px) and (max-width: 767px)': {
 *     marginRight: 16,
 *     marginLeft: 16,
 *   },
 * }
 * ```
 * @param args Additional args to be passed to the mapFn
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const combineMediaQueries = (mapFn: MediaStylesMapFn, ...args: any[]) => {
  return viewportSizes
    .map((size: ViewportSize) => mapFn(size, ...args))
    .reduce((allStyles, currentStyles) => ({...allStyles, ...currentStyles}), {});
};

// ============================================================================
// UI Dimensions

/**
 * The height of the site header.
 */
export const headerHeight = 64;

/**
 * The vertical gap between the header and the main content area if the content
 * area contains a preamble.
 */
export const headerPreambleContentGap = 8;

/**
 * The maximum width of the main content.
 */
export const maxContentWidth = 740;

/**
 * The maximum width of the main content for bespoke pages (e.g., Home).
 */
export const maxBespokeContentWidth = 1000;

/**
 * The bottom padding of the main content.
 */
export const contentBottomPadding = 120;

/**
 * The width of the Sidebar navigation.
 */
export const sidebarWidth = 320;
export const sidebarWidthCollapsed = 98;
export const sidebarWidthExpanded = 356;

/**
 * The horizontal space around the main content (between the Sidebar navigation
 * and the content). This space is commonly referenced throughout the UI.
 */
export const contentHSpace = {
  s: spaceNumbers.s,
  m: spaceNumbers.xl,
  l: spaceNumbers.xl,
  xl: spaceNumbers.xxxl,
  xxl: spaceNumbers.xxxl * 2,
};

/**
 * The border radius of the "bento box" portions of the UI (e.g., preamble).
 */
export const bentoRadius = 16;

/**
 * The width of the Level 1 navigation items in the sidebar.
 */
export const L1NavItemWidth = 82;
