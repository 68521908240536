import {
  rocketIcon,
  componentIcon,
  patternIcon,
  playbookIcon,
  shapesIcon,
  bookOpenIcon,
  infoIcon,
} from '@workday/canvas-system-icons-web';
import {CanvasSystemIcon} from '@workday/design-assets-types';

export type CategoryMeta = {
  icon: CanvasSystemIcon;
  description?: string;
};

export const getCategoryMeta = (category: string): CategoryMeta | undefined => {
  switch (category) {
    case 'get-started':
      return {
        icon: rocketIcon, // signpost not available yet.
      };
    case 'styles':
      return {
        icon: shapesIcon,
      };
    case 'components':
      return {
        icon: componentIcon,
      };
    case 'patterns':
      return {
        icon: patternIcon,
      };
    case 'frameworks':
      return {
        icon: playbookIcon,
      };
    case 'guidelines':
      return {
        icon: bookOpenIcon,
      };
    case 'help':
      return {
        icon: infoIcon,
      };
  }
  return;
};

export default getCategoryMeta;
