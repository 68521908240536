import React from 'react';
import {type} from '@workday/canvas-kit-react/tokens';
import {Box} from '@workday/canvas-kit-react/layout';

import {endsWith} from '../../utils/strings';
import {IncorrectGif} from './Gif';

export interface ImageProps {
  src: string;
  alt: string;
}

export const Image = ({src, alt, ...elemProps}: ImageProps) => {
  // Center SVGs and GIFs
  if (endsWith(src, '.svg')) {
    return (
      <span css={{display: 'block', textAlign: 'center'}}>
        <img src={src} alt={alt} {...elemProps} />
      </span>
    );
  }
  // Render static image if reduce motion is enabled
  if (endsWith(src, '.gif')) {
    return <IncorrectGif />;
  }

  // Return other images as is (they're already being styled and processed by
  // gatsby-image)
  return <img src={src} alt={alt} {...elemProps} />;
};

export interface FormattedImageProps {
  /**
   * The text displayed underneath the image.
   */
  caption?: string;
  children: React.ReactNode;
}

export const FormattedImage = ({caption, children, ...elemProps}: FormattedImageProps) => {
  return (
    <Box
      marginY="xl"
      css={{
        // Override margins applied to .gatsby-resp-image-wrapper in
        // ContentContainer (for images in the main content, we don't want
        // margins on images used within FormattedImage)
        '.gatsby-resp-image-wrapper': {margin: 0},
      }}
      {...elemProps}
    >
      {children}
      {caption && <p css={{...type.levels.subtext.medium, textAlign: 'center'}}>{caption}</p>}
    </Box>
  );
};
