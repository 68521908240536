import React from 'react';
import {ExtractProps} from '@workday/canvas-kit-react/common';
import {Box, BoxProps} from '@workday/canvas-kit-react/layout';

import {contentHSpace, maxContentWidth, mqAt, sidebarWidth} from '../../utils/breakpoints';
import {calcFullWidth} from '../../utils/style';

export interface ContentOverflowBoxProps extends BoxProps, ExtractProps<typeof Box> {
  children?: React.ReactNode;
  widthReduction?: number;
}

/**
 * Some elements need to occupy the full available width of the main content
 * container (i.e., ContentBody) AND provide horizontal scrolling. This includes:
 *
 * (1) Rendered code examples: the rendered component and/or the code snippet
 *     may have a minWidth that exceeds the width of ContentBody.
 * (2) Tables: tables will require horizontal scrolling to remain readable at
 *     small viewport sizes.
 *
 * These components should be rendered within a ContentOverflowBox. overflowX
 * only works if the element has a set width; `width: 100%` does NOT work, so
 * we must calculate the width based on the size of the viewport. ContentOverflowBox
 * takes the horizontal space around ContentBody into account when calculating
 * the width.
 *
 * ContentOverflowBox also accepts a `widthReduction` prop in case it needs to
 * be nested within another ContentOverflowBox and the width of the inner
 * ContentOverflowBox needs to be reduced to fit within the outer
 * ContentOverflowBox (for example, if the outer ContentOverflowBox has
 * borders). For an example of this use case, see the CodeBlock component which
 * has an outer ContentOverflowBox with borders and inner ContentOverflowBoxes
 * for the rendered code example and the corresponding code snippet.
 *
 * TODO: is there a better way to create a full-width component with horizontal
 * scrolling? I was surprised it was this difficult, but this is the only way
 * I've gotten this to work.
 *
 * TODO: should an inner `<Box display="inline-block" minWidth="100%">` wrap
 * children? Or maybe be provided as subcomponent of ContentOverflowBox? For
 * more context, see how `<Box display="inline-block"...` is used in CodeBlock.
 */
export const ContentOverflowBox = ({
  children,
  widthReduction = 0,
  ...elemProps
}: ContentOverflowBoxProps) => {
  const styles = {
    [mqAt('s')]: {
      width: calcFullWidth(contentHSpace.s, widthReduction),
    },
    [mqAt('m')]: {
      width: calcFullWidth(contentHSpace.m, widthReduction),
    },
    [mqAt('l')]: {
      // Compensate for presence of Sidebar
      width: calcFullWidth(contentHSpace.l, sidebarWidth + widthReduction),
    },
  };

  return (
    // Width should never exceed maxContentWidth
    <Box overflowX="auto" maxWidth={maxContentWidth - widthReduction} css={styles} {...elemProps}>
      {children}
    </Box>
  );
};
