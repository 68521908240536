import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {getCategoryMeta} from './getCategoryMeta';
import {useSiteMetadata} from './useSiteMetadata';
import {kebabToTitleCase} from './strings';
import {CanvasSystemIcon} from '@workday/design-assets-types';

import {patternIcon} from '@workday/canvas-system-icons-web';

export interface Hierarchy {
  title: string;
  path: string;
  level: number;
  rank: number | null;
  isPage: boolean;
  icon?: CanvasSystemIcon;
  children?: Hierarchy[];
  type?: 'section' | 'parent' | 'page';
  category?: string;
  description?: string;
}

type PageNode = {
  node: {
    context: {
      frontmatter: Hierarchy;
    };
    componentChunkName: string;
    path: string;
  };
};

/**
 * Used to provide a rank to nav directories within the hierarchy that are
 * not associated with a specific page (and therefore can't have a rank
 * specified in Markdown frontmatter).
 *
 * NOTE: This will be superceded at the top level of the navigation by the
 * sectionMap in the Sidebar component. This only continues to be supported
 * for cases where we want to rank sub-categories.
 *
 * TODO: This should be made generic if/when we convert this into a Gatsby theme.
 */
const directoryRankMap: {[key: string]: number} = {
  '/getting-started/for-designers': 1,
  '/getting-started/for-developers': 2,
};
const hasRank = (hierarchy: Hierarchy) => hierarchy.rank !== null && hierarchy.rank !== undefined;

/**
 * Maps segment (i.e., folder) slugs in the URL to more readable titles. Used
 * for cases where the title includes characters such as punctuation marks that
 * cannot be used in a URL.
 */
const segmentTitleMap: {[key: string]: string} = {
  'tasks-domains-and-business-processes': 'Tasks, Domains and Business Processes',
  'whats-new': "What's New",
  'ai-guidance': 'AI Guidance',
};

/**
 * Recursively sorts the hierarchy by traversing the children and sorting on the following fields (in order):
 * 1. Rank
 * 2. Children exist
 * 3. Alphabetical
 */
const sort = (hierarchy: Hierarchy) => {
  hierarchy.children = hierarchy.children?.sort((a: Hierarchy, b: Hierarchy) => {
    // 1. Sort by rank (ranked entries go before unranked entries)
    const aHasRank = hasRank(a);
    const bHasRank = hasRank(b);

    if (aHasRank && !bHasRank) {
      return -1;
    } else if (!aHasRank && bHasRank) {
      return 1;
    } else if (aHasRank && bHasRank) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return a.rank! > b.rank! ? 1 : -1;
    }

    // 2. Ranks are equal. Sort by children exist (entries with no children go before entries with children)
    if (!a.children && b.children) {
      return -1;
    }

    // 4. Ranks and children existence are equal. Sort by alpha
    return a.title.localeCompare(b.title);
  });

  if (hierarchy.children?.length) {
    hierarchy.children.forEach(child => sort(child));
  }
  return hierarchy;
};

const getCategory = (path: string) => path.split('/')[1];

/**
 * Converts a segment to a more readable title to be displayed in the site
 * navigation. Looks in segmentTitleMap first; otherwise defaults to converting
 * kebab to title case.
 */
const createSegmentTitle = (segment: string): string =>
  segmentTitleMap[segment] || kebabToTitleCase(segment);

const createDirectory = (page: Hierarchy, segment: string, level: number): Hierarchy => {
  const path = `/${page.path
    .split('/')
    .slice(1, level + 1) // offset by one to account for leading '/'
    .join('/')}`;

  const meta = getCategoryMeta(segment);

  return {
    path,
    level,
    title: createSegmentTitle(segment),
    icon: meta && meta.icon,
    children: [],
    rank: directoryRankMap[path],
    category: getCategory(path),
    description: meta && meta.description,
    isPage: false,
  };
};

const addChild = (
  node: Hierarchy,
  {title, path, rank, icon, description}: Hierarchy,
  level: number,
  isPage: boolean
) => {
  const child = {
    title,
    path,
    icon,
    level,
    rank,
    isPage,
    description,
    category: getCategory(path),
  };

  if (node.children) {
    node.children.push(child);
  } else {
    node.children = [child];
  }
};

// Export buildHierarchy for platform-specific useHierarchy hooks
export const buildHierarchy = (pages: PageNode[]) => {
  const config = useSiteMetadata();
  return React.useMemo(() => {
    const navTree: Hierarchy[] = pages.reduce(
      (tree: Hierarchy[], {node}: PageNode) => {
        const page = node.context?.frontmatter || {};

        // Remove Gatsby's trailing slash
        // TODO: https://ghe.megaleo.com/design/canvas-site/issues/38
        page.path = node.path.replace(/\/$/, '');

        // Don't add any pages within `/patterns` to the hierarchy (e.g.,
        // /patterns/[id].tsx). The only Pattern page we want want to show in
        // the sidenav is the Patterns Overview/Landing page, which we will
        // manually add at the end of buildHierarchy.
        if (page.path.indexOf('/patterns') !== -1) {
          return tree;
        }
        // Split the uri into its directories
        const root = tree[0];
        const path = page.path.split('/');
        path.shift();

        // Keep track of current directory when building the tree
        let pwd = root;

        if (path.length > 1) {
          // Iterate through each segment of the uri, creating directories and children
          path.forEach((segment: string, index: number) => {
            // The final element of uri is a link/page
            if (index === path.length - 1) {
              addChild(pwd, page, index + 1, true);
            } else {
              const existing = pwd.children?.find(l => l.title === createSegmentTitle(segment));
              // Navigate to the existing directory
              if (existing) {
                pwd = existing;
              }
              // Or create a new directory and navigate to it
              else {
                const dir = createDirectory(page, segment, index + 1);
                addChild(pwd, dir, dir.level, false);

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                pwd = pwd.children![pwd.children!.length - 1];
              }
            }
          });
        } else {
          // The uri has no subdirectories, add it to the root
          addChild(root, page, 1, true);
        }

        return tree;
      },
      [
        {
          path: '/',
          title: 'Home',
          children: [],
          level: 0,
          rank: 1,
          isPage: true,
        },
      ]
    );

    // Manually add Patterns Overview page to the hierarchy, but only for
    // internal builds (since all Patterns content is currently internal-only
    // with Phase 1 of the Canvas Site/WPL merge)
    if (config.internal) {
      navTree[0].children?.push({
        category: 'patterns',
        title: 'Patterns',
        path: '/patterns',
        level: 1,
        rank: 1,
        isPage: false,
        icon: patternIcon,
        children: [
          {
            title: 'Overview',
            path: '/patterns/overview',
            level: 2,
            rank: 1,
            isPage: true,
            category: 'patterns',
          },
          {
            title: 'Patterns',
            path: '/patterns/patterns',
            level: 2,
            rank: 2,
            isPage: true,
            category: 'patterns',
          },
          {
            title: 'Proto Patterns',
            path: '/patterns/proto',
            level: 2,
            rank: 3,
            isPage: true,
            category: 'patterns',
          },
        ],
      });
    }

    return sort(navTree[0]);
  }, [pages]);
};

export const useHierarchy = () => {
  // We query allSitePage instead of allMdx because we remove internal pages in createPage
  const {
    allSitePage: {edges: pages},
  } = useStaticQuery(graphql`
    {
      allSitePage(
        filter: {componentChunkName: {regex: "/^component---content/"}}
        sort: {fields: [path], order: ASC}
      ) {
        edges {
          node {
            path
            componentChunkName
            context {
              frontmatter {
                rank
                title
              }
            }
          }
        }
      }
    }
  `);

  // Decomposed so we can memoize everything we do to the query.
  return buildHierarchy(pages);
};
