import React from 'react';
import slugify from 'slugify';

import {accessibleHide, createComponent, useForkRef} from '@workday/canvas-kit-react/common';
import {SystemIcon} from '@workday/canvas-kit-react/icon';
import {space, type} from '@workday/canvas-kit-react/tokens';

import {linkIcon} from '@workday/canvas-system-icons-web';

import {useNavigation} from '../nav/Navigation';

import {voiceOverFocusDelay} from '../../utils/a11y';
import {textOverflowStyles} from '../../utils/style';
import {getFragmentElement, getHeadingLink} from '../../utils/urlFragment';

export interface HeadingProps {
  children: string;
  id?: string;
}

const headingStyles = {
  ...textOverflowStyles,
  position: 'relative',

  // AnchorNav will apply focus to headings when they are navigated to using
  // AnchorNav links or via the heading value in the URL fragment (as per a11y
  // requirements). No need to show the focus outline.
  outline: 'none',

  a: {
    transition: 'opacity .2s ease-out 0s',
  },

  '&:hover a': {
    marginLeft: space.xxs,
  },

  '&:not(:hover) a': {
    ...accessibleHide,
    opacity: 0,
  },
} as const;

// Heading is a base component used to generate headings (h2 and onwards) with
// quick-links/permalinks on hover. It is not used for h1 since h1 requires
// custom behavior (see below) and does not have a quick-link.
const Heading = createComponent('h2')({
  displayName: 'Heading',
  Component: ({children, id, ...elemProps}: HeadingProps, ref, Element) => {
    // internalHeadingRef is needed for internal use to find the tab the
    // the heading is contained in (needed to generate a link to the heading)
    const internalHeadingRef = React.useRef<HTMLHeadingElement>(null);

    // Use useForkRef to maintain support for a ref passed into the Heading
    // component (if present) in conjunction with our internal ref
    const headingRef = useForkRef(ref, internalHeadingRef);

    const [headingTabSlug, setHeadingTabSlug] = React.useState('');

    React.useEffect(() => {
      const tabName = internalHeadingRef.current
        ?.closest('[role="tabpanel"]')
        ?.getAttribute('data-id');
      if (tabName) {
        setHeadingTabSlug(slugify(tabName, {lower: true}));
      }
    }, []);

    return (
      <Element id={id} ref={headingRef} css={headingStyles} {...elemProps}>
        {children}
        {id && (
          <a href={getHeadingLink(id, headingTabSlug)} aria-label={`Permalink - ${children}`}>
            <SystemIcon icon={linkIcon} />
          </a>
        )}
      </Element>
    );
  },
});

export const H2 = ({children, ...elemProps}: HeadingProps) => {
  return (
    <Heading
      as="h2"
      css={{...type.levels.heading.large, marginTop: space.xxl, marginBottom: space.xs}}
      {...elemProps}
    >
      {children}
    </Heading>
  );
};

export const H3 = ({children, ...elemProps}: HeadingProps) => {
  return (
    <Heading
      as="h3"
      css={{...type.levels.heading.medium, marginTop: space.xl, marginBottom: space.xs}}
      {...elemProps}
    >
      {children}
    </Heading>
  );
};

export const H4 = ({children, ...elemProps}: HeadingProps) => {
  return (
    <Heading
      as="h4"
      css={{...type.levels.heading.small, marginTop: space.xl, marginBottom: space.xs}}
      {...elemProps}
    >
      {children}
    </Heading>
  );
};

export const H5 = ({children, ...elemProps}: HeadingProps) => {
  return (
    <Heading
      as="h5"
      css={{
        ...type.levels.body.large,
        fontWeight: type.properties.fontWeights.bold,
        marginTop: space.l,
        marginBottom: space.xxs,
      }}
      {...elemProps}
    >
      {children}
    </Heading>
  );
};

// h1 does not use the Heading base component since it does not have a
// quick-link. It also requires special focus management: when the newly
// appointed screen is loaded, programmatic focus needs to be managed to mimic
// the behavior of a native page load. We dynamically add the tabindex="-1"
// attribute to the h1, and after the view is loaded, set focus to the h1. It
// is recommended to set focus after a 350 millisecond delay to account for
// VoiceOver. This will place screen reader focus on the main heading in the
// document. On blur of the h1, remove the tabindex="-1" attribute.
// TODO: Need to remove tabindex on blur
export const H1 = ({children, ...elemProps}: HeadingProps) => {
  const nav = useNavigation();
  const h1Element = React.useRef<HTMLHeadingElement>(null);

  React.useEffect(() => {
    // If an L1 nav item was just activated, do NOT auto-focus the h1 on the
    // newly loaded page (since we instead want focus to pass to the newly
    // activated L2).
    if (!nav.isL1Activated()) {
      const delayedFocusTimer = setTimeout(() => {
        // Don't focus h1 if there's an element encoded in the URL fragment
        // since that element will already be focused (see Layout)
        if (!getFragmentElement() && h1Element.current) {
          // Prevent scrolling when focusing the h1. If the user presses the back
          // button to return to a page, we want to preserve the default browser
          // behavior of preserving whereever the user scrolled to when they were
          // last at the page (instead of scrolling to the focused h1, which is
          // the default behavior when focusing an element).
          //
          // TODO: preventScroll is not supported in IE or Safari. Address this, if
          // necessary.
          h1Element.current.focus({preventScroll: true});
        }
      }, voiceOverFocusDelay);
      return () => clearTimeout(delayedFocusTimer);
    }
    return;
  }, []);

  return (
    <h1
      css={{...type.levels.title.medium, marginBottom: space.s, outline: 'none'}}
      tabIndex={-1}
      {...elemProps}
      ref={h1Element}
    >
      {children}
    </h1>
  );
};
