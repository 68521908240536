import React from 'react';
import styled from '@emotion/styled';
import {colors, type} from '@workday/canvas-kit-react/tokens';
import {pickForegroundColor} from '@workday/canvas-kit-react/common';

import {useStatusRegion} from '../../StatusRegion';

export interface ColorCopyProps {
  colorName: string;
  onFocus?: () => void;
  onBlur?: () => void;
}

const Container = styled('div')({
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  transition: 'transform 0.2s ease-out',
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1,

  input: {
    opacity: 0, // Can't use visibility or display and call select()
    zIndex: -1,
    position: 'absolute',
  },

  button: {
    ...type.levels.subtext.large,
    cursor: 'pointer',
    border: 0,
    background: 'transparent',
    // Font size of 13px is not present in CK type tokens
    fontSize: '13px',
    fontWeight: type.properties.fontWeights.bold,
    width: '100%',
    height: '100%',
    opacity: 0,
    outline: 'none',
    transition: 'opacity 0.2s ease-out',
    '&:hover, &:focus': {
      opacity: 1,
    },
  },
});

export const ColorCopy = ({colorName, onFocus, onBlur, ...elemProps}: ColorCopyProps) => {
  const hex = colors[colorName];
  if (!hex) {
    return null;
  }

  const [copied, setCopied] = React.useState(false);
  const copyButtonRef = React.useRef<HTMLButtonElement>(null);

  const statusRegion = useStatusRegion();

  const handleColorClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    const container = (e.target as HTMLElement).parentElement;

    const input = container?.getElementsByTagName('input')[0];

    if (input && input.value) {
      input.select();
      document.execCommand('copy');

      // Immediately pass focus back to the copy button to prevent it from
      // fading away after losing focus due to the input.select() call a couple
      // lines up
      if (copyButtonRef.current) {
        copyButtonRef.current.focus();
      }

      // Politely announce the copied color to screen readers
      statusRegion.announcePolite(`${colorName} hexadecimal code ${input.value} copied`);

      setCopied(true);
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  };

  return (
    <Container {...elemProps}>
      <button
        aria-label={`Copy ${colorName} hexadecimal code ${hex}`}
        onBlur={onBlur}
        onFocus={onFocus}
        onClick={handleColorClick}
        ref={copyButtonRef}
        type="button"
        style={{color: pickForegroundColor(hex, colors.blackPepper400, colors.frenchVanilla100)}}
      >
        {copied ? 'Copied!' : 'Copy'}
      </button>
      {/* Prevent the visually hidden input from being tabbed to */}
      <input aria-hidden="true" value={hex} tabIndex={-1} type="text" readOnly />
    </Container>
  );
};

export default ColorCopy;
