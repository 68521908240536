import React from 'react';
import {colors, type, space} from '@workday/canvas-kit-react/tokens';
import {DeprecatedMenuItem, DeprecatedMenuItemProps} from '@workday/canvas-kit-preview-react/menu';
import {SystemIcon} from '@workday/canvas-kit-react/icon';
import {chevronRightSmallIcon} from '@workday/canvas-system-icons-web';
import {SearchItem} from '../../utils/search';
import {kebabToTitleCase} from '../../utils/strings';
import {getCategoryMeta} from '../../utils/getCategoryMeta';

const selectedStyling = {
  '[aria-selected="true"] &': {
    color: `${colors.frenchVanilla100} !important`,
    '.wd-icon-fill, .wd-icon-accent': {
      fill: `${colors.frenchVanilla100} !important`,
    },
  },
};

export const getAutocompleteList = (
  results: SearchItem[],
  onItemClick: (path: string, event: React.MouseEvent) => void,
  onAllResultsClick: (event: React.MouseEvent) => void
): React.ReactElement<DeprecatedMenuItemProps>[] => {
  const list = results.slice(0, 3).map(({title, path}) => {
    const breadcrumb = path.split('/').slice(1, -2);
    const categoryIcon = getCategoryMeta(breadcrumb[0])?.icon;

    return (
      <DeprecatedMenuItem
        onClick={onItemClick.bind(undefined, path)}
        key={path}
        css={{
          height: 'auto',
        }}
      >
        <div
          css={{
            ...type.levels.body.small,
            fontWeight: type.properties.fontWeights.bold,
            ...selectedStyling,
          }}
        >
          {categoryIcon && (
            <SystemIcon
              color={colors.licorice300}
              colorHover={colors.licorice300}
              size={20}
              css={{margin: `-${space.xxxs} ${space.xxs} -${space.xxxs} 0`, ...selectedStyling}}
              icon={categoryIcon}
            />
          )}
          {title}
        </div>
        <p
          css={{
            ...type.levels.subtext.medium,
            ...type.variants.hint,
            ...selectedStyling,
          }}
        >
          in{' '}
          {breadcrumb.map((parent, index) => {
            return (
              <>
                {kebabToTitleCase(parent)}
                {index < breadcrumb.length - 1 && (
                  <SystemIcon
                    color={colors.licorice300}
                    colorHover={colors.licorice300}
                    size={20}
                    css={{margin: '-6px 2px', ...selectedStyling}}
                    icon={chevronRightSmallIcon}
                  />
                )}
              </>
            );
          })}
        </p>
      </DeprecatedMenuItem>
    );
  });

  if (results.length) {
    list.push(
      <DeprecatedMenuItem
        onClick={onAllResultsClick}
        css={{
          margin: `${space.xxxs} 0`,
          background: colors.soap100,
          fontWeight: type.properties.fontWeights.medium,
          '&[aria-selected="true"]': {
            background: colors.blueberry400,
          },
        }}
      >
        See all results ({results.length})
      </DeprecatedMenuItem>
    );
  }

  return list;
};
