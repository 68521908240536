import {createMedia} from '@artsy/fresnel';
import {viewportRanges} from '../utils/breakpoints';

// Code from https://github.com/artsy/fresnel#ssr-example

const CanvasSiteMedia = createMedia({
  breakpoints: {
    s: viewportRanges.s[0],
    m: viewportRanges.m[0],
    l: viewportRanges.l[0],
    xl: viewportRanges.xl[0],
    xxl: viewportRanges.xxl[0],
  },
});

// Generate CSS to be injected into the head
export const mediaStyles = CanvasSiteMedia.createMediaStyle();
export const {Media, MediaContextProvider} = CanvasSiteMedia;
