import React from 'react';
import styled from '@emotion/styled';
import {Hyperlink} from '@workday/canvas-kit-react/button';
import {space} from '@workday/canvas-kit-react/tokens';
import {emptyStateSearchGraphic} from '@workday/canvas-graphics-web';

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  margin: `${space.xxl} 0`,
});

export const IconEmptyState = ({clearSearch}: {clearSearch: () => void}) => {
  const reset = e => {
    e.preventDefault();
    clearSearch();
  };

  return (
    <Container>
      <div dangerouslySetInnerHTML={{__html: emptyStateSearchGraphic.svg}} />
      <p>
        We couldn't find any matching icons.
        <br />
        Try another term, or{' '}
        <Hyperlink href="#" onClick={reset}>
          reset your search
        </Hyperlink>
        .
      </p>
    </Container>
  );
};

export default IconEmptyState;
