import React from 'react';
import {space} from '@workday/canvas-kit-react/tokens';
import {ResponsiveFlex} from './ResponsiveFlex';

export interface SideBySideProps {
  children: React.ReactNode;
}

export const SideBySide = ({children, ...elemProps}: SideBySideProps) => (
  <ResponsiveFlex
    columnIfLessThan="m"
    sSpacing={space.m}
    mSpacing={space.m}
    lSpacing={space.m}
    xxlSpacing={space.l}
    marginBottom="l"
    marginTop="m"
    {...elemProps}
  >
    {children}
  </ResponsiveFlex>
);
