import React from 'react';
import {navigate} from 'gatsby';
import {Flex} from '@workday/canvas-kit-react/layout';
import {colors, space, spaceNumbers} from '@workday/canvas-kit-react/tokens';
import {styled} from '@workday/canvas-kit-react/common';
import {TertiaryButton} from '@workday/canvas-kit-react/button';
import {arrowLeftIcon, searchIcon} from '@workday/canvas-system-icons-web';
import {FormField, FormFieldLabelPosition} from '@workday/canvas-kit-react/form-field';
import {Combobox} from '@workday/canvas-kit-labs-react/combobox';
import {TextInput, TextInputProps} from '@workday/canvas-kit-react/text-input';
import {getQueryValue, getSearchResults} from '../../utils/search';
import {getAutocompleteList} from './getAutocompleteList';

export interface SearchFormProps {
  /**
   * The placeholder text of the SearchForm text input.
   * @default Search
   */
  placeholder?: string;
  /**
   * The value of the SearchForm text input.
   */
  value?: string;
  /**
   * Whether or not the SearchForm should be rendered in mobile mode (i.e.,
   * rendered over the full width of the site Header)
   */
  mobile?: boolean;
  /**
   * A function to be called when we wish to hide the SearchForm in mobile
   * mode.
   */
  hideSearch?: () => void;
}

const SearchIcon = styled(TertiaryButton)<{mobile?: boolean}>(({mobile}) => ({
  display: mobile ? undefined : 'flex',
  width: mobile ? undefined : space.l,
  height: mobile ? undefined : space.l,
  margin: mobile ? `auto ${space.xxs}` : `auto ${space.xxxs}`,
}));

const SearchField = styled(FormField)<{mobile?: boolean}>(
  {
    display: 'inline-block',
    width: '100%',
    marginBottom: space.zero,
    '> div': {
      display: 'block',
    },
  },
  ({mobile}) => ({
    height: mobile ? undefined : space.xl,
  })
);

const SearchInput = styled(TextInput)<TextInputProps & {focused: boolean; mobile: boolean}>(
  {
    transition: 'background-color 120ms, color 120ms, box-shadow 200ms, border-color 200ms',
    width: '100%',
    paddingRight: space.xl,

    '&::-webkit-search-cancel-button': {
      display: 'none',
    },
  },
  ({mobile}) => ({
    borderRadius: mobile ? 0 : undefined,
    color: mobile ? undefined : colors.frenchVanilla100,
    height: mobile ? 64 : undefined,
    maxWidth: mobile ? undefined : 480,
    paddingLeft: mobile
      ? spaceNumbers.xl + 2 * spaceNumbers.xxs
      : spaceNumbers.xl + spaceNumbers.xxs,
  }),
  // TODO: Confirm we want to hide the focus ring for the mobile search input
  ({mobile}) =>
    mobile && {
      border: 0,
      // TODO: See if there's an alternative to using !important here
      borderBottom: `1px solid ${colors.soap400} !important`,
      '&:focus': {
        // TODO: See if there's an alternative to using !important here
        boxShadow: 'none !important',
      },
    },
  ({focused}) =>
    focused && {
      color: colors.blackPepper300,
      background: colors.frenchVanilla100,
      '&::placeholder': {
        color: colors.licorice300,
      },
    }
);

const buildStatusString = (resultCount: number): string => {
  return `${resultCount} result${resultCount === 1 ? '' : 's'}`;
};

export const SearchForm = ({
  placeholder = 'Search',
  mobile = false,
  hideSearch,
  ...elemProps
}: SearchFormProps) => {
  const [query, setQuery] = React.useState(getQueryValue('q') || '');
  const [focused, setFocused] = React.useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const formRef = React.useRef<HTMLFormElement>(null);

  const handleSearchKey = React.useCallback(event => {
    if (
      event.key === '/' &&
      !(document.activeElement && ['INPUT', 'TEXTAREA'].includes(document.activeElement?.nodeName))
    ) {
      // eslint-disable-next-line mdx/no-unused-expressions
      inputRef.current?.focus();
      event.preventDefault();
    }
  }, []);

  React.useEffect(() => {
    window.addEventListener('keydown', handleSearchKey);
    return () => {
      window.removeEventListener('keydown', handleSearchKey);
    };
  }, [handleSearchKey]);

  // Focus input on mount if SearchForm is in mobile mode
  React.useEffect(() => {
    if (mobile && inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSubmit = (event: React.SyntheticEvent): void => {
    event.preventDefault();

    if (query === '') {
      // eslint-disable-next-line mdx/no-unused-expressions
      inputRef.current?.focus();
      return;
    }

    // eslint-disable-next-line mdx/no-unused-expressions
    inputRef.current?.blur();
    navigate('/search?q=' + query);

    if (hideSearch) {
      hideSearch();
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.startsWith('See all results')) {
      // Controlled components are stupid.
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      inputRef.current!.value = query;
      return;
    }
    setQuery(value);
  };

  const onAutocompleteClick = (path: string) => {
    setQuery('');
    navigate(path);

    if (hideSearch) {
      hideSearch();
    }
  };

  const results = getSearchResults(query);

  return (
    <form
      role="search"
      action="."
      aria-labelledby="search-input-label"
      onSubmit={handleSubmit}
      ref={formRef}
      {...elemProps}
    >
      <Flex
        position="relative"
        alignItems="center"
        height={mobile ? undefined : space.xl}
        width={mobile ? undefined : 320}
      >
        <SearchField
          id="search-input-label"
          inputId="search-input"
          label="Search"
          grow={true}
          labelPosition={FormFieldLabelPosition.Hidden}
          mobile={true}
        >
          <Combobox
            initialValue={query}
            clearButtonVariant={focused ? undefined : 'inverse'}
            onChange={handleInputChange}
            clearButtonAriaLabel="Reset search form"
            labelId="search-input"
            showClearButton={true}
            onFocus={setFocused.bind(undefined, true)}
            onBlur={setFocused.bind(undefined, false)}
            autocompleteItems={getAutocompleteList(results, onAutocompleteClick, handleSubmit)}
            getStatusText={buildStatusString.bind(undefined, results.length)}
            css={{
              'input + div, button[aria-label="Reset search form"] + div': {
                // Add 4px to offset the negative 4px top margin built into the
                // Combobox menu
                height: mobile && results.length ? 'calc(100vh - 64px + 4px)' : undefined,
                maxHeight: 'none',
                '[role="listbox"]': {maxHeight: 'none'},
              },
            }}
          >
            <SearchInput
              ref={inputRef}
              focused={focused}
              value={query}
              placeholder={placeholder}
              name="search"
              autoComplete="off"
              mobile={mobile}
            />
          </Combobox>
        </SearchField>
        {mobile ? (
          <SearchIcon
            icon={arrowLeftIcon}
            aria-label="Hide Search"
            onClick={hideSearch}
            mobile={true}
          />
        ) : (
          <SearchIcon
            aria-label="Search"
            icon={searchIcon}
            onFocus={setFocused.bind(undefined, true)}
            onBlur={setFocused.bind(undefined, false)}
            type="submit"
            // TODO-styling: Styles were previously defined in SearchIcon.
            // Absolute positioning was not working with the build after
            // upgrading to v10, so we moved all styles inline for now.
            style={{position: 'absolute', top: 0, bottom: 0, left: 0, padding: 0, zIndex: 3}}
          />
        )}
      </Flex>
    </form>
  );
};
