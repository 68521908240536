import React from 'react';

export interface MdxFileProps {
  /**
   * The MDX file imported within another MDX file.
   *
   * @example
   * import MyFile from '@workday/canvas-kit-docs/dist/mdx/MY-FILE.mdx';
   *
   * <MdxFile>
   *   <MyFile />
   * </MdxFile>
   */
  children: React.ReactElement;
  /**
   * Remove child elements until an element with a matching ID is found
   */
  removeUpToId?: string;
  /**
   * A transform function that receives an HTMLDivElement containing the compiled MDX
   * for any alterations to made.
   *
   * @example
   * <MdxFile transform={(html) => html.getElementsByClassName('foo')[0].remove()}>
   *   <MyFile />
   * </MdxFile>
   */
  transform?: (html: Element) => void;
}

export const MdxFile = ({children, removeUpToId, transform}: MdxFileProps) => {
  if (!removeUpToId && !transform) {
    return children;
  }

  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!ref.current) {
      return;
    }
    const html = ref.current! as HTMLDivElement;
    if (removeUpToId) {
      while (html.children[0].id !== removeUpToId) {
        html.children[0].remove();
      }
    }
    if (transform) {
      transform(html);
    }
  }, [ref.current]);

  return <div ref={ref}>{children}</div>;
};
