import React from 'react';
import styled, {CSSObject} from '@emotion/styled';

import {Card as CanvasCard} from '@workday/canvas-kit-react/card';
import {Flex} from '@workday/canvas-kit-react/layout';
import {colors, space, type} from '@workday/canvas-kit-react/tokens';

import {arrowRightSmallIcon} from '@workday/canvas-system-icons-web';
import {CanvasSystemIcon} from '@workday/design-assets-types';

import {TertiaryButtonLink} from './Link';
import {HeadingTag} from '../../utils/types';

export interface CardLinkProps {
  children: React.ReactNode;
  icon?: CanvasSystemIcon;
  to: string;
}

export const CardLink = ({children, icon = arrowRightSmallIcon, to}: CardLinkProps) => (
  <TertiaryButtonLink href={to} icon={icon} css={{marginTop: space.s}}>
    {children}
  </TertiaryButtonLink>
);

export interface CardProps {
  children: React.ReactNode;
  headingLevel?: HeadingTag;
  headingStyles?: CSSObject;
  /**
   * The text displayed as the Card heading.
   */
  headingText: string;
  /**
   * The icon displayed in the Card.
   */
  icon: React.ReactElement;
  /**
   * The text displayed as the Card link.
   */
  linkText?: string;
  /**
   * The URL for the Card link.
   */
  linkTo?: string;
}

// Generic customized version of the CK Card used for cards on the Home page
export const Card = ({
  children,
  headingLevel = 'h3',
  headingStyles = {},
  headingText,
  icon,
  linkText,
  linkTo,
  ...elemProps
}: CardProps) => (
  <CanvasCard
    as={Flex}
    css={{
      // For some reason, we need to specify flexDirection as a css override.
      // Using the flexDirection prop of Flex doesn't work in this case.
      flexDirection: 'column',
      textAlign: 'left',
    }}
    padding="m"
    {...elemProps}
  >
    <CanvasCard.Heading
      as={headingLevel}
      css={{
        ...type.levels.heading.small,
        alignItems: 'center',
        display: 'flex',
        marginBottom: 0,
        ...headingStyles,
        // TODO: Force accent and fill layers to be white; is there a better way to do this?
        // Maybe we should pick bgColors that allow for proper contrast.
        '.wd-icon-accent': {
          fill: `${colors.frenchVanilla100} !important`,
        },
        '.wd-icon-fill': {
          fill: `${colors.frenchVanilla100} !important`,
        },
      }}
    >
      {/* Using Flex rather than Box to shrink-wrap the enclosed icon */}
      <Flex marginInlineEnd="s">{icon}</Flex>
      {headingText}
    </CanvasCard.Heading>
    <CanvasCard.Body
      // Using as={Flex} doesn't work as expected here. Use css instead.
      css={{
        alignItems: 'flex-start',
        flex: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        p: {...type.levels.body.small},
      }}
      display="flex"
      marginTop="s"
    >
      {children}
      {linkTo && linkText && <CardLink to={linkTo}>{linkText}</CardLink>}
    </CanvasCard.Body>
  </CanvasCard>
);

export const BorderlessCard = styled(Card)({
  border: 'none',
});

export const CTACard = styled(CanvasCard)({
  border: 'none',
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'left',
  h2: {
    ...type.levels.heading.large,
    marginTop: space.s,
  },
  p: {
    marginBottom: space.s,
  },
  a: {
    marginTop: 'auto',
    alignSelf: 'flex-start',
  },
});
