import packageJson from '../../package.json';

/**
 * Returns the full canvas-kit version number installed on the site (e.g., v9.1.9).
 *
 * @param withPrefix Whether or not to return the 'v' prefix in the version number
 */
export const fullCKVersion = (withPrefix = true) => {
  const version = packageJson.dependencies['@workday/canvas-kit-react'];

  if (version.charAt(0) === '^') {
    return withPrefix ? version.replace('^', 'v') : version.substring(1);
  }

  return withPrefix ? 'v' + version : version;
};

/**
 * Returns the major canvas-kit version number installed on the site (e.g., v9).
 *
 * @param withPrefix Whether or not to return the 'v' prefix in the version number
 */
export const majorCKVersion = (withPrefix = true) => {
  const fullVersion = fullCKVersion(withPrefix);

  return fullVersion.split('.')[0];
};
