import colorsJson from '@workday/canvas-colors-web/dist/hq/CanvasHQ.json';
import { ExternalHyperlink } from '@workday/canvas-kit-react/button';
import { InformationHighlight } from '@workday/canvas-kit-docs';
import { ColorGrid } from "../../../../src/components/content/tokens/ColorGrid";
import { BrandTokens } from "../../../../src/components/content/tokens/TokenBrandGrid";
import { getInternalExternalURL } from "../../../../src/utils/url";
import * as React from 'react';
export default {
  colorsJson,
  ExternalHyperlink,
  InformationHighlight,
  ColorGrid,
  BrandTokens,
  getInternalExternalURL,
  React
};