import React from 'react';
import {colors, space, type} from '@workday/canvas-kit-react/tokens';
import {Flex, Box} from '@workday/canvas-kit-react/layout';

export interface SuggestionProps {
  children: React.ReactNode;
  /**
   * The text displayed underneath the Do/Caution/Don't heading on the card.
   * Keep it short and sweet.
   */
  guidance: string;
  /**
   * Determines what type of card will be rendered. `success` will render a Do
   * card (green), `alert` a Caution card (orange), and `error` a Don't card
   * (red).
   * @default success
   */
  status?: 'success' | 'alert' | 'error' | 'aim' | 'avoid';
  /**
   * The background color of the card. `grey` renders a `soap200` background.
   * @default grey
   */
  background?: 'grey' | 'white';
}

export const Suggestion = ({
  status = 'success',
  background = 'grey',
  guidance,
  children,
  ...elemProps
}: SuggestionProps) => {
  let color, textColor, message;

  switch (status) {
    case 'success':
    default:
      color = colors.greenApple400;
      textColor = colors.greenApple500;
      message = 'Do';
      break;
    case 'alert':
      color = colors.cantaloupe400;
      message = 'Caution';
      break;
    case 'error':
      color = colors.cinnamon500;
      textColor = colors.cinnamon600;
      message = "Don't";
      break;
    case 'aim':
      color = colors.greenApple400;
      textColor = colors.greenApple500;
      message = 'Aim for';
      break;
    case 'avoid':
      color = colors.cinnamon500;
      textColor = colors.cinnamon600;
      message = 'Avoid';
      break;
  }
  return (
    <Box {...elemProps}>
      <Flex
        background={background === 'white' ? colors.frenchVanilla100 : colors.soap200}
        border={`1px solid ${background === 'white' ? colors.soap500 : 'transparent'}`}
        borderBottom={`8px solid ${color}`}
        borderRadius="l"
        padding="m"
        marginBottom="xxs"
        minHeight="100px"
        alignItems="center"
        justifyContent="center"
        css={{
          '& > *': {flexGrow: 1},

          // Override margins applied to .gatsby-resp-image-wrapper in
          // ContentContainer (for images in the main content, we don't want
          // margins on images used within Suggestion)
          '.gatsby-resp-image-wrapper': {margin: 0},

          // A paragraph (with a margin) wraps the image as a consequence of
          // using the Suggestion component in MDX due to required whitespace
          // in MDX components. Remove this margin.
          '& > p': {margin: 0},
        }}
      >
        {children}
      </Flex>
      <p
        css={{
          ...type.levels.body.small,
          fontWeight: type.properties.fontWeights.medium,
          color: textColor,
          marginBottom: space.xxxs,
        }}
      >
        {message}
      </p>
      <p css={type.levels.subtext.medium}>{guidance}</p>
    </Box>
  );
};
