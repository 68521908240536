import React from 'react';
import {space} from '@workday/canvas-kit-react/tokens';
import {TemplateFrontmatter} from './types';
import {Keyline} from '../content/Keyline';
import {ContentPreamble} from '../content/ContentPreamble';
import {ContentBody} from '../content/ContentBody';
import {FAQNoticeCard} from '../content/NoticeCard';
import {PackageInfo} from '../content/PackageInfo';
import Tabs from '../nav/Tabs';
import {Breadcrumbs} from '../nav/Breadcrumbs';

import {useSiteMetadata} from '../../utils/useSiteMetadata';

export type Tab = string | {name: string; internal?: boolean; external?: boolean};

export interface TabsTemplateFrontmatter extends TemplateFrontmatter {
  tabs: Tab[];
  internaltabs: string[];
}

export interface TabsTemplateProps {
  children: JSX.Element | JSX.Element[];
  frontmatter: TabsTemplateFrontmatter;
}

export const TabsTemplate = ({children, frontmatter}: TabsTemplateProps) => {
  const config = useSiteMetadata();

  const tabs = frontmatter.tabs.reduce((runningTabs: string[], tab: Tab) => {
    if (typeof tab === 'string') {
      runningTabs.push(tab);
    } else if (
      tab.name &&
      ((tab.internal === undefined && tab.external === undefined) ||
        (tab.internal && config.internal) ||
        (tab.external && !config.internal))
    ) {
      runningTabs.push(tab.name);
    }

    return runningTabs;
  }, []);

  return (
    <>
      <ContentPreamble bodyHasTabs={true}>
        {frontmatter.keyline && (
          <Keyline css={{marginTop: 0, marginBottom: space.l}}>{frontmatter.keyline}</Keyline>
        )}
        {frontmatter.breadcrumbs && (
          <Breadcrumbs items={frontmatter.breadcrumbs} css={{marginBottom: 0}} />
        )}
        <ContentPreamble.Title>{frontmatter.title}</ContentPreamble.Title>
        {frontmatter.description && (
          <ContentPreamble.Introduction>{frontmatter.description}</ContentPreamble.Introduction>
        )}
        {frontmatter.component && (
          <PackageInfo
            inPreamble
            figma={frontmatter.component.figma}
            github={frontmatter.component.github}
            storybook={frontmatter.component.storybook}
            package={frontmatter.component.package}
            web={frontmatter.component.web}
          />
        )}
      </ContentPreamble>
      <ContentBody hasTabs={true}>
        {tabs && (
          <Tabs tabNames={tabs} anchorNavDepth={frontmatter.anchorNavDepth}>
            {children}
          </Tabs>
        )}
        <FAQNoticeCard bodyHasTabs={true} pageTitle={frontmatter ? frontmatter.title : undefined} />
      </ContentBody>
    </>
  );
};
