import {useStaticQuery, graphql} from 'gatsby';

export const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query InternalQuery {
      allSite {
        edges {
          node {
            siteMetadata {
              title
              description
              author
              internal
            }
          }
        }
      }
    }
  `);
  return data.allSite.edges[0].node.siteMetadata;
};

export default useSiteMetadata;
