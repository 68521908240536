import React from 'react';
import styled from '@emotion/styled';
import {GatsbyImage, getImage, IGatsbyImageData} from 'gatsby-plugin-image';
import {colors, space, borderRadius, type} from '@workday/canvas-kit-react/tokens';
import {Tabs, useTabsModel} from '@workday/canvas-kit-react/tabs';
import {Box, Flex} from '@workday/canvas-kit-react/layout';
import {StatusIndicator, StatusIndicatorProps} from '@workday/canvas-kit-react/status-indicator';
import {SystemIcon, SystemIconProps} from '@workday/canvas-kit-react/icon';
import {infoIcon} from '@workday/canvas-system-icons-web';
import {mqGreaterThanOrEqual, mqLessThan} from '../../utils/breakpoints';
import {Image} from '../../utils/types';

type Note = {
  title: string;
  subtext?: string;
};
export interface TabsData {
  [key: string]: {
    title: string;
    description?: string;
    notes: Note[];
    suggest?: string;
    status?: StatusIndicatorProps[];
  };
}

type MyTabItem = {
  id: string;
  value: string;
  text: React.ReactNode;
  contents: React.ReactElement;
};

interface TabbedContentProps {
  tabsData: TabsData;
  isTitleTabNames?: boolean;
  images: any[];
  notesIconSettings?: SystemIconProps;
}

const Panels = styled('div')({
  '[role="tabpanel"]': {
    background: colors.soap200,
    padding: space.m,
    borderRadius: borderRadius.l,

    '&:not([hidden])': {
      display: 'flex',
    },

    h5: {
      ...type.levels.body.large,
      fontWeight: type.properties.fontWeights.bold,
    },

    '.description': {
      ...type.levels.subtext.medium,
      ...type.variants.hint,
      marginBottom: space.xxs,
    },

    '.card-icon': {
      marginRight: '10px',
    },
  },
});

const TipList = styled('ul')({
  listStyleType: 'none',
  marginTop: space.s,
  li: {
    ...type.levels.body.small,
    marginBottom: space.s,
    whiteSpace: 'pre-line',
    ':last-of-type': {
      marginBottom: space.m,
    },
  },
});

const TabsList = styled(Tabs.List)({
  margin: 0,
  marginTop: space.s,
  borderBottom: 0,
});

const StatusIndicators = styled('div')({
  '& > span + span': {
    marginLeft: space.xs,
  },
});

const ResponsiveFlex = styled(Flex)({
  justifyContent: 'space-between',
  width: '100%',
  [mqLessThan('m')]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
});

const Subtext = styled('span')({
  display: 'block',
  ...type.levels.subtext.small,
});

export const TabbedContent = ({
  tabsData,
  images,
  notesIconSettings,
  isTitleTabNames = false,
}: TabbedContentProps) => {
  const items: MyTabItem[] = Object.keys(tabsData).map(level => {
    const {title, description, notes, suggest, status} = tabsData[level];
    const image = images.find((img: Image) => img.node.name === level)?.node;
    const key = title.toLowerCase().replace(' ', '-');

    return {
      id: key,
      text: title,
      value: isTitleTabNames ? title : level,
      contents: (
        <ResponsiveFlex>
          <Box marginInlineEnd={space.xxs}>
            <h5>{title}</h5>
            {description && <p className="description">{description}</p>}
            {status && (
              <StatusIndicators>
                {status.map(item => (
                  <StatusIndicator key={item.label} {...item} />
                ))}
              </StatusIndicators>
            )}
            <TipList>
              {notes.map((note, index) => (
                <li key={`${key} note ${index}`}>
                  <Flex>
                    {notesIconSettings && (
                      <SystemIcon className="card-icon" size={20} {...notesIconSettings} />
                    )}
                    <p>
                      {note.title}
                      {note.subtext && <Subtext>{note.subtext}</Subtext>}
                    </p>
                  </Flex>
                </li>
              ))}
            </TipList>
            {suggest && (
              <Flex>
                <SystemIcon className="card-icon" icon={infoIcon} size={20} />
                <p css={type.levels.subtext.large}>{suggest}</p>
              </Flex>
            )}
          </Box>
          {image && (
            <Flex
              flexShrink={0}
              flexBasis="50%"
              alignSelf="center"
              justifyContent="center"
              css={{[mqGreaterThanOrEqual('m')]: {padding: `0 ${space.xs}`}}}
            >
              <GatsbyImage
                image={getImage(image) as IGatsbyImageData}
                alt={`Example for space.${level}`}
              />
            </Flex>
          )}
        </ResponsiveFlex>
      ),
    };
  });

  const model = useTabsModel({items});

  return (
    <Tabs model={model}>
      <TabsList overflowButton={<Tabs.OverflowButton>More</Tabs.OverflowButton>}>
        {(item: MyTabItem) => (
          <Tabs.Item key={`space.${item.id} tab`} data-id={item.id}>
            {item.value}
          </Tabs.Item>
        )}
      </TabsList>
      <Tabs.Menu.Popper>
        <Tabs.Menu.Card minWidth={92} maxWidth={300} maxHeight={200}>
          <Tabs.Menu.List>
            {(item: MyTabItem) => (
              <Tabs.Menu.Item key={`space.${item.id} tab`} data-id={item.id}>
                {item.value}
              </Tabs.Menu.Item>
            )}
          </Tabs.Menu.List>
        </Tabs.Menu.Card>
      </Tabs.Menu.Popper>
      <Panels>
        <Tabs.Panels>
          {(item: MyTabItem) => (
            <Tabs.Panel key={`space.${item.id} panel`} data-id={item.id}>
              {item.contents}
            </Tabs.Panel>
          )}
        </Tabs.Panels>
      </Panels>
    </Tabs>
  );
};
