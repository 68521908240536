import React from 'react';
import {endsWith} from '../../utils/strings';
import {Box} from '@workday/canvas-kit-react/layout';
import {colors, space, type} from '@workday/canvas-kit-react/tokens';
import {H2, P} from '../StyledElements';

export interface GifProps {
  /**
   * `p` element that wraps the gif and jpg images
   */
  children: React.ReactElement;
}

export const IncorrectGif = elemProps => (
  <Box
    border={`2px solid ${colors.cinnamon500}`}
    borderRadius="l"
    background="cinnamon100"
    padding="l"
    marginY="xl"
    {...elemProps}
  >
    <H2 css={{marginTop: 0}}>Incorrectly Formatted GIF</H2>
    <P>
      You either formatted your GIF incorrectly, or your fallback image is missing. It should look
      like this (adjusting number of &ldquo;../&rdquo; for path as necessary):
    </P>
    <Box
      as="pre"
      marginTop="m"
      padding="m"
      color="frenchVanilla100"
      backgroundColor="licorice500"
      borderRadius="l"
      css={{
        fontFamily: type.properties.fontFamilies.monospace,
      }}
    >
      {`<Gif>

![Alt text description](../../resources/filename.gif)
![Alt text description](../../resources/filename.png)

</Gif>`}
    </Box>
  </Box>
);

export const Gif = ({children, ...elemProps}: GifProps) => {
  const images = children.props.children.filter((child: React.ReactNode) => child !== '\n');
  const gif = images.find((child: React.ReactElement) => endsWith(child.props.src, '.gif'));
  const fallback = images.find(
    (child: React.ReactElement) => child.props.className === 'gatsby-resp-image-wrapper'
  );
  const fallbackSrc = fallback?.props.children[1].props.href;

  if (!gif || !fallbackSrc) {
    return <IncorrectGif />;
  }

  return (
    <p css={{textAlign: 'center', marginTop: space.s}}>
      <picture {...elemProps}>
        <source srcSet={fallbackSrc} media="(prefers-reduced-motion: reduce)"></source>
        <img srcSet={gif.props.src} alt={gif.props.alt} css={{maxWidth: '100%'}} />
      </picture>
    </p>
  );
};
