// Compiler directive is required since we use Emotion 11 and
// gatsby-plugin-emotion (which uses @emotion/babel-preset-css-prop). For
// more info, see: https://emotion.sh/docs/emotion-11#css-prop-types
/// <reference types="@emotion/react/types/css-prop" />

import {type} from '@workday/canvas-kit-react/tokens';
import {CSSObject} from '@emotion/styled';

const allElements = `html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video`;

const reset: CSSObject = {
  [allElements]: {
    margin: 0,
    padding: 0,
    border: 0,
    fontSize: '100%',
    font: 'inherit',
    verticalAlign: 'baseline',
  },
  body: type.levels.body.small,
};

export default reset;
