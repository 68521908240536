import React from 'react';
import styled from '@emotion/styled';
import CanvasColors from '@workday/canvas-colors-web';
import ColorSetRow, {ColorSetWeight} from './ColorSetRow';
import {colors, space, type} from '@workday/canvas-kit-react/tokens';
import {pickForegroundColor} from '@workday/canvas-kit-react/common';

export interface ColorSetProps {
  set: {
    swatch_name: string;
    active: '1' | '0';
    rank: number;
    id: number;
    slug: string;
    weight_100: string;
    weight_200: string;
    weight_300: string;
    weight_400: string;
    weight_500: string;
    weight_600: string;
  };
}

const dashToCamelCase = (slug: string) => slug.replace(/-([a-z])/gi, g => g[1].toUpperCase());

const getColors = (slug: string) => {
  const camelCaseSlug = dashToCamelCase(slug);

  return {
    100: CanvasColors[`${camelCaseSlug}100`],
    200: CanvasColors[`${camelCaseSlug}200`],
    300: CanvasColors[`${camelCaseSlug}300`],
    400: CanvasColors[`${camelCaseSlug}400`],
    500: CanvasColors[`${camelCaseSlug}500`],
    600: CanvasColors[`${camelCaseSlug}600`],
  };
};

const getStyle = (hex: string) => {
  const style: React.CSSProperties = {
    background: hex,
    color: pickForegroundColor(hex, colors.blackPepper300, colors.frenchVanilla100),
  };

  return style;
};

const Container = styled('div')({
  width: '30%',
  margin: `${space.m} 0`,

  '@media screen and (max-width: 1024px)': {
    width: '45%',

    '&:last-child': {
      marginLeft: 0,
    },
  },
  ul: {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
});

const Primary = styled('div')({
  height: '120px',
  padding: space.s,
  borderRadius: '10px 10px 0 0',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-around',

  h3: {
    ...type.levels.body.large,
    margin: 0,
    marginBottom: space.xxs,
    color: 'inherit',
    fontWeight: type.properties.fontWeights.bold,
  },

  '.weight': {
    alignSelf: 'bottom',
  },
});

export const ColorSet = ({set}: ColorSetProps) => {
  if (!set) {
    return null;
  }

  const {slug, swatch_name: setName} = set;

  const colors = getColors(slug);
  const primaryStyle = getStyle(colors[500]);

  return (
    <Container role="group" aria-label={`${setName} hue`}>
      <Primary style={primaryStyle}>
        <h3>{setName}</h3>
        <span className="weight">500</span>
      </Primary>
      <ul role="list" aria-label={`${setName} shades`}>
        {Object.keys(colors).map((weight: ColorSetWeight, index) => {
          const hex = colors[weight];

          return (
            <ColorSetRow
              hex={hex}
              swatchKey={dashToCamelCase(slug)}
              weight={weight}
              style={getStyle(hex)}
              key={index}
            />
          );
        })}
      </ul>
    </Container>
  );
};

export default ColorSet;
