// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---content-components-buttons-action-bar-mdx": () => import("./../../../content/components/buttons/action-bar.mdx" /* webpackChunkName: "component---content-components-buttons-action-bar-mdx" */),
  "component---content-components-buttons-button-mdx": () => import("./../../../content/components/buttons/button.mdx" /* webpackChunkName: "component---content-components-buttons-button-mdx" */),
  "component---content-components-buttons-segmented-control-mdx": () => import("./../../../content/components/buttons/segmented-control.mdx" /* webpackChunkName: "component---content-components-buttons-segmented-control-mdx" */),
  "component---content-components-containers-card-mdx": () => import("./../../../content/components/containers/card.mdx" /* webpackChunkName: "component---content-components-containers-card-mdx" */),
  "component---content-components-containers-expandable-container-mdx": () => import("./../../../content/components/containers/expandable-container.mdx" /* webpackChunkName: "component---content-components-containers-expandable-container-mdx" */),
  "component---content-components-containers-side-panel-mdx": () => import("./../../../content/components/containers/side-panel.mdx" /* webpackChunkName: "component---content-components-containers-side-panel-mdx" */),
  "component---content-components-containers-table-mdx": () => import("./../../../content/components/containers/table.mdx" /* webpackChunkName: "component---content-components-containers-table-mdx" */),
  "component---content-components-containers-tabs-mdx": () => import("./../../../content/components/containers/tabs.mdx" /* webpackChunkName: "component---content-components-containers-tabs-mdx" */),
  "component---content-components-indicators-banner-mdx": () => import("./../../../content/components/indicators/banner.mdx" /* webpackChunkName: "component---content-components-indicators-banner-mdx" */),
  "component---content-components-indicators-loading-dots-mdx": () => import("./../../../content/components/indicators/loading-dots.mdx" /* webpackChunkName: "component---content-components-indicators-loading-dots-mdx" */),
  "component---content-components-indicators-pill-mdx": () => import("./../../../content/components/indicators/pill.mdx" /* webpackChunkName: "component---content-components-indicators-pill-mdx" */),
  "component---content-components-indicators-skeleton-mdx": () => import("./../../../content/components/indicators/skeleton.mdx" /* webpackChunkName: "component---content-components-indicators-skeleton-mdx" */),
  "component---content-components-indicators-status-indicator-mdx": () => import("./../../../content/components/indicators/status-indicator.mdx" /* webpackChunkName: "component---content-components-indicators-status-indicator-mdx" */),
  "component---content-components-inputs-checkbox-mdx": () => import("./../../../content/components/inputs/checkbox.mdx" /* webpackChunkName: "component---content-components-inputs-checkbox-mdx" */),
  "component---content-components-inputs-color-input-mdx": () => import("./../../../content/components/inputs/color-input.mdx" /* webpackChunkName: "component---content-components-inputs-color-input-mdx" */),
  "component---content-components-inputs-form-field-mdx": () => import("./../../../content/components/inputs/form-field.mdx" /* webpackChunkName: "component---content-components-inputs-form-field-mdx" */),
  "component---content-components-inputs-radio-mdx": () => import("./../../../content/components/inputs/radio.mdx" /* webpackChunkName: "component---content-components-inputs-radio-mdx" */),
  "component---content-components-inputs-select-mdx": () => import("./../../../content/components/inputs/select.mdx" /* webpackChunkName: "component---content-components-inputs-select-mdx" */),
  "component---content-components-inputs-switch-mdx": () => import("./../../../content/components/inputs/switch.mdx" /* webpackChunkName: "component---content-components-inputs-switch-mdx" */),
  "component---content-components-inputs-text-area-mdx": () => import("./../../../content/components/inputs/text-area.mdx" /* webpackChunkName: "component---content-components-inputs-text-area-mdx" */),
  "component---content-components-inputs-text-input-mdx": () => import("./../../../content/components/inputs/text-input.mdx" /* webpackChunkName: "component---content-components-inputs-text-input-mdx" */),
  "component---content-components-layout-box-mdx": () => import("./../../../content/components/layout/box.mdx" /* webpackChunkName: "component---content-components-layout-box-mdx" */),
  "component---content-components-layout-flex-mdx": () => import("./../../../content/components/layout/flex.mdx" /* webpackChunkName: "component---content-components-layout-flex-mdx" */),
  "component---content-components-layout-grid-mdx": () => import("./../../../content/components/layout/grid.mdx" /* webpackChunkName: "component---content-components-layout-grid-mdx" */),
  "component---content-components-navigation-breadcrumbs-mdx": () => import("./../../../content/components/navigation/breadcrumbs.mdx" /* webpackChunkName: "component---content-components-navigation-breadcrumbs-mdx" */),
  "component---content-components-navigation-pagination-mdx": () => import("./../../../content/components/navigation/pagination.mdx" /* webpackChunkName: "component---content-components-navigation-pagination-mdx" */),
  "component---content-components-overview-mdx": () => import("./../../../content/components/overview.mdx" /* webpackChunkName: "component---content-components-overview-mdx" */),
  "component---content-components-popups-dialog-mdx": () => import("./../../../content/components/popups/dialog.mdx" /* webpackChunkName: "component---content-components-popups-dialog-mdx" */),
  "component---content-components-popups-menu-mdx": () => import("./../../../content/components/popups/menu.mdx" /* webpackChunkName: "component---content-components-popups-menu-mdx" */),
  "component---content-components-popups-modal-mdx": () => import("./../../../content/components/popups/modal.mdx" /* webpackChunkName: "component---content-components-popups-modal-mdx" */),
  "component---content-components-popups-popup-mdx": () => import("./../../../content/components/popups/popup.mdx" /* webpackChunkName: "component---content-components-popups-popup-mdx" */),
  "component---content-components-popups-toast-mdx": () => import("./../../../content/components/popups/toast.mdx" /* webpackChunkName: "component---content-components-popups-toast-mdx" */),
  "component---content-components-popups-tooltip-mdx": () => import("./../../../content/components/popups/tooltip.mdx" /* webpackChunkName: "component---content-components-popups-tooltip-mdx" */),
  "component---content-components-text-body-text-mdx": () => import("./../../../content/components/text/body-text.mdx" /* webpackChunkName: "component---content-components-text-body-text-mdx" */),
  "component---content-components-text-heading-mdx": () => import("./../../../content/components/text/heading.mdx" /* webpackChunkName: "component---content-components-text-heading-mdx" */),
  "component---content-components-text-label-text-mdx": () => import("./../../../content/components/text/label-text.mdx" /* webpackChunkName: "component---content-components-text-label-text-mdx" */),
  "component---content-components-text-subtext-mdx": () => import("./../../../content/components/text/subtext.mdx" /* webpackChunkName: "component---content-components-text-subtext-mdx" */),
  "component---content-components-text-text-mdx": () => import("./../../../content/components/text/text.mdx" /* webpackChunkName: "component---content-components-text-text-mdx" */),
  "component---content-components-text-title-mdx": () => import("./../../../content/components/text/title.mdx" /* webpackChunkName: "component---content-components-text-title-mdx" */),
  "component---content-get-started-for-designers-resources-memsource-mdx": () => import("./../../../content/get-started/for-designers/resources/memsource.mdx" /* webpackChunkName: "component---content-get-started-for-designers-resources-memsource-mdx" */),
  "component---content-get-started-for-designers-understanding-canvas-mdx": () => import("./../../../content/get-started/for-designers/understanding-canvas.mdx" /* webpackChunkName: "component---content-get-started-for-designers-understanding-canvas-mdx" */),
  "component---content-get-started-for-developers-contributing-to-canvas-mdx": () => import("./../../../content/get-started/for-developers/contributing-to-canvas.mdx" /* webpackChunkName: "component---content-get-started-for-developers-contributing-to-canvas-mdx" */),
  "component---content-get-started-for-developers-resources-api-pattern-guidelines-mdx": () => import("./../../../content/get-started/for-developers/resources/api-pattern-guidelines.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-api-pattern-guidelines-mdx" */),
  "component---content-get-started-for-developers-resources-canvas-kit-labs-mdx": () => import("./../../../content/get-started/for-developers/resources/canvas-kit-labs.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-canvas-kit-labs-mdx" */),
  "component---content-get-started-for-developers-resources-canvas-kit-preview-mdx": () => import("./../../../content/get-started/for-developers/resources/canvas-kit-preview.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-canvas-kit-preview-mdx" */),
  "component---content-get-started-for-developers-resources-collection-api-mdx": () => import("./../../../content/get-started/for-developers/resources/collection-api.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-collection-api-mdx" */),
  "component---content-get-started-for-developers-resources-combobox-mdx": () => import("./../../../content/get-started/for-developers/resources/combobox.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-combobox-mdx" */),
  "component---content-get-started-for-developers-resources-compound-components-mdx": () => import("./../../../content/get-started/for-developers/resources/compound-components.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-compound-components-mdx" */),
  "component---content-get-started-for-developers-resources-creating-compound-components-mdx": () => import("./../../../content/get-started/for-developers/resources/creating-compound-components.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-creating-compound-components-mdx" */),
  "component---content-get-started-for-developers-resources-maintaining-mdx": () => import("./../../../content/get-started/for-developers/resources/maintaining.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-maintaining-mdx" */),
  "component---content-get-started-for-developers-resources-mdx": () => import("./../../../content/get-started/for-developers/resources.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-mdx" */),
  "component---content-get-started-for-developers-resources-responsive-styling-mdx": () => import("./../../../content/get-started/for-developers/resources/responsive-styling.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-responsive-styling-mdx" */),
  "component---content-get-started-for-developers-resources-style-props-mdx": () => import("./../../../content/get-started/for-developers/resources/style-props.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-style-props-mdx" */),
  "component---content-get-started-for-developers-resources-testing-mdx": () => import("./../../../content/get-started/for-developers/resources/testing.mdx" /* webpackChunkName: "component---content-get-started-for-developers-resources-testing-mdx" */),
  "component---content-get-started-for-developers-using-canvas-mdx": () => import("./../../../content/get-started/for-developers/using-canvas.mdx" /* webpackChunkName: "component---content-get-started-for-developers-using-canvas-mdx" */),
  "component---content-get-started-introduction-tsx": () => import("./../../../content/get-started/introduction.tsx" /* webpackChunkName: "component---content-get-started-introduction-tsx" */),
  "component---content-guidelines-accessibility-accessibility-tree-mdx": () => import("./../../../content/guidelines/accessibility/accessibility-tree.mdx" /* webpackChunkName: "component---content-guidelines-accessibility-accessibility-tree-mdx" */),
  "component---content-guidelines-accessibility-accessible-color-md": () => import("./../../../content/guidelines/accessibility/accessible-color.md" /* webpackChunkName: "component---content-guidelines-accessibility-accessible-color-md" */),
  "component---content-guidelines-accessibility-accessible-forms-mdx": () => import("./../../../content/guidelines/accessibility/accessible-forms.mdx" /* webpackChunkName: "component---content-guidelines-accessibility-accessible-forms-mdx" */),
  "component---content-guidelines-accessibility-alt-text-md": () => import("./../../../content/guidelines/accessibility/alt-text.md" /* webpackChunkName: "component---content-guidelines-accessibility-alt-text-md" */),
  "component---content-guidelines-accessibility-keyboard-focus-management-md": () => import("./../../../content/guidelines/accessibility/keyboard-focus-management.md" /* webpackChunkName: "component---content-guidelines-accessibility-keyboard-focus-management-md" */),
  "component---content-guidelines-accessibility-meaningful-page-structure-mdx": () => import("./../../../content/guidelines/accessibility/meaningful-page-structure.mdx" /* webpackChunkName: "component---content-guidelines-accessibility-meaningful-page-structure-mdx" */),
  "component---content-guidelines-accessibility-overview-mdx": () => import("./../../../content/guidelines/accessibility/overview.mdx" /* webpackChunkName: "component---content-guidelines-accessibility-overview-mdx" */),
  "component---content-guidelines-content-language-and-style-acronyms-md": () => import("./../../../content/guidelines/content/language-and-style/acronyms.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-acronyms-md" */),
  "component---content-guidelines-content-language-and-style-capitalization-md": () => import("./../../../content/guidelines/content/language-and-style/capitalization.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-capitalization-md" */),
  "component---content-guidelines-content-language-and-style-command-names-keys-md": () => import("./../../../content/guidelines/content/language-and-style/command-names-keys.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-command-names-keys-md" */),
  "component---content-guidelines-content-language-and-style-file-names-types-extensions-md": () => import("./../../../content/guidelines/content/language-and-style/file-names-types-extensions.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-file-names-types-extensions-md" */),
  "component---content-guidelines-content-language-and-style-folders-drives-paths-md": () => import("./../../../content/guidelines/content/language-and-style/folders-drives-paths.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-folders-drives-paths-md" */),
  "component---content-guidelines-content-language-and-style-jargon-md": () => import("./../../../content/guidelines/content/language-and-style/jargon.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-jargon-md" */),
  "component---content-guidelines-content-language-and-style-lists-md": () => import("./../../../content/guidelines/content/language-and-style/lists.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-lists-md" */),
  "component---content-guidelines-content-language-and-style-notes-md": () => import("./../../../content/guidelines/content/language-and-style/notes.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-notes-md" */),
  "component---content-guidelines-content-language-and-style-person-pronouns-md": () => import("./../../../content/guidelines/content/language-and-style/person-pronouns.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-person-pronouns-md" */),
  "component---content-guidelines-content-language-and-style-personal-titles-md": () => import("./../../../content/guidelines/content/language-and-style/personal-titles.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-personal-titles-md" */),
  "component---content-guidelines-content-language-and-style-product-references-md": () => import("./../../../content/guidelines/content/language-and-style/product-references.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-product-references-md" */),
  "component---content-guidelines-content-language-and-style-punctuation-md": () => import("./../../../content/guidelines/content/language-and-style/punctuation.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-punctuation-md" */),
  "component---content-guidelines-content-language-and-style-state-city-names-md": () => import("./../../../content/guidelines/content/language-and-style/state-city-names.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-state-city-names-md" */),
  "component---content-guidelines-content-language-and-style-urls-md": () => import("./../../../content/guidelines/content/language-and-style/urls.md" /* webpackChunkName: "component---content-guidelines-content-language-and-style-urls-md" */),
  "component---content-guidelines-content-numbers-ages-md": () => import("./../../../content/guidelines/content/numbers/ages.md" /* webpackChunkName: "component---content-guidelines-content-numbers-ages-md" */),
  "component---content-guidelines-content-numbers-byte-sizes-md": () => import("./../../../content/guidelines/content/numbers/byte-sizes.md" /* webpackChunkName: "component---content-guidelines-content-numbers-byte-sizes-md" */),
  "component---content-guidelines-content-numbers-dates-times-md": () => import("./../../../content/guidelines/content/numbers/dates-times.md" /* webpackChunkName: "component---content-guidelines-content-numbers-dates-times-md" */),
  "component---content-guidelines-content-numbers-fiscal-years-md": () => import("./../../../content/guidelines/content/numbers/fiscal-years.md" /* webpackChunkName: "component---content-guidelines-content-numbers-fiscal-years-md" */),
  "component---content-guidelines-content-numbers-fractions-md": () => import("./../../../content/guidelines/content/numbers/fractions.md" /* webpackChunkName: "component---content-guidelines-content-numbers-fractions-md" */),
  "component---content-guidelines-content-numbers-numerals-md": () => import("./../../../content/guidelines/content/numbers/numerals.md" /* webpackChunkName: "component---content-guidelines-content-numbers-numerals-md" */),
  "component---content-guidelines-content-numbers-numeric-abbreviations-md": () => import("./../../../content/guidelines/content/numbers/numeric-abbreviations.md" /* webpackChunkName: "component---content-guidelines-content-numbers-numeric-abbreviations-md" */),
  "component---content-guidelines-content-numbers-percentages-md": () => import("./../../../content/guidelines/content/numbers/percentages.md" /* webpackChunkName: "component---content-guidelines-content-numbers-percentages-md" */),
  "component---content-guidelines-content-numbers-phone-numbers-md": () => import("./../../../content/guidelines/content/numbers/phone-numbers.md" /* webpackChunkName: "component---content-guidelines-content-numbers-phone-numbers-md" */),
  "component---content-guidelines-content-overview-mdx": () => import("./../../../content/guidelines/content/overview.mdx" /* webpackChunkName: "component---content-guidelines-content-overview-mdx" */),
  "component---content-guidelines-content-ui-text-buttons-and-calls-to-action-md": () => import("./../../../content/guidelines/content/ui-text/buttons-and-calls-to-action.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-buttons-and-calls-to-action-md" */),
  "component---content-guidelines-content-ui-text-check-boxes-md": () => import("./../../../content/guidelines/content/ui-text/check-boxes.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-check-boxes-md" */),
  "component---content-guidelines-content-ui-text-dialogs-md": () => import("./../../../content/guidelines/content/ui-text/dialogs.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-dialogs-md" */),
  "component---content-guidelines-content-ui-text-drop-down-menus-md": () => import("./../../../content/guidelines/content/ui-text/drop-down-menus.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-drop-down-menus-md" */),
  "component---content-guidelines-content-ui-text-empty-states-md": () => import("./../../../content/guidelines/content/ui-text/empty-states.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-empty-states-md" */),
  "component---content-guidelines-content-ui-text-error-and-alert-messages-md": () => import("./../../../content/guidelines/content/ui-text/error-and-alert-messages.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-error-and-alert-messages-md" */),
  "component---content-guidelines-content-ui-text-field-labels-md": () => import("./../../../content/guidelines/content/ui-text/field-labels.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-field-labels-md" */),
  "component---content-guidelines-content-ui-text-grids-md": () => import("./../../../content/guidelines/content/ui-text/grids.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-grids-md" */),
  "component---content-guidelines-content-ui-text-headings-md": () => import("./../../../content/guidelines/content/ui-text/headings.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-headings-md" */),
  "component---content-guidelines-content-ui-text-instructional-text-md": () => import("./../../../content/guidelines/content/ui-text/instructional-text.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-instructional-text-md" */),
  "component---content-guidelines-content-ui-text-link-text-md": () => import("./../../../content/guidelines/content/ui-text/link-text.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-link-text-md" */),
  "component---content-guidelines-content-ui-text-placeholder-text-md": () => import("./../../../content/guidelines/content/ui-text/placeholder-text.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-placeholder-text-md" */),
  "component---content-guidelines-content-ui-text-radio-buttons-md": () => import("./../../../content/guidelines/content/ui-text/radio-buttons.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-radio-buttons-md" */),
  "component---content-guidelines-content-ui-text-related-actions-menu-md": () => import("./../../../content/guidelines/content/ui-text/related-actions-menu.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-related-actions-menu-md" */),
  "component---content-guidelines-content-ui-text-special-characters-md": () => import("./../../../content/guidelines/content/ui-text/special-characters.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-special-characters-md" */),
  "component---content-guidelines-content-ui-text-tabs-md": () => import("./../../../content/guidelines/content/ui-text/tabs.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-tabs-md" */),
  "component---content-guidelines-content-ui-text-tooltips-and-toasts-md": () => import("./../../../content/guidelines/content/ui-text/tooltips-and-toasts.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-tooltips-and-toasts-md" */),
  "component---content-guidelines-content-ui-text-wizard-instructions-md": () => import("./../../../content/guidelines/content/ui-text/wizard-instructions.md" /* webpackChunkName: "component---content-guidelines-content-ui-text-wizard-instructions-md" */),
  "component---content-guidelines-content-voice-and-tone-simple-and-clever-md": () => import("./../../../content/guidelines/content/voice-and-tone/simple-and-clever.md" /* webpackChunkName: "component---content-guidelines-content-voice-and-tone-simple-and-clever-md" */),
  "component---content-guidelines-content-voice-and-tone-voice-and-tone-md": () => import("./../../../content/guidelines/content/voice-and-tone/voice-and-tone.md" /* webpackChunkName: "component---content-guidelines-content-voice-and-tone-voice-and-tone-md" */),
  "component---content-guidelines-globalization-overview-md": () => import("./../../../content/guidelines/globalization/overview.md" /* webpackChunkName: "component---content-guidelines-globalization-overview-md" */),
  "component---content-guidelines-globalization-rtl-and-bidi-mdx": () => import("./../../../content/guidelines/globalization/rtl-and-bidi.mdx" /* webpackChunkName: "component---content-guidelines-globalization-rtl-and-bidi-mdx" */),
  "component---content-help-announcements-canvas-v-10-announcements-mdx": () => import("./../../../content/help/announcements/canvas-v10-announcements.mdx" /* webpackChunkName: "component---content-help-announcements-canvas-v-10-announcements-mdx" */),
  "component---content-help-announcements-canvas-v-7-announcements-mdx": () => import("./../../../content/help/announcements/canvas-v7-announcements.mdx" /* webpackChunkName: "component---content-help-announcements-canvas-v-7-announcements-mdx" */),
  "component---content-help-announcements-canvas-v-8-announcements-mdx": () => import("./../../../content/help/announcements/canvas-v8-announcements.mdx" /* webpackChunkName: "component---content-help-announcements-canvas-v-8-announcements-mdx" */),
  "component---content-help-announcements-canvas-v-9-announcements-mdx": () => import("./../../../content/help/announcements/canvas-v9-announcements.mdx" /* webpackChunkName: "component---content-help-announcements-canvas-v-9-announcements-mdx" */),
  "component---content-help-announcements-mdx": () => import("./../../../content/help/announcements.mdx" /* webpackChunkName: "component---content-help-announcements-mdx" */),
  "component---content-help-faq-mdx": () => import("./../../../content/help/faq.mdx" /* webpackChunkName: "component---content-help-faq-mdx" */),
  "component---content-help-glossary-mdx": () => import("./../../../content/help/glossary.mdx" /* webpackChunkName: "component---content-help-glossary-mdx" */),
  "component---content-help-upgrade-guides-canvas-kit-v-4-upgrade-guide-mdx": () => import("./../../../content/help/upgrade-guides/canvas-kit-v4-upgrade-guide.mdx" /* webpackChunkName: "component---content-help-upgrade-guides-canvas-kit-v-4-upgrade-guide-mdx" */),
  "component---content-help-upgrade-guides-canvas-kit-v-5-upgrade-guide-mdx": () => import("./../../../content/help/upgrade-guides/canvas-kit-v5-upgrade-guide.mdx" /* webpackChunkName: "component---content-help-upgrade-guides-canvas-kit-v-5-upgrade-guide-mdx" */),
  "component---content-help-upgrade-guides-canvas-kit-v-6-upgrade-guide-mdx": () => import("./../../../content/help/upgrade-guides/canvas-kit-v6-upgrade-guide.mdx" /* webpackChunkName: "component---content-help-upgrade-guides-canvas-kit-v-6-upgrade-guide-mdx" */),
  "component---content-help-upgrade-guides-canvas-kit-v-7-upgrade-guide-mdx": () => import("./../../../content/help/upgrade-guides/canvas-kit-v7-upgrade-guide.mdx" /* webpackChunkName: "component---content-help-upgrade-guides-canvas-kit-v-7-upgrade-guide-mdx" */),
  "component---content-help-upgrade-guides-canvas-kit-v-8-upgrade-guide-mdx": () => import("./../../../content/help/upgrade-guides/canvas-kit-v8-upgrade-guide.mdx" /* webpackChunkName: "component---content-help-upgrade-guides-canvas-kit-v-8-upgrade-guide-mdx" */),
  "component---content-help-upgrade-guides-canvas-v-10-upgrade-guide-mdx": () => import("./../../../content/help/upgrade-guides/canvas-v10-upgrade-guide.mdx" /* webpackChunkName: "component---content-help-upgrade-guides-canvas-v-10-upgrade-guide-mdx" */),
  "component---content-help-upgrade-guides-canvas-v-11-upgrade-guide-mdx": () => import("./../../../content/help/upgrade-guides/canvas-v11-upgrade-guide.mdx" /* webpackChunkName: "component---content-help-upgrade-guides-canvas-v-11-upgrade-guide-mdx" */),
  "component---content-help-upgrade-guides-canvas-v-9-upgrade-guide-mdx": () => import("./../../../content/help/upgrade-guides/canvas-v9-upgrade-guide.mdx" /* webpackChunkName: "component---content-help-upgrade-guides-canvas-v-9-upgrade-guide-mdx" */),
  "component---content-help-upgrade-guides-mdx": () => import("./../../../content/help/upgrade-guides.mdx" /* webpackChunkName: "component---content-help-upgrade-guides-mdx" */),
  "component---content-styles-assets-accent-icons-mdx": () => import("./../../../content/styles/assets/accent-icons.mdx" /* webpackChunkName: "component---content-styles-assets-accent-icons-mdx" */),
  "component---content-styles-assets-applet-icons-mdx": () => import("./../../../content/styles/assets/applet-icons.mdx" /* webpackChunkName: "component---content-styles-assets-applet-icons-mdx" */),
  "component---content-styles-assets-overview-mdx": () => import("./../../../content/styles/assets/overview.mdx" /* webpackChunkName: "component---content-styles-assets-overview-mdx" */),
  "component---content-styles-assets-system-icons-mdx": () => import("./../../../content/styles/assets/system-icons.mdx" /* webpackChunkName: "component---content-styles-assets-system-icons-mdx" */),
  "component---content-styles-assets-the-dub-mdx": () => import("./../../../content/styles/assets/the-dub.mdx" /* webpackChunkName: "component---content-styles-assets-the-dub-mdx" */),
  "component---content-styles-tokens-color-mdx": () => import("./../../../content/styles/tokens/color.mdx" /* webpackChunkName: "component---content-styles-tokens-color-mdx" */),
  "component---content-styles-tokens-depth-mdx": () => import("./../../../content/styles/tokens/depth.mdx" /* webpackChunkName: "component---content-styles-tokens-depth-mdx" */),
  "component---content-styles-tokens-motion-mdx": () => import("./../../../content/styles/tokens/motion.mdx" /* webpackChunkName: "component---content-styles-tokens-motion-mdx" */),
  "component---content-styles-tokens-opacity-mdx": () => import("./../../../content/styles/tokens/opacity.mdx" /* webpackChunkName: "component---content-styles-tokens-opacity-mdx" */),
  "component---content-styles-tokens-overview-mdx": () => import("./../../../content/styles/tokens/overview.mdx" /* webpackChunkName: "component---content-styles-tokens-overview-mdx" */),
  "component---content-styles-tokens-shape-mdx": () => import("./../../../content/styles/tokens/shape.mdx" /* webpackChunkName: "component---content-styles-tokens-shape-mdx" */),
  "component---content-styles-tokens-space-mdx": () => import("./../../../content/styles/tokens/space.mdx" /* webpackChunkName: "component---content-styles-tokens-space-mdx" */),
  "component---content-styles-tokens-type-mdx": () => import("./../../../content/styles/tokens/type.mdx" /* webpackChunkName: "component---content-styles-tokens-type-mdx" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */)
}

