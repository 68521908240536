/**
 * Returns a wrapper around the provided `callback` function that limits
 * `callback` so it isn't run more than once every `limit` ms.
 */
export const throttle = (callback: () => void, limit: number) => {
  let tick = false;
  return () => {
    if (!tick) {
      setTimeout(() => {
        callback();
        tick = false;
      }, limit);
    }
    tick = true;
  };
};
