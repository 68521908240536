import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {TabbedContent, TabsData} from '../TabbedContent';

const tabsData: TabsData = {
  small: {
    title: 'Small',
    description: '320px - 767px',
    notes: [
      {title: 'Number of columns: 4 \r\n Margins: 16px \r\n Gutter: 16px'},
      {title: 'Used for mobile size screens'},
    ],
    suggest:
      'To accommodate a 3 panel layout on mobile you can add a separate 12 column grid with a margin of 16px and gutter of 16px',
  },
  medium: {
    title: 'Medium',
    description: '768px - 1023px',
    notes: [
      {title: 'Number of columns: 8 \r\n Margins: 40px \r\n Gutter: 24px'},
      {title: 'Used for tablet size screens'},
    ],
  },
  large: {
    title: 'Large',
    description: '1024px - 1439px',
    notes: [
      {title: 'Number of columns: 12 \r\n Margins: 40px \r\n Gutter: 32px'},
      {title: 'Used for desktop and small laptop size screens'},
    ],
  },
  extraLarge: {
    title: 'Extra Large',
    description: '1440px+',
    notes: [
      {title: 'Number of columns: 12 \r\n Margins: 80px \r\n Gutter: 40px'},
      {title: 'Used for large desktop size screens'},
    ],
    suggest:
      'For extra large screens we recommend that you apply a Max-Width of 1440px for the main page content.',
  },
};

export const LayoutResponsiveTabs = () => {
  const {
    allFile: {edges: images},
  } = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^layout-guide/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return <TabbedContent tabsData={tabsData} images={images} isTitleTabNames />;
};
