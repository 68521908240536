import React from 'react';
import {colors, space, type} from '@workday/canvas-kit-react/tokens';
import {Box} from '@workday/canvas-kit-react/layout';

import {
  ViewportSize,
  bentoRadius,
  headerHeight,
  headerPreambleContentGap,
  combineMediaQueries,
  contentHSpace,
  maxBespokeContentWidth,
  maxContentWidth,
  mqAt,
} from '../../utils/breakpoints';
import {H1} from '../content/Heading';

interface PreambleContainerProps {
  bodyHasTabs?: boolean;
  children?: React.ReactNode;
}

const getPreambleContainerMediaQuery = (size: ViewportSize) => {
  // The horizontal padding around the preamble content
  const hPadding = contentHSpace[size];

  return {
    [mqAt(size)]: {
      paddingRight: hPadding,
      paddingLeft: hPadding,
    },
  };
};

const PreambleContainer = ({bodyHasTabs, children}: PreambleContainerProps) => {
  return (
    <Box
      background={colors.soap100}
      borderRadius={bodyHasTabs ? `${bentoRadius}px ${bentoRadius}px 0 0` : bentoRadius}
      marginRight="xxs"
      paddingTop="xxxl"
      // Negative bottom margin is used to hide the bentoBox radius applied to
      // the tabs list (if it exists)
      marginBottom={bodyHasTabs ? -bentoRadius : undefined}
      // Bottom padding needs to compensate for negative bottom margin (if it
      // exists)
      paddingBottom={bodyHasTabs ? `calc(${space.xl} + ${bentoRadius}px)` : space.xxxl}
      css={combineMediaQueries(getPreambleContainerMediaQuery)}
    >
      {children}
    </Box>
  );
};

export interface ContentPreambleProps {
  bodyHasTabs?: boolean;
  children: React.ReactNode;
}

export const ContentPreamble = ({
  children,
  bodyHasTabs = false,
  ...elemProps
}: ContentPreambleProps) => (
  <>
    {/*
      This Box creates vertical spacing needed for the "bento box" style
      of the preamble. It creates a vertcal gap between the site header
      and the preamble which needs to stay in place as you scroll through
      the main content of the page.
    */}
    <Box
      height={headerPreambleContentGap}
      position="sticky"
      top={headerHeight}
      width="100%"
      backgroundColor={colors.frenchVanilla100}
      zIndex={2}
      {...elemProps}
    />
    <PreambleContainer bodyHasTabs={bodyHasTabs} {...elemProps}>
      <Box maxWidth={maxBespokeContentWidth}>{children}</Box>
    </PreambleContainer>
  </>
);

export interface IntroductionProps {
  children: string;
}

ContentPreamble.Introduction = ({children, ...elemProps}: IntroductionProps) => (
  <Box
    as="p"
    marginTop="m"
    maxWidth={maxContentWidth}
    css={{...type.levels.body.large, ...type.variants.hint}}
    {...elemProps}
    dangerouslySetInnerHTML={{__html: children}}
  />
);

export interface TitleProps {
  children: string;
}

ContentPreamble.Title = ({children, ...elemProps}: TitleProps) => (
  <H1 css={{maxWidth: maxContentWidth}} {...elemProps}>
    {children}
  </H1>
);
