import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {checkIcon} from '@workday/canvas-system-icons-web';
import {colors} from '@workday/canvas-kit-react/tokens';
import {TabbedContent, TabsData} from '../TabbedContent';

const depthData: TabsData = {
  zero: {
    title: 'Depth 0',
    notes: [
      {title: 'Select drop-down'},
      {title: 'Objects resting on grey backgrounds'},
      {title: 'A stroke may be applied to create definition if depth is not necessary'},
    ],
  },
  one: {
    title: 'Depth 1',
    notes: [{title: 'Card  components'}],
  },
  two: {
    title: 'Depth 2',
    notes: [{title: 'Top navigation'}, {title: 'Bottom navigation'}],
  },
  three: {
    title: 'Depth 3',
    notes: [{title: 'Floating Action Button (FAB)'}, {title: 'Menus'}],
  },
  four: {
    title: 'Depth 4',
    notes: [{title: 'Bottom sheets'}],
  },
  five: {
    title: 'Depth 5',
    notes: [
      {title: 'Banners'},
      {title: 'Snackbars'},
      {title: 'Toast Messages'},
      {title: 'Non modal Dialogs'},
      {title: 'Side Panels', subtext: '(when opacity overlay behaviour is not applied)'},
    ],
  },
  six: {
    title: 'Depth 6',
    notes: [
      {title: 'Modal Dialogs'},
      {title: 'Side Panels', subtext: '(when opacity overlay behaviour is applied)'},
    ],
  },
};

export const DepthUsageTabs = () => {
  const {
    allFile: {edges: images},
  } = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^depth-guide/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return (
    <TabbedContent
      tabsData={depthData}
      images={images}
      notesIconSettings={{icon: checkIcon, color: colors.greenApple400}}
      isTitleTabNames
    />
  );
};

export default DepthUsageTabs;
