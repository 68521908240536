import { SystemIcon, AccentIcon, AppletIcon } from '@workday/canvas-kit-react/icon';
import { sparkleSingleSmallIcon, searchSparkleIcon, documentSparkleIcon, editSparkleIcon, uploadIcon, moonIcon, noConnectionCloudIcon, thumbsDownFilledIcon, thumbsDownOutlinedIcon, thumbsUpFilledIcon, thumbsUpOutlinedIcon, filterExclamationIcon, referenceLineIcon, peopleRecruitingIcon, infoSparkleIcon, laurelWreathIcon, onboardingHomeIcon } from '@workday/canvas-system-icons-web';
import { sparkleIcon, candidateEngagementIcon, educationFinanceIcon, helpIcon, minusCircleIcon, mountainFlagIcon, notificationIcon, plusCircleIcon, scrollShieldIcon, skillsIcon, videoPlaylistIcon, workdayByokIcon } from '@workday/canvas-accent-icons-web';
import { candidateEngagementIcon as candidateEngagementAppletIcon, careerHubIcon as careerHubAppletIcon, extendIcon as extendAppletIcon, flowWriterIcon as flowWriterAppletIcon, guidedRequisitionIcon as guidedRequisitionAppletIcon, journeyIcon as journeyAppletIcon, managerInsightsIcon as managerInsightsAppletIcon, peopleWorkspaceIcon as peopleWorkspaceAppletIcon, planningIcon as planningAppletIcon, removeParInventoryIcon as removeParInventoryAppletIcon, userCartHubIcon as userCartHubAppletIcon, workdayByokIcon as workdayByokAppletIcon, jobReqWorkspaceIcon as jobReqWorkspaceAppletIcon } from '@workday/canvas-applet-icons-web';
import * as React from 'react';
export default {
  SystemIcon,
  AccentIcon,
  AppletIcon,
  sparkleSingleSmallIcon,
  searchSparkleIcon,
  documentSparkleIcon,
  editSparkleIcon,
  uploadIcon,
  moonIcon,
  noConnectionCloudIcon,
  thumbsDownFilledIcon,
  thumbsDownOutlinedIcon,
  thumbsUpFilledIcon,
  thumbsUpOutlinedIcon,
  filterExclamationIcon,
  referenceLineIcon,
  peopleRecruitingIcon,
  infoSparkleIcon,
  laurelWreathIcon,
  onboardingHomeIcon,
  sparkleIcon,
  candidateEngagementIcon,
  educationFinanceIcon,
  helpIcon,
  minusCircleIcon,
  mountainFlagIcon,
  notificationIcon,
  plusCircleIcon,
  scrollShieldIcon,
  skillsIcon,
  videoPlaylistIcon,
  workdayByokIcon,
  candidateEngagementAppletIcon,
  careerHubAppletIcon,
  extendAppletIcon,
  flowWriterAppletIcon,
  guidedRequisitionAppletIcon,
  journeyAppletIcon,
  managerInsightsAppletIcon,
  peopleWorkspaceAppletIcon,
  planningAppletIcon,
  removeParInventoryAppletIcon,
  userCartHubAppletIcon,
  workdayByokAppletIcon,
  jobReqWorkspaceAppletIcon,
  React
};