import React from 'react';

import CodeBlock from './CodeBlock';

interface ExampleCodeBlockProps {
  code: {
    (): JSX.Element;
    __RAW__: string;
  };
  live?: boolean;
  scope?: object;
  showCode?: boolean;
}

const ExampleCodeBlock = ({code, live = true, scope, showCode = false}: ExampleCodeBlockProps) => {
  return (
    <CodeBlock
      className="language-tsx"
      live={live}
      scope={scope}
      showCode={showCode}
      Component={code}
    >
      {code.__RAW__}
    </CodeBlock>
  );
};

export default ExampleCodeBlock;
