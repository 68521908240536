export const camelToTitleCase = (str: string) => {
  return str
    .replace(/([A-Z]|[1-6])/g, ' $1')
    .toLowerCase()
    .split(' ')
    .map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
};

export const kebabToTitleCase = (str: string) => {
  return str
    .split('-')
    .map(word => {
      switch (word) {
        case 'ui':
          return 'UI';
        case 'and':
          return 'and';
        case 'the':
          return 'the';
        case 'a':
          return 'a';
        default:
          return word.charAt(0).toUpperCase() + word.slice(1);
      }
    })
    .join(' ');
};

/**
 * Returns whether or not `str` ends with `searchStr` (IE doesn't support the
 * built-in endsWith).
 */
export const endsWith = (str: string, searchStr: string) => {
  if (str) {
    return str.substring(str.length - searchStr.length, str.length) === searchStr;
  }
  return false;
};

/**
 * Hash function taken from: https://github.com/bryc/code/blob/master/jshash/experimental/cyrb53.js
 * Source: https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
 */
export const hashString = (str: string, seed = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return (4294967296 * (2097151 & h2) + (h1 >>> 0)).toString();
};
