import styled from '@emotion/styled';

import {Hyperlink} from '@workday/canvas-kit-react/button';
import {space, colors, commonColors, type} from '@workday/canvas-kit-react/tokens';

import {H1, H2, H3, H4, H5} from './content/Heading';
import {Table} from './content/Table';

export const listStyles = {
  paddingLeft: space.xl,
  margin: `${space.m} 0 ${space.l}`,
};

export {H1, H2, H3, H4, H5};

export const P = styled('p')({
  marginTop: space.s,
});

export const DD = styled('dd')({
  marginTop: space.xs,
  '& + dt': {
    marginTop: space.xs,
  },
});

export const DL = styled('dl')({
  ...listStyles,
});

export const OL = styled('ol')({
  ...listStyles,
});

export const UL = styled('ul')({
  ...listStyles,
});

export const LI = styled('li')({
  '& + li': {
    marginTop: space.xs,
  },
});

export const Blockquote = styled('blockquote')({
  marginTop: space.s,
  padding: `${space.xxs} ${space.m}`,
  borderLeft: `4px solid ${colors.soap400}`,
  ...type.variants.hint,

  p: {
    margin: 0,
  },
});

export const HR = styled('hr')({
  display: 'block',
  height: '1px',
  border: 0,
  borderTop: `1px solid ${commonColors.divider}`,
  margin: `${space.m} 0`,
  padding: 0,
});

export const Img = styled('img')({
  maxWidth: '100%',
});

// Define styles for standard HTML elements as custom Emotion-styled
// components. When passed in as shortcodes to MDXProvider, these
// custom components will be rendered in place of standard HTML
// elements (h1, p, etc.) when the MDX is converted to HTML. Because
// the styles for these custom components are being assigned using
// Emotion-generated class names, they won't interfere with styles for
// Canvas Kit components in rendered code examples. For example, the
// styles for h3 on the Canvas Site won't interfere with the styles for
// the h3 rendered by the Canvas Kit Card component.
//
// https://www.gatsbyjs.com/docs/how-to/routing/customizing-components/
const StyledElements = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  p: P,
  dd: DD,
  dl: DL,
  ol: OL,
  ul: UL,
  li: LI,
  a: Hyperlink,
  strong: styled('strong')({
    fontWeight: 700,
  }),
  em: styled('em')({
    fontStyle: 'italic',
  }),
  hr: HR,
  blockquote: Blockquote,
  img: Img,
  table: Table,
};

export default StyledElements;
