import React from 'react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import {Link} from 'gatsby';
import {hideMouseFocus, mouseFocusBehavior} from '@workday/canvas-kit-react/common';
import {SystemIcon} from '@workday/canvas-kit-react/icon';
import * as systemIcons from '@workday/canvas-system-icons-web';
import {CanvasSystemIcon} from '@workday/design-assets-types';
import {colors, space, type} from '@workday/canvas-kit-react/tokens';
import {Text} from '@workday/canvas-kit-react/text';

export interface SidebarItemProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  /**
   * URL for the React Router Link component.
   */
  to: string;
  /**
   * The title of the menu item
   */
  children: React.ReactNode;
  /**
   * Whether or not the item is a parent of other pages
   */
  isParent?: boolean;
  /**
   * Optional menu item icon
   */
  icon?: CanvasSystemIcon;
  /**
   * If true, will change the background to indicate the item is selected
   */
  selected?: boolean;
  /**
   * The level of indentation of the menu item. If `icon` is defined, this will be ignored (icons are not supported on sub-items).
   */
  indent?: 0 | 1 | 2 | 3;
  /**
   * The aria controls id for the expandable content
   */
  contentId?: string;
  /**
   * When defined, will show a chevron on the right side of the item. True is down, False is up.
   */
  expanded?: boolean;
}

const baseStyle = {
  background: 'transparent',
  color: colors.blackPepper300,
  textDecoration: 'none',
  '.wd-icon-fill, .wd-icon-accent': {
    fill: colors.licorice200,
  },
};
const hoverStyle = {
  background: colors.soap500,
  color: colors.blackPepper400,
  textDecoration: 'none',
  cursor: 'pointer',
  '.category-description': {
    color: colors.blackPepper400,
  },
};
const selectedStyle = {
  background: colors.blueberry100,
  color: colors.blueberry400,
  boxShadow: `inset ${space.xxxs} 0 ${colors.blueberry400}`,
  '.wd-icon-fill, .wd-icon-accent': {
    fill: colors.blueberry400,
  },
};

// TODO: Update when type line height changes
const paddingMap = {
  0: `${space.s} ${space.m}`,
  1: `10px ${space.m} 9px 48px`, // 9 + 10 + 21 (line height) = 40
  2: `6px ${space.m} 5px 72px`, // 5 + 6 + 21 (line height) = 32
  3: `6px ${space.m} 5px 96px`,
};

const StyledLink = styled(Link, {
  shouldForwardProp: prop => isPropValid(prop), // && prop !== 'color'
})<SidebarItemProps & {hasIcon: boolean}>(
  {
    width: '100%',
    textAlign: 'left',
    border: 0,
    display: 'flex',
    alignItems: 'center',
    transition: '150ms background, 1000ms box-shadow ease-out',
    boxShadow: `inset 0 0 transparent`,
    ...baseStyle,
    ...hideMouseFocus,
    ...mouseFocusBehavior({
      '&:focus': {
        ...baseStyle,
        '&:hover': hoverStyle,
        '&.active': selectedStyle,
      },
    }),
  },
  ({indent = 0}) => ({
    padding: paddingMap[indent],
    ...(indent > 0 ? type.levels.subtext.large : type.levels.body.small),
    ...(indent > 0 ? {} : {fontWeight: type.properties.fontWeights.bold}),
  }),
  ({selected}) =>
    selected
      ? selectedStyle
      : {
          '&:hover': hoverStyle,
          '&:focus': {
            background: colors.blueberry400,
            color: colors.frenchVanilla100,
            textDecoration: 'none',
            outline: 'none',
            '.category-description': {
              color: colors.frenchVanilla100,
            },
            '.wd-icon-fill, .wd-icon-accent': {
              fill: colors.frenchVanilla100,
            },
          },
        }
);

export const SidebarItem = React.forwardRef(
  (
    {
      selected = false,
      indent = 0,
      children,
      isParent,
      expanded,
      icon,
      to,
      contentId,
      ...elemProps
    }: SidebarItemProps,
    ref
  ) => {
    const buttonProps = isParent
      ? {
          as: 'button',
          id: `expandable-${contentId}`,
          'aria-expanded': expanded,
          'aria-controls': `expandable-content-${contentId}`,
          to: undefined,
        }
      : {};

    return (
      <StyledLink
        to={to}
        selected={selected}
        indent={indent}
        hasIcon={!!icon}
        ref={ref}
        {...buttonProps}
        {...elemProps}
      >
        {indent > 0 ? (
          <>
            {isParent && (
              <SystemIcon
                icon={
                  expanded ? systemIcons.chevronDownSmallIcon : systemIcons.chevronRightSmallIcon
                }
              />
            )}
            <Text as="div" marginLeft={space.xxs} flexGrow="1">
              {children}
            </Text>
          </>
        ) : (
          <>
            {icon && <SystemIcon marginRight="xxs" alignSelf="baseline" icon={icon} />}
            <Text as="div" flexGrow="1">
              {children}
            </Text>
            {isParent && (
              <SystemIcon
                icon={expanded ? systemIcons.chevronUpSmallIcon : systemIcons.chevronDownSmallIcon}
              />
            )}
          </>
        )}
      </StyledLink>
    );
  }
);

export default SidebarItem;
