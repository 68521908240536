import React from 'react';
import {withPrefix} from 'gatsby';

import {pathToNavId} from './SidebarNew';

import {voiceOverFocusDelay} from '../../utils/a11y';

interface NavigationContextObject {
  isHomePage: () => boolean;

  activePath: () => string;
  setActivePath: (path: string) => void;

  /**
   * Indicates if an L1 nav item was just activated (i.e., clicked on). This
   * affects how focus behaves. Normally, when a page is loaded, focus is
   * automatically transferred to the h1. However, if an L1 is activated, focus
   * should instead be transferred to the L2 which is activated a result of
   * activating that L1.
   */
  isL1Activated: () => boolean;
  setIsL1Activated: (isActivated: boolean) => void;

  activeL1Path: () => string;

  hoveredL1Path: () => string;
  setHoveredL1Path: (path: string) => void;

  isMenuCollapsed: () => boolean;
  setIsMenuCollapsed: (isCollapsed: boolean) => void;

  isHoveredMenuHeldOpen: () => boolean;
  setIsHoveredMenuHeldOpen: (isHeld: boolean) => void;
}

const NavigationContext = React.createContext<NavigationContextObject | undefined>(undefined);

interface NavigationProviderProps {
  children: React.ReactNode;
  currentPath: string;
}

export const NavigationProvider = ({children, currentPath}: NavigationProviderProps) => {
  const isHomePage = () => currentPath === withPrefix('/');

  const [isMenuCollapsedValue, setIsMenuCollapsedValue] = React.useState(isHomePage());
  const [activePathValue, setActivePathValue] = React.useState(currentPath);
  const [isL1ActivatedValue, setIsL1ActivatedValue] = React.useState(false);
  const [hoveredL1PathValue, setHoveredL1PathValue] = React.useState('');

  // We need access to the latest value of the isHoveredMenuHeldOpenValue state
  // variable within a setTimeout (see below). Due to how closures work, we
  // need to use a ref (isHoveredMenuHeldOpenRef) to acheive this.
  // Source: https://stackoverflow.com/questions/55198517/react-usestate-why-settimeout-function-does-not-have-latest-state-value/77228056#77228056
  const isHoveredMenuHeldOpenRef = React.useRef(false);
  const [isHoveredMenuHeldOpenValue, setIsHoveredMenuHeldOpenValue] = React.useState(false);

  const activePath = () => activePathValue;
  const setActivePath = (path: string) => {
    setActivePathValue(path);
  };

  const isL1Activated = () => isL1ActivatedValue;
  const setIsL1Activated = (isActivated: boolean) => {
    setIsL1ActivatedValue(isActivated);
  };

  const activeL1Path = () => '/' + activePathValue.split('/')[1];

  const hoveredL1Path = () => hoveredL1PathValue;
  const setHoveredL1Path = (path: string) => {
    setHoveredL1PathValue(path);
  };

  const isMenuCollapsed = () => isMenuCollapsedValue;
  const setIsMenuCollapsed = (isCollapsed: boolean) => {
    setIsMenuCollapsedValue(isCollapsed);
  };

  const isHoveredMenuHeldOpen = () => isHoveredMenuHeldOpenRef.current;
  const setIsHoveredMenuHeldOpen = (isHeld: boolean) => {
    setIsHoveredMenuHeldOpenValue(isHeld);
  };

  // See comment above regarding isHoveredMenuHeldOpenRef
  React.useEffect(() => {
    isHoveredMenuHeldOpenRef.current = isHoveredMenuHeldOpenValue;
  }, [isHoveredMenuHeldOpenValue]);

  // Update nav state on first render and if currentPath changes
  React.useEffect(() => {
    setIsMenuCollapsed(isHomePage());

    // Remove path prefix and trailing slash (if it exists)
    // TODO: is this really the only way to get the path prefix?
    const pathPrefix = withPrefix('/').replace(/\/$/, '');
    const currentPathWithoutPrefix = currentPath.replace(pathPrefix, '').replace(/\/$/, '');

    setActivePath(currentPathWithoutPrefix);

    // Focus newly activated L2 after activating an L1. It is recommended to
    // set focus after a 350 millisecond delay to account for VoiceOver.
    const delayedFocusTimer = setTimeout(() => {
      if (isL1ActivatedValue) {
        const activeNavItemElem = document.getElementById(pathToNavId(currentPathWithoutPrefix));
        activeNavItemElem?.focus({preventScroll: true});
      }
    }, voiceOverFocusDelay);

    return () => clearTimeout(delayedFocusTimer);
  }, [currentPath]);

  return (
    <NavigationContext.Provider
      value={{
        isHomePage,
        activePath,
        setActivePath,
        isL1Activated,
        setIsL1Activated,
        activeL1Path,
        hoveredL1Path,
        setHoveredL1Path,
        isMenuCollapsed,
        setIsMenuCollapsed,
        isHoveredMenuHeldOpen,
        setIsHoveredMenuHeldOpen,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = () => {
  const context = React.useContext(NavigationContext);
  if (context === undefined) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
