import React from 'react';
import {useSiteMetadata} from '../utils/useSiteMetadata';

export const InternalContent = ({children}: {children: React.ReactNode}) => {
  const config = useSiteMetadata();

  if (config.internal) {
    return <>{children}</>;
  }

  return null;
};

export const ExternalContent = ({children}: {children: React.ReactNode}) => {
  const config = useSiteMetadata();

  if (!config.internal) {
    return <>{children}</>;
  }

  return null;
};
