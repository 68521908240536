import queryString from 'query-string';

/**
 * Returns the value of the given key in the browser's current URL fragment. If
 * no key was provided, return the entire fragment.
 */
export const getFragmentValue = (key?: string) => {
  // Check if window is defined in case we're using server side rendering
  if (typeof window === 'undefined') {
    return;
  }

  const hash = window.location.hash;

  if (key) {
    const parsed = queryString.parse(hash);

    // Assert value as a string (we don't expect to see an array
    // of strings here)
    return parsed[key] as string;
  }

  // If key is undefined, strip out the `#` and return the entire fragment
  return hash.substring(1);
};

/**
 * Returns the element represented in the browser's current URL fragment. The
 * element id can be respresented one of two ways:
 *
 * 1. Within a complex fragment: #tab=tabName&heading=headingId (if a heading
 *    id needs to coexist with a tab name in the fragment)
 * 2. Within a standard fragment: #id
 */
export const getFragmentElement = () => {
  // Check for the complex #heading=id format first
  const elementId = getFragmentValue('heading') || getFragmentValue();

  return elementId ? document.getElementById(elementId) : null;
};

/**
 * Returns the numerical index of the tab encoded in the browser's current
 * URL fragment (if present).
 */
export const getFragmentTabIndex = () => {
  const tabValue = getFragmentValue('tab');

  // If no tab value is present in the fragment, default to index 0
  if (!tabValue) {
    return 0;
  }

  // Return stringified tabValue if it's an integer. Otherwise, map the
  // tabValue to its corresponding numerical index.
  let tabIndex = parseInt(tabValue, 10);
  if (isNaN(tabIndex)) {
    // Default tabIndex to 0 in case tabValue isn't found
    tabIndex = 0;
    const tabs = document.querySelectorAll('.tabs div[role="tablist"] > *');
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].getAttribute('data-slug') === tabValue) {
        return i;
      }
    }
  }

  return tabIndex;
};

/**
 * Returns the correct href for a heading link (preserves the tab value).
 */
export const getHeadingLink = (
  heading?: string,
  tab: string | undefined = getFragmentValue('tab')
) => {
  if (tab) {
    const obj = {tab, heading};
    return `#${queryString.stringify(obj, {sort: false})}`;
  }

  return `#${heading}`;
};

/**
 * Sets the browser's URL fragment to the provided values.
 */
export const setFragment = (values: {[key: string]: string | undefined}) => {
  const fragment = queryString.stringify(values, {sort: false});

  // If we modify the fragment using navigate (@reach/router), the browser
  // tries to find an element with id `#tab=${tab}&heading=${heading}...`.
  // Upon failing (which it inevitably will), the browser scrolls to the
  // top of the page. Every time you select a tab, click on an anchor nav
  // item, or do anything else which modifies the fragment, the page scrolls
  // to the top.
  //
  // To prevent this undesired scrolling behavior, we use pushState instead.
  //
  // Reference: https://stackoverflow.com/a/14560718
  history.pushState({}, '', `#${fragment}`);
};

/**
 * Sets the heading value in the browser's URL fragment. Setting the heading
 * value preserves the tab value (if it exists) since we assume you're
 * navigating to a different heading within the same tab.
 */
export const setFragmentHeadingValue = (heading: string) => {
  setFragment({
    tab: getFragmentValue('tab'),
    heading,
  });
};

/**
 * Sets the tab value in the browser's URL fragment. Setting the tab value
 * wipes out the heading value (if it exists) since we assume you're
 * navigating to another tab with different headings than the previous tab.
 */
export const setFragmentTabValue = (tab: string) => {
  setFragment({tab});
};
