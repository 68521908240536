import React from 'react';
import {colors} from '@workday/canvas-kit-react/tokens';
import {SystemIcon} from '@workday/canvas-kit-react/icon';
import {activityStreamIcon} from '@workday/canvas-system-icons-web';

export default () => (
  <>
    <SystemIcon icon={activityStreamIcon} />
    <SystemIcon icon={activityStreamIcon} color={colors.blueberry500} />
    <SystemIcon
      icon={activityStreamIcon}
      accent={colors.frenchVanilla100}
      fill={colors.blueberry500}
      background={colors.blueberry500}
    />
    <SystemIcon icon={activityStreamIcon} size={48} />
  </>
);
