import React from 'react';
import {withPrefix, Link} from 'gatsby';

import {PrimaryButton, TertiaryButton} from '@workday/canvas-kit-react/button';
import {hideMouseFocus} from '@workday/canvas-kit-react/common';
import {Box, Flex} from '@workday/canvas-kit-react/layout';
import {Menu} from '@workday/canvas-kit-react/menu';
import {Modal, useModalModel} from '@workday/canvas-kit-react/modal';
import {
  Popup,
  usePopupModel,
  useCloseOnEscape,
  useCloseOnOutsideClick,
  useInitialFocus,
  useReturnFocus,
} from '@workday/canvas-kit-react/popup';
import {Text, Subtext} from '@workday/canvas-kit-react/text';
import {borderRadius, colors, type, space} from '@workday/canvas-kit-react/tokens';

import {
  gridViewIcon,
  searchIcon,
  caretDownIcon,
  notificationsIcon,
} from '@workday/canvas-system-icons-web';

import {DeprecatedHeader} from './deprecated/header';
import {Media} from './Media';
import {Platform, usePlatformSwitcher} from './PlatformSwitcher';
import {SiteSwitcher} from './SiteSwitcher';
import {Sidebar} from './nav/Sidebar';
import {SearchForm} from './search/SearchForm';
import {headerHeight, mqLessThan} from '../utils/breakpoints';
import {fullCKVersion, majorCKVersion} from '../utils/packages';
import {Location} from '../utils/types';

// TODO: Workaround for TS cannot find module error, consider adding custom
// type definition instead.
// https://www.gatsbyjs.com/plugins/gatsby-plugin-react-svg/#using-with-typescript
// https://annacoding.com/article/5I7om6mCrW25KeA4ObeyMJ/Two-ways-to-solve:-Cannot-find-SVG-module-error-when-compiling-Typescript-in-React-application
// eslint-disable-next-line @typescript-eslint/no-var-requires
const CanvasLogo = require('../images/canvas-logo.svg') as {default: string};

const SiteNavigation = ({location}: {location: Location}) => {
  // We don't need the Modal to return focus to the Modal.Target button since
  // the site will automatically assign focus the destination page's H1 (see
  // the H1 component). Set returnFocusRef to a dummy ref.
  //
  // TODO: Note that this means clicking the Overlay to dismiss the Modal won't
  // return focus to the Modal.Target. Is this ok?
  const ref = React.useRef(null);
  const model = useModalModel({returnFocusRef: ref});

  // TODO: Should we use Popup instead of Modal?
  return (
    <Modal model={model}>
      <Modal.Target as={TertiaryButton} icon={gridViewIcon} aria-label="Site Navigation" />
      {/* Shift Overlay down so it doesn't cover the Header */}
      <Modal.Overlay css={{top: headerHeight}}>
        {/*
          Sidebar is a fixed position relative to the viewport. Modal.Card
          seems to be required for the click-outside handling to work so
          leaving it in as a dimension-less element.
        */}
        <Modal.Card height={0} width={0} padding={0}>
          <Sidebar
            currentPath={(location && location.pathname) || '/'}
            hidePopup={() => model.events.hide()}
          />
        </Modal.Card>
      </Modal.Overlay>
    </Modal>
  );
};

const BrandLink = ({title, href}: {title: string; href: string}) => {
  const titleStyles = {
    fontSize: type.properties.fontSizes[16],

    // Somewhat arbitrarily selected 512px breakpoint for setting maxWidth of
    // title to force it to wrap
    [mqLessThan(512)]: {
      fontSize: type.properties.fontSizes[12],
      lineHeight: '16px',
      maxWidth: '120px',
    },
  };

  return (
    <Box
      as="a"
      href={href}
      css={{
        textDecoration: 'none',
        borderRadius: borderRadius.m,
        '&:focus': {
          boxShadow: `0 0 0 2px ${colors.frenchVanilla100}`,
          outline: 'none',
        },
      }}
    >
      <Flex alignItems="stretch" height="100%" paddingInlineStart="s">
        <img alt="" src={CanvasLogo.default} />
        {title && (
          <Flex alignItems="center" marginLeft="s">
            <Box as="p" id="top" tabIndex={-1} color="blackPepper400" css={titleStyles}>
              {title}
            </Box>
          </Flex>
        )}
      </Flex>
    </Box>
  );
};

const SearchButton = ({...elemProps}) => (
  <TertiaryButton icon={searchIcon} aria-label="Search" {...elemProps} />
);

const navigate = (href: string) => {
  window.location.href = href;
};

const Switcher = () => (
  <SiteSwitcher>
    {/* Have to use onClick here (instead of in SiteSwitcher.Link) because of how Menu calls its children's onClick prop.
        TODO: Fix this when Menu is updated to a compound component */}
    <SiteSwitcher.Link onClick={navigate.bind(undefined, '//www.workday.com')}>
      Workday
    </SiteSwitcher.Link>
    <SiteSwitcher.Link onClick={navigate.bind(undefined, '//brand.workday.com/')}>
      Workday Brand Central
    </SiteSwitcher.Link>
    <SiteSwitcher.Link isDisabled={true}>Workday Canvas Design System</SiteSwitcher.Link>
    <SiteSwitcher.Link onClick={navigate.bind(undefined, '//research.workday.com')}>
      Workday User Research
    </SiteSwitcher.Link>
  </SiteSwitcher>
);

const PlatformSwitcher = ({...elemProps}) => {
  const platformSwitcher = usePlatformSwitcher();

  const [menuWidth, setMenuWidth] = React.useState(0);

  const platforms: {[key in Platform]: string} = {
    all: 'All',
    web: 'Web',
    ios: 'iOS',
    android: 'Android',
  };

  return (
    <Menu items={Object.keys(platforms)}>
      <Menu.Target
        as={TertiaryButton}
        height={24}
        icon={caretDownIcon}
        iconPosition="end"
        marginLeft="xxxs"
        textDecoration="none"
        backgroundColor="soap300"
        color="blueberry500"
        fontSize={type.properties.fontSizes[12]}
        fontWeight={type.properties.fontWeights.regular}
        onFocus={(e: React.FocusEvent) => setMenuWidth(e.currentTarget.clientWidth)}
        {...elemProps}
      >
        <Text>Platform: {platforms[platformSwitcher.platformValue]}</Text>
      </Menu.Target>
      <Menu.Popper>
        <Menu.Card width={menuWidth}>
          <Menu.List>
            {(item: Platform) => (
              <Menu.Item
                data-id={item}
                onClick={() => {
                  platformSwitcher.selectPlatform(item);
                }}
              >
                {platforms[item]}
              </Menu.Item>
            )}
          </Menu.List>
        </Menu.Card>
      </Menu.Popper>
    </Menu>
  );
};

export interface HeaderProps {
  location: Location;
  siteTitle: string;
}

export const getVersionUrl = (version: string) => {
  if (typeof window !== 'undefined') {
    const chooseCorrectUrl = window.location.host.includes('canvas.')
      ? `//${window.location.host}/${version}`
      : `https://canvas.workdaydesign.com/${version}`;
    return chooseCorrectUrl + location.pathname.replace(withPrefix('/'), '/');
  }
  return;
};

const Header = ({location, siteTitle = ''}: HeaderProps) => {
  const [mobileSearchVisible, setMobileSearchVisible] = React.useState(false);
  const [vsMenuWidth, setVsMenuWidth] = React.useState(0);

  const handleSearchButtonClick = () => {
    setMobileSearchVisible(!mobileSearchVisible);
  };
  const TopRightNav = () => {
    return (
      <>
        <Switcher />
        <TertiaryButton
          as={Link}
          icon={notificationsIcon}
          to="/help/announcements"
          aria-label="Announcements"
        />
      </>
    );
  };

  const generateComponent = (size: 's' | 'm' | 'greaterThanM') => {
    const siteNavigation =
      size === 's' || size === 'm' ? <SiteNavigation location={location} /> : null;

    const contents =
      size === 's' ? (
        <SearchButton onClick={handleSearchButtonClick} />
      ) : (
        <>
          {size === 'm' ? <SearchButton onClick={handleSearchButtonClick} /> : <SearchForm />}
          <TopRightNav />
        </>
      );

    const currentFullVersionNum = fullCKVersion();

    // this gets the string v9 instead of the full version number to prevent the popup from showing when there's a minor or patch release
    const currentMajorVersionNum = majorCKVersion();

    const versionDropdownRef = React.useRef<HTMLButtonElement>(null);

    const handleHideVersionPopup = () => {
      localStorage.setItem(`seenVersionPopup${currentMajorVersionNum}`, currentMajorVersionNum);
    };

    const model = usePopupModel();

    useCloseOnOutsideClick(model);
    useCloseOnEscape(model);
    useInitialFocus(model);
    useReturnFocus(model);

    React.useEffect(() => {
      const seenVersionPopup = localStorage.getItem(`seenVersionPopup${currentMajorVersionNum}`);
      const expirationDate = new Date('December 25, 2023 01:15:00');
      const currentDate = new Date();

      if (!seenVersionPopup && currentDate < expirationDate && !location.href.includes('v10')) {
        model.events.show();
      }
    }, []);

    // Update this array when a new version comes out to map over for the version dropdown
    const versionsForDropdown = ['v10', 'v9', 'v8', 'v7', 'v6'];

    return (
      <header role="banner">
        <DeprecatedHeader
          title={siteTitle}
          themeColor={DeprecatedHeader.Theme.Blue}
          brand={<span></span>} // Hack to override brand link styling.
          leftSlot={
            <Flex flex="1 0 auto" height="100%" padding="xxs" alignItems="center">
              {siteNavigation}
              <BrandLink title={siteTitle} href={withPrefix('/')} />
              <Menu items={versionsForDropdown}>
                <Menu.Target
                  as={TertiaryButton}
                  height={24}
                  icon={caretDownIcon}
                  iconPosition="end"
                  marginLeft="s"
                  textDecoration="none"
                  backgroundColor="soap300"
                  color="blueberry500"
                  fontSize={type.properties.fontSizes[12]}
                  fontWeight={type.properties.fontWeights.regular}
                  ref={versionDropdownRef}
                  onFocus={(e: React.FocusEvent) => setVsMenuWidth(e.currentTarget.clientWidth)}
                >
                  <Text>{currentFullVersionNum}</Text>
                </Menu.Target>
                <Menu.Popper>
                  <Menu.Card width={vsMenuWidth}>
                    <Menu.List>
                      {item => (
                        <Menu.Item
                          as="a"
                          href={getVersionUrl(item)}
                          css={{textDecoration: 'none'}}
                          target="_blank"
                          data-id={item}
                        >
                          {item}
                        </Menu.Item>
                      )}
                    </Menu.List>
                  </Menu.Card>
                </Menu.Popper>
              </Menu>
              {size === 'greaterThanM' && <PlatformSwitcher />}
              <Popup model={model}>
                <Popup.Popper anchorElement={versionDropdownRef}>
                  <Popup.Card
                    padding={space.s}
                    marginTop={space.s}
                    overflowY="visible"
                    maxWidth={'258px'}
                    css={{
                      '&:after': {
                        content: '""',
                        width: '20px',
                        height: '20px',
                        transform: 'rotate(-45deg)',
                        background: '#fff',
                        position: 'absolute',
                        top: '-10px',
                        left: 'calc(50% - 10px)',
                      },
                    }}
                  >
                    <Popup.CloseIcon aria-label="Close" />
                    <Popup.Heading>
                      <Subtext size="large" fontWeight={'700'}>
                        <span role="img" aria-label="party">
                          🎉🎉{' '}
                        </span>{' '}
                        Canvas v10 is here!
                        <span role="img" aria-label="party">
                          🎉🎉{' '}
                        </span>
                      </Subtext>
                    </Popup.Heading>
                    <Popup.Body>
                      <Subtext size="large">
                        The site has been updated with new v10 styles, components and documentation.
                        Use this dropdown to access previous versions.
                      </Subtext>
                    </Popup.Body>
                    <Flex justifyContent="flex-end">
                      <Popup.CloseButton
                        as={PrimaryButton}
                        onClick={handleHideVersionPopup}
                        size="small"
                        grow={false}
                      >
                        Got it
                      </Popup.CloseButton>
                    </Flex>
                  </Popup.Card>
                </Popup.Popper>
              </Popup>
            </Flex>
          }
          css={{
            zIndex: 20,
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            background: colors.soap100,
            boxShadow: 'none',
            a: {
              ...hideMouseFocus,
            },

            // Prevent content of Emotion-injected <style> tags from rendering on the
            // page briefly before they're moved to the <head> at runtime (due to the
            // `> *:not(.canvas-header--menu-icon)` selector in the CK Header)
            style: {
              display: 'none !important',
            },
          }}
        >
          {contents}
        </DeprecatedHeader>
        {mobileSearchVisible && (
          // TODO: This Box should probably be part of the mobile SearchForm
          <Box
            zIndex={30}
            position="fixed"
            top={0}
            left={0}
            right={0}
            backgroundColor={colors.frenchVanilla100}
          >
            <SearchForm mobile={true} hideSearch={() => setMobileSearchVisible(false)} />
          </Box>
        )}
      </header>
    );
  };

  return (
    <>
      <Media at="s">{generateComponent('s')}</Media>
      <Media at="m">{generateComponent('m')}</Media>
      <Media greaterThan="m">{generateComponent('greaterThanM')}</Media>
    </>
  );
};

export default Header;
