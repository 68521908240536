import React from 'react';
import {persistToSessionStorage, restoreFromSessionStorage} from './sessionStorage';
import {Hierarchy, useHierarchy} from './useHierarchy';

export interface NavHierarchy extends Hierarchy {
  expanded?: boolean;
  selected?: boolean;
}

/**
 * A Nav object based on the NavHierarchy, but organized into keyed sections,
 * where the keys are used for the title of the section in the sidebar.
 */
export type Nav = {
  [key: string]: NavHierarchy[];
};

/**
 * An object mapping sections in the nav coresponding to the '
 * prefix of the path of a category (e.g. 'components');
 */
type SectionMap = {
  [section: string]: string[];
};

export const defaultStorageKey = 'CanvasNavHierarchy';

/**
 * Traverses the hierarchy and returns the page
 * (in the form of a sub-hierarchy object) based on the path provided.
 */
export const getPageFromPath = (path: string, hierarchy: Hierarchy, match: Hierarchy[] = []) => {
  // If the provided path is anywhere within `/patterns`, immediately return
  // the Patterns Overview page. All paths within `/patterns` will map to the
  // Patterns Overview page.
  if (path.indexOf('/patterns') !== -1) {
    if (hierarchy.children) {
      return hierarchy.children.find(element => element.title === 'Patterns');
    }
  }
  if (hierarchy.path === path.replace(/\/$/, '')) {
    match.push(hierarchy);
    return hierarchy;
  }
  if (hierarchy.children) {
    hierarchy.children.forEach(child => getPageFromPath(path, child, match));
  }
  return match[0] || hierarchy; // TODO: Shouldn't need `|| hierarchy` here.
};

export const generateStatelessNav = (sectionMap: SectionMap, hierarchy: Hierarchy) => {
  const navStateless: Nav = {
    _: [], // The uncategorized categories in the sidebar
  };
  Object.keys(sectionMap).forEach(section => (navStateless[section] = []));

  // eslint-disable-next-line mdx/no-unused-expressions
  hierarchy.children?.forEach(child => {
    for (const section in sectionMap) {
      for (const category of sectionMap[section]) {
        if (child.path.startsWith(`/${category}`)) {
          navStateless[section].push(child);
          return;
        }
      }
    }
    navStateless._.push(child);
  });

  Object.keys(sectionMap).forEach(key =>
    navStateless[key].sort((a, b) => {
      if (a.category && !b.category) {
        return -1;
      } else if (!a.category && b.category) {
        return 1;
      } else if (a.category && b.category) {
        return sectionMap[key].indexOf(a.category) > sectionMap[key].indexOf(b.category) ? 1 : -1;
      }
      return 0;
    })
  );

  return navStateless;
};

/**
 * Retrieves the stateful hierarchy from HTML local session storage.
 * Otherwise, generates a stateless version of the nav object from the site page hierarchy.
 */
export const useNavHierarchy = (
  sectionMap: SectionMap,
  hierarchy = useHierarchy(),
  storageKey = defaultStorageKey
) =>
  React.useState(
    React.useMemo(() => {
      const storageValue = restoreFromSessionStorage(storageKey);
      if (storageValue) {
        return storageValue;
      }

      return generateStatelessNav(sectionMap, hierarchy);
    }, [hierarchy, sectionMap, storageKey])
  );

/**
 * A hook to persist the nav state to local session storage whenever it is updated.
 */
export const useNavSessionStorage = (nav: Nav, storageKey = defaultStorageKey) =>
  React.useEffect(() => {
    persistToSessionStorage(storageKey, nav);
  }, [nav]);

/**
 * Recursive depth first search of a hierarchy object to return a descendant with a matching path.
 */
export const findPathInHierarchy = (
  hierarchy: NavHierarchy,
  path: string
): NavHierarchy | undefined => {
  if (hierarchy.children && hierarchy.path === path) {
    return hierarchy;
  }
  if (hierarchy.children) {
    for (const child of hierarchy.children) {
      const match = findPathInHierarchy(child, path);
      if (match) {
        return match;
      }
    }
  }
  return;
};

/**
 * Iterates over each section of the nav and updates the expanded state of
 * each item associated with the array of paths.
 * If expanded is defined, it will set the item's state to that value, otherwise it will inverse it.
 */
export const updateNavExpandedState = (nav: Nav, paths: string[], expanded?: boolean) => {
  const navClone: Nav = JSON.parse(JSON.stringify(nav));

  paths.forEach(path => {
    Object.keys(navClone).map(section => {
      navClone[section].forEach(hierarchy => {
        if (path.startsWith(hierarchy.path)) {
          const item = findPathInHierarchy(hierarchy, path);
          if (item) {
            item.expanded = expanded === undefined ? !item.expanded : expanded;
          }
        }
      });
    });
  });
  return navClone;
};
