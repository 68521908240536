import { ExternalHyperlink } from '@workday/canvas-kit-react/button';
import { InformationHighlight } from '@workday/canvas-kit-docs';
import { DepthUsageTabs } from "../../../../src/components/content/tokens/DepthUsageTabs";
import { DepthTokens } from "../../../../src/components/content/tokens/TokenDepthGrid";
import { getInternalExternalURL } from "../../../../src/utils/url";
import * as React from 'react';
export default {
  ExternalHyperlink,
  InformationHighlight,
  DepthUsageTabs,
  DepthTokens,
  getInternalExternalURL,
  React
};