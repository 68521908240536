import { Box } from '@workday/canvas-kit-react/layout';
import { space, colors } from '@workday/canvas-kit-react/tokens';
import { ExternalHyperlink } from '@workday/canvas-kit-react/button';
import { InformationHighlight } from '@workday/canvas-kit-docs';
import { StatusIndicator } from '@workday/canvas-kit-react/status-indicator';
import { SpaceUsageTabs } from "../../../../src/components/content/tokens/SpaceUsageTabs";
import { SpaceTokens } from "../../../../src/components/content/tokens/TokenSpaceGrid";
import { getInternalExternalURL } from "../../../../src/utils/url";
import * as React from 'react';
export default {
  Box,
  space,
  colors,
  ExternalHyperlink,
  InformationHighlight,
  StatusIndicator,
  SpaceUsageTabs,
  SpaceTokens,
  getInternalExternalURL,
  React
};