import React from 'react';

import {AccentIcon} from '@workday/canvas-kit-react/icon';
import {colors, space, type} from '@workday/canvas-kit-react/tokens';
import {Box, Flex} from '@workday/canvas-kit-react/layout';

import {exclamationCircleIcon, highFiveIcon, speechBubbleQuestionIcon} from '@workday/canvas-accent-icons-web';

import {InternalContent} from '../LimitedContent';

import {P} from '../StyledElements';
import {CardLink, CardProps} from './Card';
import {Link} from './Link';
import {
  ViewportSize,
  combineMediaQueries,
  contentHSpace,
  maxContentWidth,
  mqAt,
} from '../../utils/breakpoints';

export interface NoticeCardProps extends Omit<CardProps, 'icon'> {
  /**
   * NoticeCard requires different styling if it's being displayed in a
   * ContentBody with tabs versus a ContentBody without tabs.
   */
  bodyHasTabs?: boolean;
  // TODO: Leaving the option in for a NoticeCard without borders (how it was
  // originally designed in the spec), but we decided it looked better with a
  // border. Remove this configuration option later if we don't need it.
  bordered?: boolean;
  /**
   * The icon displayed in the NoticeCard.
   * @default exclamationCircle
   */
  icon?: 'exclamationCircle' | 'speechBubbleQuestion' | 'highFive';
}

const getNoticeCardIcon = (iconName: string) => {
  let icon;

  switch (iconName) {
    case 'speechBubbleQuestion':
      icon = speechBubbleQuestionIcon;
      break;
    case 'highFive':
      icon = highFiveIcon;
      break;
    default:
      icon = exclamationCircleIcon;
  }

  return <AccentIcon icon={icon} />;
};

const getNoticeCardMediaQuery = (size: ViewportSize) => {
  // The horizontal margin around the card content
  const hMargin = contentHSpace[size];

  return {
    [mqAt(size)]: {
      marginRight: hMargin,
      marginLeft: hMargin,
    },
  };
};

export const NoticeCard = ({
  bodyHasTabs = false,
  bordered = true,
  children,
  headingLevel = 'h2',
  headingStyles = {},
  headingText,
  icon = 'exclamationCircle',
  linkText,
  linkTo,
  ...elemProps
}: NoticeCardProps) => {
  const styles = {
    ...(bodyHasTabs && {
      maxWidth: maxContentWidth,
      ...combineMediaQueries(getNoticeCardMediaQuery),
    }),
  };

  const hasLink = linkTo && linkText;

  return (
    <Flex
      // notice-card class is used by AnchorNav in case AnchorNav needs to
      // align itself with a NoticeCard at the top of the content.
      className="notice-card exclude-anchor-nav"
      border={bordered ? `1px solid ${colors.soap400}` : undefined}
      borderRadius={bordered ? 'l' : undefined}
      marginTop="xxl"
      // Use smaller bottom padding for a bordered NoticeCard to compensate for
      // the height of the CardLink at the bottom of the NoticeCard
      padding={
        bordered
          ? `${space.m} ${space.m} ${hasLink ? space.s : space.m} ${space.m}`
          : `0 ${space.s}`
      }
      css={styles}
      {...elemProps}
    >
      <Box>{getNoticeCardIcon(icon)}</Box>
      <Box marginLeft="s">
        <Box
          as={headingLevel}
          css={{
            ...type.levels.body.large,
            fontWeight: type.properties.fontWeights.bold,
            ...headingStyles,
          }}
        >
          {headingText}
        </Box>
        {children}
        {hasLink && <CardLink to={linkTo}>{linkText}</CardLink>}
      </Box>
    </Flex>
  );
};

export interface FAQNoticeCardProps extends Pick<NoticeCardProps, 'bodyHasTabs'> {
  /**
   * The frontmatter title of the page being rendered. Used to suppress
   * rendering of the FAQNoticeCard on certain pages.
   */
  pageTitle?: string;
}

// Not necessary to suppress FAQNoticeCard from pages in src/pages (like
// index.tsx aka the Home page) since those tsx pages require you to manually
// add the <FAQNoticeCard /> component
const suppressFAQ = ['Frequently Asked Questions'];

export const FAQNoticeCard = ({pageTitle, ...elemProps}: FAQNoticeCardProps) => {
  if (pageTitle && suppressFAQ.indexOf(pageTitle) !== -1) {
    return null;
  }

  return (
    <NoticeCard
      bordered={true}
      headingText="Can't Find What You Need?"
      icon="speechBubbleQuestion"
      linkText="FAQ Section"
      linkTo="/help/faq"
      {...elemProps}
    >
      <P>
        Check out our FAQ section which may help you find the information you&apos;re looking for.
        <InternalContent>
          {' '}
          For further information, contact the{' '}
          <Link href="https://workday-dev.slack.com/archives/C7AJWQ8BG">#ask-canvas-design</Link> or{' '}
          <Link href="https://workday-dev.slack.com/archives/C8X0W5AQH">#ask-canvas-kit</Link>
          channels on Slack.
        </InternalContent>
      </P>
    </NoticeCard>
  );
};
