import React from 'react';

import {persistToSessionStorage, restoreFromSessionStorage} from '../utils/sessionStorage';
import {Hierarchy, useHierarchy} from '../utils/useHierarchy';

import {useiOSHierarchy} from '../utils/useIOSHierarchy';
import {useAndroidHierarchy} from '../utils/useAndroidHierarchy';
import {useWebHierarchy} from '../utils/useWebHierarchy';

export const platformStorageKey = 'CanvasPlatform';

export type Platform = 'all' | 'web' | 'ios' | 'android';

interface PlatformSwitcherContextObject {
  selectPlatform: (platform: string) => void;
  platformValue: Platform;
  hierarchy: Hierarchy;
}

export const PlatformSwitcherContext = React.createContext<
  PlatformSwitcherContextObject | undefined
>(undefined);

interface PlatformSwitcherProps {
  children: React.ReactNode;
}

export const PlatformSwitcherProvider = ({children}: PlatformSwitcherProps) => {
  const hierarchy = useHierarchy();
  const iOSHierarchy = useiOSHierarchy();
  const androidHierarchy = useAndroidHierarchy();
  const webHierarchy = useWebHierarchy();

  const defaultPlatform = restoreFromSessionStorage(platformStorageKey) || 'all';

  let defaultHierarchy;
  switch (defaultPlatform) {
    case 'android':
      defaultHierarchy = androidHierarchy;
      break;
    case 'ios':
      defaultHierarchy = iOSHierarchy;
      break;
    case 'web':
      defaultHierarchy = webHierarchy;
      break;
    default:
      defaultHierarchy = hierarchy;
  }

  const [value, setValue] = React.useState(defaultPlatform);
  const [hierarchyValue, setHierarchyValue] = React.useState(defaultHierarchy);

  const platformSelect = (platform: string) => {
    setValue(platform);
    persistToSessionStorage(platformStorageKey, platform);

    switch (platform) {
      case 'android':
        setHierarchyValue(androidHierarchy);
        break;
      case 'ios':
        setHierarchyValue(iOSHierarchy);
        break;
      case 'web':
        setHierarchyValue(webHierarchy);
        break;
      default:
        setHierarchyValue(hierarchy);
    }
  };

  return (
    <PlatformSwitcherContext.Provider
      value={{selectPlatform: platformSelect, platformValue: value, hierarchy: hierarchyValue}}
    >
      {children}
    </PlatformSwitcherContext.Provider>
  );
};

export const usePlatformSwitcher = () => {
  const context = React.useContext(PlatformSwitcherContext);
  if (context === undefined) {
    throw new Error('usePlatformSwitcher must be used within a PlatformSwitcherProvider');
  }
  return context;
};
