import React from 'react';
import styled from '@emotion/styled';
import {type, space, commonColors} from '@workday/canvas-kit-react/tokens';

export const Container = styled('div')<{do: boolean}>(
  {
    margin: `${space.m} 0`,

    h6: {
      ...type.h6,
      ...type.variants.hint,
      textTransform: 'uppercase',
      borderBottom: `1px solid ${commonColors.divider}`,
      fontWeight: 500,
      paddingBottom: space.xxs,
    },
    ul: {
      listStyleType: 'none',
      paddingLeft: 0,
      marginTop: space.s,

      li: {
        paddingLeft: space.l,
        backgroundRepeat: 'no-repeat',
      },
    },
  },
  props => ({
    'ul li': {
      backgroundImage: `url('data:image/svg+xml;base64, ${
        props.do
          ? 'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgY2xhc3M9IndkLWljb24tY2hlY2sgd2QtaWNvbiIgZm9jdXNhYmxlPSJmYWxzZSIgcm9sZT0icHJlc2VudGF0aW9uIiB2aWV3Qm94PSIwIDAgMjQgMjQiPjxnIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xhc3M9IndkLWljb24tY29udGFpbmVyIj48Zz48cGF0aCBkPSJNOC42NTcgMTUuODVsMTAuMjYtMTAuMjZhLjQ5LjQ5IDAgMCAxIC43MDMuMDFsLjcuN2EuNDk1LjQ5NSAwIDAgMSAuMDEuNzA0TDkuMDA1IDE4LjMzYS40ODguNDg4IDAgMCAxLS42OTIuMDAyTDguMyAxOC4zMmwtNC45NDYtNC45NDZhLjQ5NS40OTUgMCAwIDEgLjAwMy0uNzExbC43LS43YS41MDcuNTA3IDAgMCAxIC43MTEtLjAwNGwzLjg4OSAzLjg5eiIgZmlsbD0iIzQzYzQ2MyIvPjwvZz48L2c+PC9zdmc+'
          : 'PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgY2xhc3M9IndkLWljb24teCB3ZC1pY29uIiBmb2N1c2FibGU9ImZhbHNlIiByb2xlPSJwcmVzZW50YXRpb24iIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGcgZmlsbC1ydWxlPSJldmVub2RkIiBjbGFzcz0id2QtaWNvbi1jb250YWluZXIiPjxnPjxwYXRoIGQ9Ik01Ljk5NiA0LjU4MmEuNS41IDAgMCAwLS43MTctLjAwM2wtLjcuN2EuNTA0LjUwNCAwIDAgMCAuMDAzLjcxN0wxMC41ODYgMTJsLTYuMDA0IDYuMDA0YS41LjUgMCAwIDAtLjAwMy43MTdsLjcuN2MuMTk3LjE5Ny41MTQuMi43MTctLjAwM0wxMiAxMy40MTRsNi4wMDQgNi4wMDRhLjUuNSAwIDAgMCAuNzE3LjAwM2wuNy0uN2EuNTA0LjUwNCAwIDAgMC0uMDAzLS43MTdMMTMuNDE0IDEybDYuMDA0LTYuMDA0YS41LjUgMCAwIDAgLjAwMy0uNzE3bC0uNy0uN2EuNTA0LjUwNCAwIDAgMC0uNzE3LjAwM0wxMiAxMC41ODYgNS45OTYgNC41ODJ6IiBmaWxsPSIjZTAyZjJmIi8+PC9nPjwvZz48L3N2Zz4='
      }')`,
    },
  })
);

export interface DoDontProps {
  children: React.ReactElement;
  /**
   * Hides the "Do" or "Don't" heading.
   */
  hideHeading?: boolean;
}

export const Do = ({children, hideHeading, ...elemProps}: DoDontProps) => (
  <Container do={true} {...elemProps}>
    {!hideHeading && <h6>Do</h6>}
    {children}
  </Container>
);

export const Dont = ({children, hideHeading, ...elemProps}: DoDontProps) => (
  <Container do={false} {...elemProps}>
    {!hideHeading && <h6>Don&rsquo;t</h6>}
    {children}
  </Container>
);
