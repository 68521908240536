import React from 'react';
import styled from '@emotion/styled';
import {space} from '@workday/canvas-kit-react/tokens';

import {ColorCopy} from './ColorCopy';

export type ColorSetWeight = '100' | '200' | '300' | '400' | '500' | '600';

export interface ColorSetRowProps extends React.LiHTMLAttributes<HTMLLIElement> {
  hex: string;
  swatchKey: string;
  weight: ColorSetWeight;
}

const Row = styled('li')({
  padding: space.s,
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  alignItems: 'content',
  justifyContent: 'space-between',
  transition: 'transform 0.2s ease-out',
  marginTop: '0 !important', // TODO: Remove this when we figure out how to avoid content styling

  '.hex': {
    fontSize: '13px',
    lineHeight: space.m,
  },

  '&:last-child': {
    borderRadius: '0 0 10px 10px',
  },

  '&:hover, &.button-focused': {
    zIndex: 1,
    transform: 'scale(1.05, 1.1)',
    borderRadius: '3px',
  },
});

export const ColorSetRow = ({hex, swatchKey, weight, ...elemProps}: ColorSetRowProps) => {
  const [buttonFocused, setButtonFocused] = React.useState(false);

  return (
    <Row className={buttonFocused ? 'button-focused' : ''} {...elemProps}>
      <ColorCopy
        colorName={swatchKey + weight}
        onFocus={setButtonFocused.bind(undefined, true)}
        onBlur={setButtonFocused.bind(undefined, false)}
      />
      <span>{weight}</span>
      <span className="hex">{hex}</span>
    </Row>
  );
};

export default ColorSetRow;
