import React from 'react';
import {CSSObject} from '@emotion/styled';

import {space, colors, commonColors, type} from '@workday/canvas-kit-react/tokens';

import {ContentOverflowBox} from './ContentOverflowBox';

export interface TableProps {
  children?: React.ReactNode;
}

const tableStyles: CSSObject = {
  ...type.levels.subtext.medium,
  borderSpacing: 0,
  // Set minWidth to guarantee a minimum table width to improve readability of
  // content-heavy tables at small viewport sizes (see prop tables in the
  // Pagination page). Increased horizontal scrolling is preferable in this
  // case to having narrow columns with excessive text wrapping.
  minWidth: '500px',
  margin: `${space.m} 0 ${space.l}`,
  // Set `width: 100%` to ensure small tables (see the Space page) take up the
  // full available width
  width: '100%',

  // Removed style for no padding on first and last column do to clipping for links in those columns
  'td, th': {
    textAlign: 'left',
    borderStyle: 'none',
    padding: `${space.s} ${space.xxs}`,
    verticalAlign: 'top',
    borderBottom: `1px solid ${colors.soap300}`,
  },

  'thead th': {
    ...type.levels.subtext.large,
    fontWeight: type.properties.fontWeights.medium,
    verticalAlign: 'bottom',
    borderBottom: `1px solid ${commonColors.divider}`,
  },

  'tr:last-child td': {
    borderBottom: 'none',
  },

  '.gatsby-resp-image-wrapper': {
    marginTop: 0,
    marginBottom: 0,
  },
};

export const Table = ({children, ...elemProps}: TableProps) => {
  return (
    <ContentOverflowBox>
      <table css={tableStyles} {...elemProps}>
        {children}
      </table>
    </ContentOverflowBox>
  );
};

export interface CustomTableProps {
  children?: React.ReactNode;
  /**
   * An array of strings specifying the width of each column in the wrapped
   * table. Widths can be specified in any
   * [valid CSS format](https://developer.mozilla.org/en-US/docs/Web/CSS/width)
   * provided they're formatted as strings: `'10%'` is acceptable, but `10%`
   * (without the quotes) is not. The first value in the array corresponds to
   * the width of the first column, the second value to the second column, and
   * so on. You do not need to provide values for every column (omitted columns
   * will be assigned the default width of `auto`).
   */
  columnWidths?: string[];
  /**
   * A CSSObject which can be used to apply any CSS styles to the wrapped
   * table. Provided as an escape hatch; this could lead to some potentially
   * ugly MDX, avoid using this unless necessary.
   */
  tableCSS?: CSSObject;
}

export const CustomTable = ({children, columnWidths, tableCSS}: CustomTableProps) => {
  const columnWidthsCSS =
    columnWidths &&
    columnWidths.reduce((runningWidths: CSSObject, width: string, index: number) => {
      runningWidths[`thead th:nth-child(${index + 1})`] = {width};
      return runningWidths;
    }, {});

  return <div css={[tableCSS, columnWidthsCSS]}>{children}</div>;
};
