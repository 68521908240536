import {useStaticQuery, graphql} from 'gatsby';
import {buildHierarchy} from './useHierarchy';

// Build the hierarchy based on the android platform frontmatter
export const useAndroidHierarchy = () => {
  // We query allSitePage instead of allMdx because we remove internal pages in createPage
  const {
    allSitePage: {edges: pages},
  } = useStaticQuery(graphql`
    {
      allSitePage(
        filter: {context: {frontmatter: {platform: {regex: "/android/"}}}}
        sort: {fields: [path], order: ASC}
      ) {
        edges {
          node {
            path
            context {
              frontmatter {
                rank
                title
                platform
              }
            }
          }
        }
      }
    }
  `);

  // Decomposed so we can memoize everything we do to the query.
  return buildHierarchy(pages);
};
