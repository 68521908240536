import React from 'react';
import {colors} from '@workday/canvas-kit-react/tokens';
import {AccentIcon} from '@workday/canvas-kit-react/icon';
import {shieldIcon} from '@workday/canvas-accent-icons-web';

export default () => (
  <>
    <AccentIcon icon={shieldIcon} />
    <AccentIcon icon={shieldIcon} color={colors.pomegranate500} />
    <AccentIcon icon={shieldIcon} size={80} />
  </>
);
