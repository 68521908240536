import {headerHeight} from './breakpoints';

export const getNumericalStyleValue = (elem: HTMLElement | null, style: string) => {
  if (!elem || typeof window === 'undefined') {
    return 0;
  }

  return parseInt(window.getComputedStyle(elem).getPropertyValue(style), 10);
};

export const getElementTop = (elem?: HTMLElement | null) => {
  if (!elem) return 0;
  const box = elem.getBoundingClientRect();

  const body = document.body;
  const docEl = document.documentElement;

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop;
  const clientTop = docEl.clientTop || body.clientTop || 0;
  return Math.round(box.top + scrollTop - clientTop);
};

/**
 * Returns the Y position of an element to be scrolled to. Compensates for the
 * presence of the page header and tab list to provide a good user experience.
 */
export const getElementScrollY = (elem?: HTMLElement | null) => {
  if (!elem) return 0;

  const tabListHeight =
    (document.querySelector('[role=tablist]') as HTMLElement)?.offsetHeight || 0;
  const elemMarginTop = getNumericalStyleValue(elem, 'margin-top');

  // Scroll to the element (elem.offsetTop), but subtract (i.e., scroll less)
  // the heights of the page header and the tab list (if present) to prevent
  // the element from showing up behind the header and the tab list. Also,
  // subtract the top margin of the element to retain some whitespace above
  // the newly scrolled-to element to provide a visual cushion. The resulting
  // value can be negative if the element is in the page header itself (such
  // as the #top element used for the Back to Top Button) -- return 0 in this
  // case.
  return Math.max(0, elem.offsetTop - headerHeight - tabListHeight - elemMarginTop);
};

/**
 * Returns the how much of the Footer (height-wise) is currently visible in the
 * viewport. Used by AnchorNav and TopJumpLink to prevent those components from
 * colliding with the Footer. If the Footer is not visible, returns a negative
 * value correponding to how far the Footer is from being visible.
 *
 * Returns null if there is no Footer.
 */
export const getVisibleFooterHeight = () => {
  const footer = document.querySelector('footer#site-footer');
  if (footer) {
    const viewportHeight = window.innerHeight;
    const footerRect = footer.getBoundingClientRect();
    return viewportHeight - footerRect.top;
  }

  return null;
};
