import React from 'react';
import styled from '@emotion/styled';
import {depth, borderRadius, space, type, colors} from '@workday/canvas-kit-react/tokens';
import {focusRing} from '@workday/canvas-kit-react/common';
import {OverflowTooltip} from '@workday/canvas-kit-react/tooltip';
import {getIconComponent, CanvasIcon} from './utils';

const Heading = styled('h3')({
  ...type.levels.heading.large,
  margin: `${space.l} 0 ${space.s}`,
});

const Group = styled('ul')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  margin: `0 -${space.s}`,
  maxWidth: 'calc(100% + 32px)',
  '&:after': {
    content: '""',
    flexGrow: 1,
  },
});

interface IconPropStyles extends React.HTMLAttributes<HTMLLIElement> {
  isSelected: boolean;
}

const iconSelectedStyle = {
  outline: 'none',
  ...focusRing(),
  '.wd-icon-fill, .wd-icon-fill, .wd-icon-fill, .wd-icon-accent, .wd-icon-accent, .wd-icon-accent':
    {
      fill: colors.blueberry500,
    },
};

const Icon = styled('button')<IconPropStyles>(
  {
    textAlign: 'center',
    cursor: 'pointer',
    transition: 'background-color 0.1s ease-out, box-shadow 0.2s',
    color: '#4a4a4a',
    padding: '20px',
    margin: space.xs,
    width: '20%',
    boxSizing: 'border-box',
    textDecoration: 'none',
    borderRadius: borderRadius.l,
    border: 0,
    background: 'transparent',
    lineHeight: '24px',
    '> span': {
      ...type.levels.subtext.large,
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    '&:focus': {...iconSelectedStyle},
  },
  ({isSelected}) =>
    isSelected
      ? {
          ...iconSelectedStyle,
        }
      : {
          '&:hover, &:active': {
            ...depth[3],
            textDecoration: 'none',

            '.wd-icon-fill, .wd-icon-fill, .wd-icon-fill, .wd-icon-accent, .wd-icon-accent, .wd-icon-accent':
              {
                fill: colors.blueberry500,
              },
          },
        }
);

const IconGroup = ({
  category,
  icons,
  selectedIcon,
  handleIconButtonClickEvent,
}: {
  category?: string;
  icons: CanvasIcon[];
  selectedIcon: CanvasIcon;
  handleIconButtonClickEvent: (e: React.MouseEvent, icon: CanvasIcon) => void;
}) => (
  <div>
    {category && <Heading>{category}</Heading>}
    <Group>
      {icons.map((icon, index) => {
        const IconComponent = getIconComponent(icon);
        const isSelected = selectedIcon === icon;

        return (
          <OverflowTooltip placement="bottom" key={icon.name}>
            <Icon
              isSelected={isSelected}
              className="icon-tile"
              aria-label={`${icon.name} icon`}
              key={index}
              title={icon.name}
              tabIndex={0}
              onClick={e => {
                handleIconButtonClickEvent(e, icon);
              }}
            >
              <div>
                <IconComponent icon={icon} />
              </div>
              <span>{icon.name}</span>
            </Icon>
          </OverflowTooltip>
        );
      })}
    </Group>
  </div>
);

export default IconGroup;
