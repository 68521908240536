import React from 'react';
import styled from '@emotion/styled';

import {colors, space, type} from '@workday/canvas-kit-react/tokens';
import {extLinkIcon} from '@workday/canvas-system-icons-web';

import {TertiaryButtonLink} from './Link';
import {Media} from '../Media';
import {fullCKVersion} from '../../utils/packages';
import {useSiteMetadata} from '../../utils/useSiteMetadata';

export interface PackageInfoProps {
  /**
   * The Figma URL associated with the content.
   */
  figma?: string;
  /**
   * The GitHub URL associated with the content.
   */
  github?: string;
  /**
   * Whether or not PackageInfo is being rendered in the preamble (affects the
   * styling of elements in PackageInfo).
   */
  inPreamble?: boolean;
  /**
   * The name of the software package (e.g., NPM package) associated with the
   * content.
   */
  package?: string;
  /**
   * The Storybook URL associated with the content.
   */
  storybook?: string;
  /**
   * An object used to scope the metadata to the Web platform. Accepts the same
   * top level props that PackageInfo supports (e.g., `figma`, `github`,
   * `package`, etc.).
   */
  web?: PackageInfoProps;
}

const Row = styled('div')({
  display: 'flex',
  alignItems: 'center',
  columnGap: space.s,
  marginBottom: space.xxs,
});
const Label = styled('div')({
  ...type.levels.body.small,
  ...type.variants.hint,
});

const SourceLink = ({href, children}: {href: string; children: React.ReactNode}) => (
  <TertiaryButtonLink href={href} icon={extLinkIcon} target="_blank">
    {children}
  </TertiaryButtonLink>
);

export const PackageInfo = ({
  github,
  inPreamble = false,
  package: pkg,
  storybook,
  figma,
  web,
}: PackageInfoProps) => {
  // TODO: Remove this and just use figma if we make our figma libraries public
  const config = useSiteMetadata();
  const visibleFigma = config.internal && figma;

  // Not a huge fan of the inPreamble prop, but styling links and icons is a
  // pain and I can't think of a better way to do this (we need to override the
  // default link and icon colors for source links in the PackageInfo since
  // they don't pass contrast)
  const inPreambleStyles = inPreamble && {
    a: {color: colors.blueberry500},
    '.wd-icon-fill, .wd-icon-accent': {
      fill: `${colors.blueberry500} !important`,
    },
  };

  const generateComponent = (size: 's' | 'greaterThanS') => {
    const showLabels = size === 'greaterThanS';
    const versionedGH = `https://github.com/Workday/canvas-kit/tree/${fullCKVersion()}`;
    return (
      // package-info class is used by AnchorNav in case AnchorNav needs to
      // align itself with a PackageInfo at the top of the content.
      <div className="package-info" css={{marginTop: space.l, ...inPreambleStyles}}>
        {(github || storybook || visibleFigma || web) && (
          <Row>
            {showLabels && <Label>Sources</Label>}
            {github && <SourceLink href={`${versionedGH}${github}`}>GitHub</SourceLink>}
            {web && web.github && (
              <SourceLink href={`${versionedGH}${web.github}`}>Web GitHub</SourceLink>
            )}
            {storybook && <SourceLink href={storybook}>Storybook</SourceLink>}
            {web && web.storybook && <SourceLink href={web.storybook}>Web Storybook</SourceLink>}
            {visibleFigma && <SourceLink href={visibleFigma}>Figma</SourceLink>}
          </Row>
        )}
        {pkg && (
          <Row>
            {showLabels && <Label>Install</Label>}
            <code>yarn add {pkg}</code>
          </Row>
        )}
        {web && web.package && (
          <Row>
            {showLabels && <Label>Web Install</Label>}
            <code>yarn add {web.package}</code>
          </Row>
        )}
      </div>
    );
  };

  return (
    <>
      <Media at="s">{generateComponent('s')}</Media>
      <Media greaterThan="s">{generateComponent('greaterThanS')}</Media>
    </>
  );
};

export default PackageInfo;
