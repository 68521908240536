import React from 'react';
import lottie, {AnimationItem, AnimationConfigWithData} from 'lottie-web';

export interface AnimatedIllustrationProps {
  data: object;
}

const prefersReducedMotion = () => !!window.matchMedia('(prefers-reduced-motion: reduce)').matches;

export const AnimatedIllustration = ({data, ...elemProps}: AnimatedIllustrationProps) => {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const animationRef = React.useRef<AnimationItem>();

  React.useEffect(() => {
    if (containerRef.current && data) {
      const params: AnimationConfigWithData = {
        container: containerRef.current,
        renderer: 'svg',
        loop: true,
        autoplay: false,
        animationData: data,
      };

      animationRef.current = lottie.loadAnimation(params);
    }
  }, []);

  const playAnimation = () => {
    if (!animationRef.current || prefersReducedMotion()) {
      return;
    }
    animationRef.current.setSpeed(0.5); // Slow down to provide easing for 300ms before playing at full speed
    animationRef.current.play();
    setTimeout(() => animationRef.current?.setSpeed(1), 300);
  };

  const pauseAnimation = () => {
    if (!animationRef.current || prefersReducedMotion()) {
      return;
    }
    animationRef.current.setSpeed(0.4); // Slow down to provide easing before pausing in 300ms
    setTimeout(() => animationRef.current?.pause(), 300);
  };

  return (
    <div
      ref={containerRef}
      onMouseEnter={playAnimation}
      onMouseLeave={pauseAnimation}
      {...elemProps}
    ></div>
  );
};

export default AnimatedIllustration;
