import React from 'react';
import {colors} from '@workday/canvas-kit-react/tokens';
import {SystemIconCircle} from '@workday/canvas-kit-react/icon';
import {activityStreamIcon} from '@workday/canvas-system-icons-web';

export default () => (
  <>
    <SystemIconCircle icon={activityStreamIcon} />
    <SystemIconCircle icon={activityStreamIcon} background={colors.pomegranate500} />
  </>
);
