import React from 'react';
import styled, {CSSObject} from '@emotion/styled';
import {saveSvgAsPng} from 'save-svg-as-png';
import FileSaver from 'file-saver';

import {iconColors} from '@workday/canvas-colors-web';
import {downloadIcon} from '@workday/canvas-system-icons-web';
import {TertiaryButton} from '@workday/canvas-kit-react/button';
import {accessibleHide, Model} from '@workday/canvas-kit-react/common';
import {space, borderRadius, type} from '@workday/canvas-kit-react/tokens';
import {
  Popup,
  useCloseOnEscape,
  useCloseOnOutsideClick,
  useInitialFocus,
  useReturnFocus,
  useFocusRedirect,
  PopupState,
  PopupEvents,
} from '@workday/canvas-kit-react/popup';
import {CanvasIcon} from '@workday/design-assets-types';
import {getPixelRatio, getIconComponent} from './utils';
import {contentHSpace, maxContentWidth, mqAt, sidebarWidth} from '../../../utils/breakpoints';
import {calcFullWidth} from '../../../utils/style';

const colorIcon = (iconWrapper: HTMLDivElement) => {
  const colorClasses = [
    ['wd-icon-fill', iconColors.standard],
    ['wd-icon-accent', iconColors.standard],
    ['wd-icon-background', 'transparent'],
  ];

  colorClasses.forEach(([className, color]) => {
    Array.from(iconWrapper.getElementsByClassName(className)).forEach(elem => {
      elem.setAttribute('fill', color);
    });
  });

  return iconWrapper;
};

const removeExtension = (filename: string) => filename.replace(/\.[^/.]+$/, '');

const createDownloadableIcon = (icon: CanvasIcon) => {
  const svgWrapper = document.createElement('div');
  svgWrapper.innerHTML = icon.svg;
  return colorIcon(svgWrapper);
};

const downloadSVG = (icon: CanvasIcon) => {
  const downloadableIcon = createDownloadableIcon(icon);
  const blob = new Blob([downloadableIcon.innerHTML], {
    type: 'image/svg+xml',
  });

  FileSaver.saveAs(blob, `${removeExtension(icon.filename)}.svg`);
};

const downloadPNG = (icon: CanvasIcon) => {
  const downloadableIcon = createDownloadableIcon(icon);

  const params = {
    scale: getPixelRatio(),
  };

  saveSvgAsPng(downloadableIcon.firstChild, `${removeExtension(icon.filename)}.png`, params);
};

const getIconSize = (icon: CanvasIcon) => {
  switch (icon.type) {
    case 'applet':
      return 92;
    case 'accent':
      return 56;
    case 'system':
    default:
      return 24;
  }
};

const IconBG = styled('div')({
  backgroundImage:
    'url("data:image/svg+xml,%3Csvg%20width%3D%228%22%20height%3D%228%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cpath%20fill%3D%22%23F0F1F2%22%20d%3D%22M4%204h4v4H4z%22%2F%3E%3Cpath%20fill%3D%22%23FFF%22%20d%3D%22M0%204h4v4H0zM4%200h4v4H4z%22%2F%3E%3Cpath%20fill%3D%22%23F0F1F2%22%20d%3D%22M0%200h4v4H0z%22%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E")',
  backgroundRepeat: 'repeat',
  width: 80,
  height: 80,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: `${borderRadius.l} 0 0 ${borderRadius.l}`,
});

const IconText = styled('div')({
  flexGrow: 1,
  padding: `${space.xxs} ${space.m}`,
  h4: {
    ...type.levels.body.small,
    fontWeight: 700,
  },

  div: {
    ...type.levels.subtext.large,
    ...type.variants.hint,
    marginTop: space.xxxs,
  },
});

const Abbr = styled('abbr')({
  textDecoration: 'none',
});

export const SelectedIconPopup = ({
  model,
  icon,
}: {
  model: Model<PopupState, PopupEvents>;
  icon: CanvasIcon | null;
}) => {
  // No document, so can't use document.body for portal
  if (typeof window === 'undefined') {
    return null;
  }

  const IconComponent = icon && getIconComponent(icon);

  useCloseOnOutsideClick(model);
  useCloseOnEscape(model);
  useInitialFocus(model);
  useReturnFocus(model);
  useFocusRedirect(model);

  const popupCardStyles: CSSObject = {
    // fixed position and maxWidth need to be applied via the css prop rather
    // than the style props of Popup.Card in order to override the default
    // styles of Popup.Card
    // TODO: Investigate whether or not this is true
    position: 'fixed',
    maxWidth: maxContentWidth,

    [mqAt('s')]: {
      left: contentHSpace.s,
      width: calcFullWidth(contentHSpace.s),
    },
    [mqAt('m')]: {
      left: contentHSpace.m,
      width: calcFullWidth(contentHSpace.m),
    },
    [mqAt('l')]: {
      left: sidebarWidth + contentHSpace.l,
      width: calcFullWidth(contentHSpace.l, sidebarWidth),
    },
    [mqAt('xl')]: {
      left: sidebarWidth + contentHSpace.xl,
      width: maxContentWidth,
    },
    [mqAt('xxl')]: {
      left: sidebarWidth + contentHSpace.xxl,
      width: maxContentWidth,
    },
  };

  return (
    <Popup model={model}>
      <Popup.Popper>
        <Popup.Card
          bottom={space.l}
          height={82}
          padding={0}
          aria-label={`Download ${icon ? icon.filename : ''} icons`}
          css={popupCardStyles}
        >
          {icon && (
            <div css={{display: 'flex', alignItems: 'center', button: {marginRight: space.xxs}}}>
              <IconBG>
                <IconComponent icon={icon} />
              </IconBG>
              <IconText>
                <h4>{icon.filename}</h4>
                <div>
                  <Abbr title="Scalable Vector Graphic">SVG</Abbr> {getIconSize(icon)}
                  <Abbr title="Pixel">px</Abbr>
                </div>
              </IconText>
              <TertiaryButton onClick={() => downloadSVG(icon)} icon={downloadIcon}>
                <span css={accessibleHide}>Download </span>
                <Abbr title="Scalable Vector Graphic">SVG</Abbr>
              </TertiaryButton>
              <TertiaryButton onClick={() => downloadPNG(icon)} icon={downloadIcon}>
                <span css={accessibleHide}>Download </span>
                <Abbr title="Portable Network Graphic">PNG</Abbr>
              </TertiaryButton>
              <Popup.CloseIcon
                aria-label="Close"
                // Override default styles of Popup.CloseIcon
                css={{position: 'static', top: 'auto', right: 'auto'}}
              />
            </div>
          )}
        </Popup.Card>
      </Popup.Popper>
    </Popup>
  );
};

export default SelectedIconPopup;
