import React from 'react';
import {useStaticQuery, graphql} from 'gatsby';
import {StatusIndicator} from '@workday/canvas-kit-react/status-indicator';
import {checkIcon} from '@workday/canvas-system-icons-web';
import {colors} from '@workday/canvas-kit-react/tokens';
import {TabbedContent, TabsData} from '../TabbedContent';

const spaceData: TabsData = {
  zero: {
    title: 'Space Zero',
    description: '0 px',
    status: [{type: StatusIndicator.Type.Gray, label: 'Less Common'}],
    notes: [
      {title: 'Use sparingly'},
      {title: 'When elements are stacked and require no space between them'},
      {title: 'Rows of data e.g. tables'},
    ],
  },
 x1: {
    title: 'Space x1',
    description: '4 px',
    status: [{type: StatusIndicator.Type.Gray, label: 'Less Common'}],
    notes: [
      {title: 'Use sparingly'},
      {title: 'Accommodate a large amount of data on your page'},
      {title: 'Compact spacing between text or icons'},
    ],
  },
  x2: {
    title: 'Space x2',
    description: '8 px',
    status: [{type: StatusIndicator.Type.Blue, label: 'Common'}],
    notes: [
      {title: 'Commonly used to group compact elements like icon buttons'},
      {title: 'Accommodate a large amount of data on your page'},
      {title: 'Compact spacing between text or icons'},
    ],
  },
  x3: {
    title: 'Space x3',
    description: '12 px',
    status: [{type: StatusIndicator.Type.Gray, label: 'Less Common'}],
    notes: [
      {title: 'Use sparingly'},
      {title: 'Use when compact padding is required'},
      {title: 'Accommodate a large amount of data on your page'},
    ],
  },
  x4: {
    title: 'Space x4',
    description: '16 px',
    status: [
      {type: StatusIndicator.Type.Green, label: 'Base Value'},
      {type: StatusIndicator.Type.Blue, label: 'Common'},
    ],
    notes: [
      {
        title:
          'Use this as your base to help you decide on relevant larger or smaller spacing sizes',
      },
      {title: 'Grouping Inputs with related data'},
      {title: 'Group related input fields'},
    ],
  },
  x6: {
    title: 'Space x6',
    description: '24 px',
    status: [{type: StatusIndicator.Type.Blue, label: 'Common'}],
    notes: [
      {title: 'Padding around card content'},
      {
        title: 'Related elements where more space between them can be afforded',
      },
      {
        title: 'Separate section headings or titles from body text or inputs',
      },
    ],
  },
  x8: {
    title: 'Space x8',
    description: '32 px',
    status: [{type: StatusIndicator.Type.Blue, label: 'Common'}],
    notes: [
      {title: 'Standard spacing between cards'},
      {title: 'Used to separate groups of content'},
      {
        title: 'Separate section headings or titles from body text or inputs',
      },
    ],
  },
  x10: {
    title: 'Space x10',
    description: '40 px',
    status: [{type: StatusIndicator.Type.Blue, label: 'Common'}],
    notes: [
      {title: 'Used for outer margins on the overall page content'},
      {title: 'Used for inner margins on large items such as page sections'},
    ],
  },
  x14: {
    title: 'Space x14',
    description: '64 px',
    status: [{type: StatusIndicator.Type.Blue, label: 'Common'}],
    notes: [
      {title: 'Use to de-clutter your UI when alot of space is available'},
      {title: 'Separate banner sections from page content'},
      {title: 'Use to differentiate page content like page sections'},
    ],
  },
  x20: {
    title: 'Space x20',
    description: '80 px',
    status: [{type: StatusIndicator.Type.Gray, label: 'Less Common'}],
    notes: [
      {title: 'Use sparingly'},
      {title: 'Helps to put focus on the primary element within your page'},
      {title: 'Use to de-clutter your UI when a lot of space is available'},
    ],
  },
};

export const SpaceUsageTabs = () => {
  const {
    allFile: {edges: images},
  } = useStaticQuery(graphql`
    query {
      allFile(filter: {relativePath: {regex: "/^space-guide/"}}) {
        edges {
          node {
            name
            relativePath
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
  `);

  return (
    <TabbedContent
      tabsData={spaceData}
      images={images}
      notesIconSettings={{icon: checkIcon, color: colors.greenApple400}}
    />
  );
};
