import React from 'react';
import * as GraphicIcons from '@workday/canvas-graphics-web';
import styled from '@emotion/styled';
import {space, type} from '@workday/canvas-kit-react/tokens';

const Group = styled('ul')({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  marginTop: space.l,
});

const Illustration = styled('div')({
  width: '48%',
  textAlign: 'center',
  marginBottom: space.l,

  svg: {
    width: '100%',
  },
});

export const EmptyStateList = () => {
  const legacyEmptyStates = [
    'emptyStateGraphic',
    'emptyStateDocGraphic',
    'emptyStateEndpointGraphic',
    'emptyStateExcelGraphic',
    'emptyStateGenericGraphic',
    'emptyStateImageGraphic',
    'emptyStatePdfGraphic',
    'emptyStatePptGraphic',
    'emptyStateReportGraphic',
    'emptyStateSavedSearchGraphic',
    'emptyStateVideoGraphic',
    'emptyStateWidgetGraphic',
    'emptyStateWorkbookGraphic',
  ];

  const emptyStates = Object.keys(GraphicIcons)
    .filter(key => key.includes('emptyState'))
    .filter(key => !legacyEmptyStates.includes(key))
    .sort()
    .reduce((obj, key) => {
      obj[key] = GraphicIcons[key];
      return obj;
    }, {});

  return (
    <Group>
      {Object.keys(emptyStates).map(key => (
        <Illustration key={key}>
          <div dangerouslySetInnerHTML={{__html: emptyStates[key].svg}} />
          <div css={type.levels.subtext.large}>{emptyStates[key].name}</div>
        </Illustration>
      ))}
    </Group>
  );
};

export default EmptyStateList;
