import React from 'react';
import {getElementTop} from '../../utils/htmlElements';

const getScrollPos = () => {
  return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
};

export interface AbsoluteToFixedProps {
  /**
   * As the user scrolls down the page, the AbsoluteToFixed container will
   * switch to fixed positioning when the vertical distance between its top
   * edge and the top edge of the browser viewport equals `offset` (and revert
   * back to absolute positioning when that distance exceeds `offset` if they
   * scroll back up).
   */
  offset: number;
  children: React.ReactNode;
}

export const fixedThresholdMet = (scrollPos: number, offset: number, top: number) => {
  return scrollPos + offset > top;
};

const AbsoluteToFixed = ({children, offset, ...props}: AbsoluteToFixedProps) => {
  const [originalTop, setOriginalTop] = React.useState<number>(0);
  const [fixed, setFixed] = React.useState<boolean>(false);
  const [scrollPos, setScrollPos] = React.useState<number>(0);
  const containerRef = React.useRef<HTMLDivElement>(null);

  const handleScroll = () =>
    requestAnimationFrame(() => {
      setScrollPos(getScrollPos());
    });

  React.useEffect(() => {
    setScrollPos(getScrollPos());
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  if (containerRef.current) {
    if (fixed) {
      if (!fixedThresholdMet(scrollPos, offset, originalTop)) {
        setFixed(false);
      }
    } else {
      const top = getElementTop(containerRef.current);
      if (fixedThresholdMet(scrollPos, offset, top)) {
        setOriginalTop(top);
        setFixed(true);
      }
    }
  }

  const style = {
    position: fixed ? 'fixed' : 'absolute',
    top: fixed ? offset : undefined,
  } as const;

  return (
    <div ref={containerRef} style={style} {...props}>
      {children}
    </div>
  );
};

export default AbsoluteToFixed;
