import React from 'react';
import {accessibleHide} from '@workday/canvas-kit-react/common';

interface StatusRegionContextObject {
  announceAssertive: (message: string) => void;
  announcePolite: (message: string) => void;
}

const StatusRegionContext = React.createContext<StatusRegionContextObject | undefined>(undefined);

interface StatusRegionProviderProps {
  children: React.ReactNode;
}

let clearMesssageTimeout: ReturnType<typeof setTimeout>;
const clearMessageTimeoutDelay = 1000;
const announceAndClear = (
  message: string,
  setMessage: React.Dispatch<React.SetStateAction<string>>
) => {
  setMessage(message);

  // Clear the live region message after a 1 second delay as per a11y
  // requirements
  if (clearMesssageTimeout) {
    clearTimeout(clearMesssageTimeout);
  }
  clearMesssageTimeout = setTimeout(() => {
    setMessage('');
  }, clearMessageTimeoutDelay);
};

export const StatusRegionProvider = ({children}: StatusRegionProviderProps) => {
  const [assertiveMessage, setAssertiveMessage] = React.useState('');
  const [politeMessage, setPoliteMessage] = React.useState('');

  const announceAssertive = (message: string) => {
    announceAndClear(message, setAssertiveMessage);
  };

  const announcePolite = (message: string) => {
    announceAndClear(message, setPoliteMessage);
  };

  return (
    <StatusRegionContext.Provider value={{announceAssertive, announcePolite}}>
      {children}
      <div aria-atomic="true" aria-live="polite" css={accessibleHide} role="status">
        {politeMessage}
      </div>
      <div aria-atomic="true" aria-live="assertive" css={accessibleHide}>
        {assertiveMessage}
      </div>
    </StatusRegionContext.Provider>
  );
};

export const useStatusRegion = () => {
  const context = React.useContext(StatusRegionContext);
  if (context === undefined) {
    throw new Error('useStatusRegion must be used within a StatusRegionProvider');
  }
  return context;
};
