// TODO: Consider replacing Fuse.js with whatever we use for site-wide search.
import Fuse from 'fuse.js';
import {CanvasIcon} from '@workday/design-assets-types';
import * as accentIconList from '@workday/canvas-accent-icons-web';
import * as systemIconList from '@workday/canvas-system-icons-web';
import * as appletIconList from '@workday/canvas-applet-icons-web';
import {SystemIcon, AccentIcon, AppletIcon} from '@workday/canvas-kit-react/icon';

export type IconType = 'wd-system-icons' | 'wd-accent-icons' | 'wd-applet-icons';

export type IconSet = typeof accentIconList | typeof systemIconList | typeof appletIconList;

export type GroupedIcons<T extends CanvasIcon> = {
  [category: string]: T;
};

export const getIconList = (iconType: IconType): IconSet => {
  switch (iconType) {
    case 'wd-system-icons':
      return systemIconList;
    case 'wd-applet-icons':
      return appletIconList;
    case 'wd-accent-icons':
      return accentIconList;
    default:
      return systemIconList;
  }
};

export const getIconCategories = (icons: IconSet) => {
  const categories = {};

  icons.forEach(icon => {
    let category = icon.category;

    if (!category) category = 'All';

    if (categories[category]) {
      categories[category].push(icon);
    } else {
      categories[category] = [icon];
    }
  });

  return categories;
};

export const getIconComponent = (icon: CanvasIcon) => {
  switch (icon.type) {
    case 'applet':
      return AppletIcon;
    case 'accent':
      return AccentIcon;
    case 'system':
    default:
      return SystemIcon;
  }
};

export const searchIcons = (query: string, icons: IconSet): {[category: string]: IconSet} => {
  const iconList = [];

  // convert to array
  for (const icon in icons) {
    if (icons[icon].filename) {
      iconList.push(icons[icon]);
    }
  }

  if (query.length === 0) {
    return getIconCategories(iconList);
  }

  const options = {
    shouldSort: true,
    threshold: 0.1,
    location: 0,
    distance: 100,
    maxPatternLength: 32,
    minMatchCharLength: 1,
    keys: ['name', 'filename', 'category', 'tags'],
  };

  const fuse = new Fuse(iconList, options);
  const result = fuse.search(query).map(result => result.item);
  const groupedIcons = getIconCategories(result);

  return groupedIcons;
};

export const getPixelRatio = () => {
  const mediaQueries = [
    '(-webkit-min-device-pixel-ratio: 1.25), (min--moz-device-pixel-ratio: 1.25), (-o-min-device-pixel-ratio: 5/4), (min-resolution: 1.25dppx)',
    '(-webkit-min-device-pixel-ratio: 2.25), (min--moz-device-pixel-ratio: 2.25), (-o-min-device-pixel-ratio: 9/4), (min-resolution: 2.25dppx)',
    '(-webkit-min-device-pixel-ratio: 3.25), (min--moz-device-pixel-ratio: 3.25), (-o-min-device-pixel-ratio: 13/4), (min-resolution: 3.25dppx)',
  ];

  if (
    // 4x
    window.devicePixelRatio > 3.25 ||
    window.matchMedia(mediaQueries[2]).matches
  ) {
    return 0.25;
  } else if (
    // 3x
    window.devicePixelRatio > 2.25 ||
    window.matchMedia(mediaQueries[1]).matches
  ) {
    return 0.33;
  } else if (
    // 2x
    window.devicePixelRatio > 1.25 ||
    window.matchMedia(mediaQueries[0]).matches
  ) {
    return 0.5;
  } else return 1; // 1x
};
