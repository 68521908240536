import React from 'react';
import {
  useListItemRegister,
  useListItemSelect,
  useListModel,
  ListItemProps,
  ListBox,
} from '@workday/canvas-kit-react/collection';
import {
  composeHooks,
  createElemPropsHook,
  createSubcomponent,
  createContainer,
  ExtractProps,
  focusRing,
} from '@workday/canvas-kit-react/common';
import {colors} from '@workday/canvas-kit-react/tokens';
import {BaseButton} from '@workday/canvas-kit-react/button';
import {Flex} from '@workday/canvas-kit-react/layout';
import {Pill} from '@workday/canvas-kit-preview-react/pill';

const useItem = composeHooks(
  createElemPropsHook(useListModel)((model, ref, elemProps: ListItemProps) => {
    const isSelected = model.state.selectedIds.includes(elemProps['data-id']);

    return {
      colors: {
        default: {
          background: isSelected ? colors.blueberry100 : colors.soap100,
          border: isSelected ? colors.blueberry500 : colors.licorice200,
          icon: colors.blackPepper400,
          color: isSelected ? colors.frenchVanilla100 : colors.blackPepper400,
          label: colors.blackPepper400,
        },
        hover: {
          background: isSelected ? colors.blueberry200 : colors.soap400,
          border: isSelected ? colors.blueberry600 : colors.licorice500,

          label: colors.blackPepper400,
        },
        active: {
          background: isSelected ? colors.blueberry300 : colors.soap600,
          border: isSelected ? colors.blueberry500 : colors.licorice500,

          label: isSelected ? colors.frenchVanilla100 : colors.blackPepper400,
        },
        focus: {
          background: isSelected ? colors.blueberry100 : colors.soap100,
          icon: colors.blackPepper400,
          label: colors.blackPepper400,
          focusRing: focusRing({
            width: 0,
            inset: 'inner',
            innerColor: colors.blueberry500,
            outerColor: colors.blueberry400,
            separation: 1,
          }),
        },

        disabled: {
          background: 'transparent',
          border: colors.blackPepper400,
          icon: colors.blackPepper400,
          label: colors.blackPepper400,
        },
      },
    };
  }),
  useListItemSelect,
  useListItemRegister
);

const SelectableItem = createSubcomponent('button')({
  displayName: 'SelectableItem',
  modelHook: useListModel,
  elemPropsHook: useItem,
})<ListItemProps>(({children, ...elemProps}, Element, model) => {
  return (
    <Pill
      as={BaseButton}
      {...elemProps}
      style={{
        boxShadow: model.state.selectedIds.includes(elemProps['data-id'])
          ? `inset 0 0 0 1px ${colors.blueberry500}, 0 0 0 0px ${colors.blueberry400}`
          : undefined,
      }}
      aria-pressed={model.state.selectedIds.includes(elemProps['data-id']) ? true : false}
    >
      <Pill.Label fontWeight={400}>{children}</Pill.Label>
    </Pill>
  );
});

export const SelectionGroup = createContainer(Flex)({
  displayName: 'SelectionGroup',
  modelHook: useListModel,
  subComponents: {
    Item: SelectableItem,
  },
})<ExtractProps<typeof ListBox, never>>(({children, ...elementProps}, Element, model) => (
  <ListBox
    model={model}
    as={Element}
    flexDirection="row"
    role="group"
    aria-label="platforms"
    padding="xxs"
    gap="xxs"
    {...elementProps}
  >
    {children}
  </ListBox>
));
