/**
 * Ensure sessionStorage is a feature of the browser
 */
const hasStorage = () => {
  try {
    sessionStorage.setItem('test_storage::internal', 'test');
    sessionStorage.removeItem('test_storage::internal');
    return true;
  } catch (e) {
    return false;
  }
};

export const lastPatternTypeKey = 'CanvasSiteLastPatternType';

/**
 * Store desired data in sessionStorage
 *
 * @param {string} key name to store data with
 * @param {any} data will be stringified and stored
 */
export const persistToSessionStorage = (key: string, data: any) => {
  if (hasStorage()) {
    sessionStorage.setItem(key, JSON.stringify(data));
  }
};

/**
 * Retrieve and parse data from sessionStorage
 *
 * @param {string} key data to retrieve and parse
 */
export const restoreFromSessionStorage = (key: string) => {
  if (hasStorage()) {
    try {
      return JSON.parse(sessionStorage.getItem(key) || '');
    } catch (e) {
      return;
    }
  }
};
