import React from 'react';
import {space} from '@workday/canvas-kit-react/tokens';

export interface WistiaVideoProps {
  id: string;
}

export const WistiaVideo = ({id, ...elemProps}: WistiaVideoProps) => {
  // const swatchRef = React.useRef(null);

  // // TODO: Tie this in to cookie banner
  // React.useEffect(() => {
  //   // eslint-disable-next-line mdx/no-unused-expressions
  //   window._wq &&
  //     window._wq.push(function (W) {
  //       W.consent(true);
  //     });
  // });

  // TODO: Use Wistia JS Embed

  // return (
  //   <>
  //     <script src={`https://fast.wistia.com/embed/medias/${id}.jsonp`} async></script>
  //     <script src="https://fast.wistia.com/assets/external/E-v1.js" async></script>
  //     <div
  //       className="wistia_responsive_padding"
  //       style={{padding: '56.25% 0 0 0', position: 'relative'}}
  //     >
  //       <div
  //         className="wistia_responsive_wrapper"
  //         style={{height: '100%', left: 0, position: 'absolute', top: 0, width: '100%'}}
  //       >
  //         <div
  //           className="wistia_embed wistia_async_hpy6mj7toj videoFoam=true"
  //           style={{height: '100%', position: 'relative', width: '100%'}}
  //         >
  //           <div
  //             className="wistia_swatch"
  //             style={{
  //               height: '100%',
  //               left: 0,
  //               opacity: 0,
  //               overflow: 'hidden',
  //               position: 'absolute',
  //               top: 0,
  //               transition: 'opacity 200ms',
  //               width: '100%',
  //             }}
  //             ref={swatchRef}
  //           >
  //             <img
  //               src={`https://fast.wistia.com/embed/medias/${id}/swatch`}
  //               style={{filter: 'blur(5px)', height: '100%', objectFit: 'contain', width: '100%'}}
  //               alt=""
  //               aria-hidden="true"
  //               onLoad={() => (swatchRef.current.style.opacity = 1)}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );

  return (
    <>
      <div
        className="wistia_responsive_padding"
        css={{
          padding: '56.25% 0 0 0',
          marginTop: space.l,
          marginBottom: space.l,
          position: 'relative',
        }}
        {...elemProps}
      >
        <div
          className="wistia_responsive_wrapper"
          style={{height: '100%', left: 0, position: 'absolute', top: 0, width: '100%'}}
        >
          <iframe
            src={`https://fast.wistia.net/embed/iframe/${id}?videoFoam=true`}
            title="Playgrounds explained Video"
            allow="autoplay; fullscreen"
            allowTransparency={true}
            frameBorder="0"
            scrolling="no"
            className="wistia_embed"
            name="wistia_embed"
            allowFullScreen
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
      <script src="https://fast.wistia.net/assets/external/E-v1.js" async></script>
    </>
  );
};
