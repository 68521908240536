import React from 'react';
import slugify from 'slugify';
import queryString from 'query-string';
import styled from '@emotion/styled';

import {Tabs as CanvasTabs, useTabsModel} from '@workday/canvas-kit-react/tabs';

import AnchorNav from './AnchorNav';
import {Media} from '../Media';
import {getElementTop} from '../../utils/htmlElements';
import {getFragmentValue, getHeadingLink, setFragmentTabValue} from '../../utils/urlFragment';
import {usePlatformSwitcher} from '../PlatformSwitcher';

import {headerHeight, headerPreambleContentGap} from '../../utils/breakpoints';

export interface TabsProps {
  /**
   * The minimum number of qualifying headings in a TabPanel's content in order
   * for the AnchorNav to be displayed for that TabPanel. Passed in via
   * frontmatter and forwarded to the AnchorNav component.
   */
  anchorNavDepth?: number;
  /**
   * The TabPanel children (hard to enforce this given lack of type-checking in
   * MDX where the children will be defined). There should be one TabPanel for
   * each tab name in `tabNames` (with its `data-id` prop set to the
   * corresponding tab name).
   */
  children: JSX.Element | JSX.Element[];
  /**
   * An array of the tab names.
   */
  tabNames: string[];
}

/**
 * An object used to map tab slugs to their full names (e.g.,
 * "related-guidelines" to "Related Guidelines").
 */
type TabSlugsMap = {
  [slug: string]: string;
};

/**
 * We need to define TabsListContainer to serve as the sticky item so the
 * sticky container ends up being ContentBody. Applying `sticky` to Tabs.List
 * doesn't work because elemProps are spread onto an inner element which is
 * the sole child of a container element (TabsListContainer) that we don't have
 * access to.
 *
 * Reference: https://elad.medium.com/css-position-sticky-how-it-really-works-54cd01dc2d46
 */
const TabsListContainer = styled('div')({
  position: 'sticky',
  top: headerHeight + headerPreambleContentGap,
  // Required to prevent images in the MDX from displaying on top of the tabs
  // as you scroll
  zIndex: 1,
});

/**
 * Helper to convert a tab name to its slug.
 */
const slugifyTabName = (tabName: string) => {
  return slugify(tabName, {lower: true});
};

/**
 * Generates a map of tab slugs to their full names so tabs can be selected
 * via the tab slug in the URL fragment.
 */
const mapTabSlugs = (tabNames: string[]) => {
  const tabSlugsMap: TabSlugsMap = {};

  tabNames.forEach(tabName => {
    tabSlugsMap[slugifyTabName(tabName)] = tabName;
  });

  return tabSlugsMap;
};

/**
 * Scrolls to the top of tab panel. Useful for scrolling to the top of a newly
 * selected tab panel.
 *
 * TODO: Relies on a very specific DOM structure, re-think this.
 */
const scrollTabPanel = () => {
  // Without rAF, this code to scroll to the top of the tab panel didn't always
  // work when switching tabs in Firefox. I never traced the exact cause, but
  // the issue primarily occurred when scrolling to the bottom of a lengthy tab
  // and then switching to another tab -- instead of being taken to the top of
  // the new tab's contents, you would be stuck looking at the bottom of the
  // new tab's contents.
  requestAnimationFrame(() => {
    // Obtain a reference to ContentBody (the element whose top position we need
    // to reference in order to scroll to the top of a newly selected tab panel)
    const scrollReference =
      document.querySelector('[role="tablist"]')?.parentElement?.parentElement?.parentElement;

    if (scrollReference) {
      // Apply an offset to ensure there's whitespace between the tabs and the
      // topmost content of the tab panel. Hardcoding this offset to 64 (the top
      // margin of the first heading in the tab panel) for now.
      // TODO: Fix this later when we clean this logic up.
      const tabScrollPos = getElementTop(scrollReference) - 64;

      if (tabScrollPos < document.documentElement.scrollTop) {
        window.scrollTo({
          left: 0,
          top: tabScrollPos,
        });
      }
    }
  });
};

/**
 * Select the tab encoded in the URL fragment (e.g., `#tab=examples`).
 */
const selectTabFromFragment = (
  tabNames: string[],
  tabSlugsMap: TabSlugsMap,
  model: ReturnType<typeof useTabsModel>
) => {
  const tabSlug = getFragmentValue('tab');

  // Default to selecting the first tab in case a tab isn't encoded in the
  // in the fragment, or if the tab value doesn't map to a valid tab.
  const tabNameToSelect =
    tabSlug && tabSlugsMap && tabSlugsMap[tabSlug] ? tabSlugsMap[tabSlug] : tabNames[0];

  model.events.select({id: tabNameToSelect});
};

const Tabs = ({anchorNavDepth, children, tabNames, ...elemProps}: TabsProps) => {
  const [content, setContent] = React.useState<HTMLElement | null>(null);
  const tabSlugsMap = React.useMemo(() => mapTabSlugs(tabNames), [tabNames]);
  const platformSwitcherContext = usePlatformSwitcher();
  const selectedPlatformValue = platformSwitcherContext.platformValue;

  const model = useTabsModel({
    // Always preselect the first tab. Faster SSR
    initialTab: tabNames[0],
    items: tabNames.map(tabName => ({id: tabName, text: tabName})),
  });

  const handlePopState = () => {
    selectTabFromFragment(tabNames, tabSlugsMap, model);
  };

  const handleTabClick = (tabName: string) => {
    // We're deliberately calling setFragmentTabValue in handleTabClick rather
    // than the Tabs model onActivate. onActivate is called when you click on a
    // tab AND when you select a tab programmatically (which we do when
    // activating a tab encoded in the URL fragment when the user hits the
    // browser back button). We do NOT want to set the tab value in the
    // fragment when we select a tab programatically since we would be
    // pushing the tab we just backed into right back on the history. We only
    // want to set the tab value in the fragment when a tab is clicked.
    setFragmentTabValue(slugifyTabName(tabName));
  };

  // Whenever a platform is selected, we update the platform in the URL
  // fragment and auto-select the corresponding tab. Note that this does NOT
  // apply to the `All` platform: if `All` is selected, we do NOT want to
  // auto-select a tab since all platform tabs are relevant to the `All`
  // setting.
  React.useEffect(() => {
    if (selectedPlatformValue && selectedPlatformValue !== 'all') {
      const platformSlugsMap: TabSlugsMap = {
        web: 'Web',
        ios: 'iOS',
        android: 'Android',
      };

      // Check if the tab for the selected platform exists on the page. We don't
      // want to select a tab that doesn't exist.
      if (tabSlugsMap[selectedPlatformValue]) {
        // Set the slug path in the URL
        setFragmentTabValue(slugifyTabName(platformSlugsMap[selectedPlatformValue]));
        // Set the selected Tab
        model.events.select({id: platformSlugsMap[selectedPlatformValue]});
      }
    }
  }, [selectedPlatformValue]);

  // Includes tabSlugsMap in the dependency array to ensure handlePopState has
  // access to the current value of tabSlugsMap
  React.useEffect(() => {
    const tabSlugsMapIsPopulated = Object.keys(tabSlugsMap).length > 0;

    if (tabSlugsMapIsPopulated) {
      // Activate the tab in the URL fragment if tabSlugsMap changed (e.g., if
      // the page just loaded and tabSlugsMap was just populated)
      selectTabFromFragment(tabNames, tabSlugsMap, model);

      // Activate the correct tab as the user navigates through the browser
      // history using the back and forward buttons
      window.addEventListener('popstate', handlePopState);
    }

    return () => {
      if (tabSlugsMapIsPopulated) {
        window.removeEventListener('popstate', handlePopState);
      }
    };
  }, [tabSlugsMap]);

  React.useEffect(() => {
    // Get tab content based on the active tab
    const tabId = document
      .querySelector(`[role=tab][data-id="${model.state.selectedIds[0]}"]`)
      ?.getAttribute('id');
    const tabContent = document.querySelector<HTMLElement>(
      `[role=tabpanel][aria-labelledby="${tabId}"]`
    );

    setContent(tabContent);

    // When the active tab changes, scroll to the top of the tab panel if BOTH
    // of the following are true:
    //
    // 1. There IS a tab in the URL fragment. If there is NO tab in the fragment
    //    (e.g., if we're navigating to a new page entirely), we want to
    //    preserve the default behavior of scrolling to the top of the new
    //    page rather than the top of the new page's first/default tab panel.
    // 2. There is NO heading in the URL fragment. If there IS a heading in the
    //    fragment, let Layout handle the scrolling.
    if (getFragmentValue('tab') && !getFragmentValue('heading')) {
      scrollTabPanel();
    }
  }, [model.state.selectedIds[0]]);

  // Every intra-tab link within a tab panel needs to include the tab in its
  // hash fragment in order for the link to work properly (otherwise,
  // intra-tab links always take you to the first tab). MDX content from
  // canvas-kit-docs doesn't include tabs in links. For example, the upgrade
  // guide links to "#codemod", not "#tab=for-developers&heading=codemod".
  // This code adds the tab portion of the fragment where necessary.
  React.useEffect(() => {
    if (content) {
      const links = content.querySelectorAll('a');
      if (links) {
        links.forEach(link => {
          const hash = link.hash;
          // First, verify we're dealing with an intra-page link (href starts
          // with `#`). We don't want to rewrite the href if the hash is on a
          // link to a different page (href does not start with `#`).
          if (hash && link.getAttribute('href')?.charAt(0) === '#') {
            const parsedHash = queryString.parse(hash);
            // The hash needs to include a tab; if it doesn't, assume the hash
            // is intra-tab and update it to the current tab
            if (!parsedHash.tab) {
              // getHeadingLink will return a full hash with the correct tab
              // for a given heading id (it just needs the heading id without
              // the hash mark, so we chop the mark off)
              const hashWithTab = getHeadingLink(hash.substring(1));
              link.setAttribute('href', hashWithTab);
            }
          }
        });
      }
    }
  }, [content]);

  return (
    <>
      <CanvasTabs model={model} {...elemProps}>
        <TabsListContainer>
          <CanvasTabs.List
            className="nav-tabs"
            overflowButton={<CanvasTabs.OverflowButton>More</CanvasTabs.OverflowButton>}
          >
            {(item: {id: string; text: string}) => (
              <CanvasTabs.Item
                key={item.id}
                data-id={item.id}
                onClick={() => handleTabClick(item.id)}
              >
                {item.text}
              </CanvasTabs.Item>
            )}
          </CanvasTabs.List>
          <CanvasTabs.Menu.Popper>
            <CanvasTabs.Menu.Card maxWidth={300} maxHeight={200}>
              <CanvasTabs.Menu.List>
                {(item: {id: string; text: string}) => (
                  <CanvasTabs.Menu.Item data-id={item.id}>{item.text}</CanvasTabs.Menu.Item>
                )}
              </CanvasTabs.Menu.List>
            </CanvasTabs.Menu.Card>
          </CanvasTabs.Menu.Popper>
        </TabsListContainer>
        {content && (
          <Media greaterThanOrEqual="xl">
            <AnchorNav content={content} headingDepth={anchorNavDepth} />
          </Media>
        )}
        {children}
      </CanvasTabs>
      <script
        dangerouslySetInnerHTML={{
          // Attempt to change the active tab before the browser has painted to prevent content
          // flashing when the user has refreshed or shared a link with a tab in the location.hash
          // fragment. This script manually changes the `hidden` attribute of `[role=tabpanel]`
          // based on de-slugging the location.hash, but only if the active tab was found. This
          // script is defined immediately after tabs to minimize chances of a paint slipping
          // through to avoid flashing. Using a script tag for SSR.
          __html: `
(function () {
  var h = location.hash.replace(/^#/, '').split('&').reduce(function(r, i){
    if (!i) { return {} }
    var p = i.split('=');

    r[p[0]] = p[1];
    return r;
  }, {});

  var aT = h['tab']?.replace(/(^[a-z]|-[a-z])/g, function(l) { return l.replace('-', '').toUpperCase() });

  var t = document.querySelector('[role=tab][data-id="'+aT+'"]');
  if (t) {
    var tPs = [];
    t.parentElement.querySelectorAll('[role=tab]').forEach(function(tab) {
      var tP = document.querySelector('[role=tabpanel][aria-labelledby="'+tab.getAttribute('id')+'"]');
      tPs.push(tP);
    })
    if (t === tPs[0]) {return}

    tPs.forEach(function(e) { if (e) {e.setAttribute('hidden', '')}});

    var p = document.querySelector('[role=tabpanel][aria-labelledby="'+t.getAttribute('id')+'"]');
    p && p.removeAttribute('hidden');
  }
  })()
            `,
        }}
      />
    </>
  );
};

export default Tabs;
