import React from 'react';
import {withPrefix} from 'gatsby';
import {space, colors, type} from '@workday/canvas-kit-react/tokens';
import {Heading, Subtext} from '@workday/canvas-kit-react/text';
import SidebarItem from './SidebarItem';
import {Hierarchy} from '../../utils/useHierarchy';
import {NavHierarchy} from '../../utils/navigation';
import {globalHistory} from '@reach/router';
import uuid from 'uuid/v4';

export interface SidebarSection {
  currentPage: Hierarchy;
  items: NavHierarchy[];
  onItemClick: (item: NavHierarchy, e: React.MouseEvent<HTMLAnchorElement>) => void;
  title?: string;
}

// MAX_DEPTH prevents items from rendering in the sidebar navigation beyond a
// certain depth (zero-indexed)
const MAX_DEPTH = 3;

// Listing Pages are pages which live alongside a folder of the same name in
// the file system. For example, upgrade-guides.mdx lives alongside an
// /upgrade-guides folder. These pages contain a list of links to the pages
// contained in the folder of the same name. The pages in the folder should NOT
// be displayed in the sidebar navigation.
//
// Note that if a page is at the MAX_DEPTH and has a folder of the same name,
// it will automatically be treated as a Listing Page and does not need to be
// added to LISTING_PAGE (e.g., getting-started > for-developers >  resources).
const LISTING_PAGES = ['Announcements', 'Upgrade Guides'];

const isSelected = (path: string, currentPath: string) => {
  return withPrefix(path) === withPrefix(currentPath).replace(/(.+)\/$/, (m, p) => p); // Remove trailing slash unless currentPath == '/'
};

const renderItemTitle = (title: string, level: number) => {
  switch (level) {
    case 0:
      return <h3>{title}</h3>;
    case 1:
      return <h4>{title}</h4>;
    default:
      return title;
  }
};

export const SidebarSection = ({currentPage, items, title, onItemClick}: SidebarSection) => {
  if (!items.length) {
    return null;
  }

  const selectedItemRef = React.useRef<HTMLAnchorElement>(null);

  // TODO: Ugly, but it works. Clean this up if we end up using this
  // functionality.
  //
  // Scrolls the Sidebar to the corresponding nav item if we just
  // loaded a new page (in case the nav item is currently out of view).
  //
  // Source: https://github.com/reach/router/issues/262
  React.useEffect(() => {
    let timer: ReturnType<typeof setTimeout>;

    const globalHistoryCleanup = globalHistory.listen(({action}) => {
      if (action === 'PUSH') {
        timer = setTimeout(() => {
          if (selectedItemRef && selectedItemRef.current !== null) {
            if (selectedItemRef.current.scrollIntoViewIfNeeded) {
              selectedItemRef.current.scrollIntoViewIfNeeded();
            }
          }
        }, 100);
      }
    });

    return () => {
      globalHistoryCleanup();
      clearTimeout(timer);
    };
  }, []);

  const Section = ({
    expanded = false,
    expandableId,
    items,
    level = 0,
  }: {
    expanded?: boolean;
    expandableId?: string;
    items: NavHierarchy[];
    level?: number;
  }) => {
    if (level > MAX_DEPTH) {
      return null;
    }

    return (
      <ul
        id={expandableId ? `expandable-content-${expandableId}` : undefined}
        hidden={expandableId ? !expanded : undefined}
        style={{listStyleType: 'none'}}
      >
        {items?.map(child => {
          if (!child || (child.level > MAX_DEPTH && !child.isPage)) {
            return null;
          }

          let item;

          // If the child is at the max depth and has children but a page/file
          // exists for it, OR if the child is a Listing Page, don't render the
          // children and render a link to the page instead.
          if (
            (child.level > MAX_DEPTH && child.isPage) ||
            LISTING_PAGES.indexOf(child.title) !== -1
          ) {
            item = JSON.parse(JSON.stringify(child));
            delete item.children;
            delete item.expanded;
          } else {
            item = child;
          }

          const expandableContentId = item.children ? uuid() : undefined;

          return (
            <li key={item.path}>
              <>
                <SidebarItem
                  to={item.path}
                  icon={item.icon}
                  indent={level as 0 | 1 | 2 | 3}
                  // Note: for the Patterns Overview item (with an `item.path`
                  // of `/patterns/overview`), `currentPage` will be equal to
                  // the Patterns Overview page (and thus `currentPage.path`
                  // will also be `/patterns/overview`) if the current page is
                  // anywhere within `/patterns`. This means the single
                  // Patterns item in the sidenav will be selected for any page
                  // within `/patterns`: Pattern Overview, Pattern Detail, and
                  // Product Example detail.
                  selected={isSelected(item.path, currentPage.path)}
                  onClick={onItemClick.bind(undefined, item)}
                  expanded={item.expanded}
                  contentId={expandableContentId}
                  isParent={item.children}
                >
                  {renderItemTitle(item.title, level)}
                  {item.description && (
                    <Subtext size="medium" className={'category-description'}>
                      {item.description}
                    </Subtext>
                  )}
                </SidebarItem>
                {item.children && (
                  <Section
                    items={item.children}
                    level={level + 1}
                    expandableId={expandableContentId}
                    expanded={item.expanded}
                  />
                )}
              </>
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <>
      {title && title != '_' && (
        <Heading
          {...type.levels.subtext.large}
          as="h2"
          size="small"
          variant="hint"
          fontWeight={500}
          textTransform="uppercase"
          lineHeight="56px"
          paddingTop={space.xxs}
          margin={`0 ${space.m}`}
          borderTop={`1px solid ${colors.soap500}`}
        >
          {title}
        </Heading>
      )}
      <Section items={items} />
    </>
  );
};

export default SidebarSection;
