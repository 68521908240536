import React from 'react';
import {withPrefix} from 'gatsby';
import {space} from '@workday/canvas-kit-react/tokens';
import {Breadcrumbs as CanvasBreadcrumbs} from '@workday/canvas-kit-react/breadcrumbs';

export type Breadcrumb = {name: string; to?: string};
export interface BreadcrumbsProps {
  items: Breadcrumb[];
}

export const Breadcrumbs = ({items, ...elemProps}: BreadcrumbsProps) => {
  return (
    // Eliminate top margin of any H2 directly following Breadcrumbs (see
    // Announcements pages for Previous Releases)
    <CanvasBreadcrumbs
      aria-label="Breadcrumb"
      css={{marginBottom: space.m, '& + h2': {marginTop: 0}}}
      {...elemProps}
    >
      <CanvasBreadcrumbs.List>
        {items.map(item =>
          item.to ? (
            <CanvasBreadcrumbs.Item>
              {/*
                TODO: Figure out why navigate is taking us to weird URLs with
                multiple path prefixes (looks like the same issue we're having
                in content/Link.tsx)
              */}
              <CanvasBreadcrumbs.Link
                href={withPrefix(item.to)} /*onAction={href => navigate(href)}*/
              >
                {item.name}
              </CanvasBreadcrumbs.Link>
            </CanvasBreadcrumbs.Item>
          ) : (
            <CanvasBreadcrumbs.CurrentItem>{item.name}</CanvasBreadcrumbs.CurrentItem>
          )
        )}
      </CanvasBreadcrumbs.List>
    </CanvasBreadcrumbs>
  );
};
