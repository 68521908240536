import React from 'react';
import IconEmptyState from './IconEmptyState';
import IconGroup from './IconGroup';
import SelectedIconPopup from './SelectedIconPopup';
import {CanvasIcon} from '@workday/design-assets-types';
import {usePopupModel} from '@workday/canvas-kit-react/popup';

const IconList = ({icons, clearSearch}) => {
  const [selectedIcon, setSelectedIcon] = React.useState<CanvasIcon | null>(null);

  const selectedIconRef = React.useRef<HTMLDivElement>(null);

  const selectedPopupModel = usePopupModel({
    returnFocusRef: selectedIconRef,
    onHide: e => {
      setSelectedIcon(null);
    },
  });

  const handleIconButtonClickEvent = (e: React.MouseEvent, icon: CanvasIcon) => {
    selectedIconRef.current = e.currentTarget;
    setSelectedIcon(icon);
    selectedPopupModel.events.show();
  };

  const categoryNames = Object.keys(icons);

  return React.useMemo(
    () => (
      <div>
        {categoryNames.length === 0 && <IconEmptyState clearSearch={clearSearch} />}
        {categoryNames.length === 1 && icons['All'] ? (
          <IconGroup
            icons={icons['All']}
            selectedIcon={selectedIcon}
            handleIconButtonClickEvent={handleIconButtonClickEvent}
          />
        ) : (
          categoryNames.map(
            category =>
              icons[category] && (
                <IconGroup
                  icons={icons[category]}
                  category={category}
                  key={category}
                  selectedIcon={selectedIcon}
                  handleIconButtonClickEvent={handleIconButtonClickEvent}
                />
              )
          )
        )}
        <SelectedIconPopup model={selectedPopupModel} icon={selectedIcon} />
      </div>
    ),
    [categoryNames]
  );
};

export default IconList;
