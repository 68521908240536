import React from 'react';
import {Global, css} from '@emotion/react';
import {withPrefix} from 'gatsby';

import {CanvasProvider} from '@workday/canvas-kit-react/common';
import {Box, Flex} from '@workday/canvas-kit-react/layout';
import {fonts} from '@workday/canvas-kit-react-fonts';

import {Footer} from './Footer';
import Header from './Header';
import CookieConsent from './CookieConsent';
import {Media, MediaContextProvider, mediaStyles} from './Media';
import SEO from './SEO';
import {StatusRegionProvider} from './StatusRegion';
import reset from './reset';
import ContentContainer from './content/ContentContainer';
import {NavigationProvider} from './nav/Navigation';
// TODO-P2-RESPONSIVE: Rename SidebarNew once we implement proper responsive
// web nav for Phase 2 and get rid of Sidebar (the original one)
import {SidebarNew} from './nav/SidebarNew';
import TopJumpLink from './nav/TopJumpLink';
import SkipToContent from './nav/SkipToContent';
import {TemplateFrontmatter} from './templates/types';
import './layout.css';

import {
  headerHeight,
  mqGreaterThanOrEqual,
  sidebarWidthCollapsed,
  sidebarWidthExpanded,
  viewportRanges,
} from '../utils/breakpoints';
import {getElementScrollY} from '../utils/htmlElements';
import {Location} from '../utils/types';
import {getFragmentElement} from '../utils/urlFragment';
import {useSiteMetadata} from '../utils/useSiteMetadata';
import {PlatformSwitcherProvider} from './PlatformSwitcher';

interface FlexContainerProps {
  children: React.ReactNode;
  location: Location;
  centerFooter?: boolean;
}

// Choosing NOT to use fresnel to render the FlexContainer since it seems
// overly expensive to re-render the entire ContentContainer when we resize to
// a smaller viewport size. Instead, we could just not render the Sidebar. This
// also prevents us from losing our scroll position on the page when we resize
// to a smaller size.
const FlexContainer = ({children, location, centerFooter}: FlexContainerProps) => {
  // Set default windowWidth to a size at which the Sidebar renders (since it
  // will be hidden via a media query at small sizes if necessary). Otherwise,
  // at larger sizes, we would see an empty space where the Sidebar should be
  // before the Sidebar popped into view after the page finished loading.
  const [windowWidth, setWindowWidth] = React.useState(
    typeof window !== 'undefined' ? window.innerWidth : viewportRanges.l[0]
  );

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const currentPath = (location && location.pathname) || '/';

  const flexStyles = {
    [mqGreaterThanOrEqual('l')]: {
      paddingLeft: currentPath === withPrefix('/') ? sidebarWidthCollapsed : sidebarWidthExpanded,
    },
  };

  // TODO-P2-RESPONSIVE: Figure out what to do with this once we implement
  // proper responsive web nav for Phase 2. It was being applied to the old
  // Sidebar (<Sidebar css={sidebarStyles} />)
  //
  // const sidebarStyles = {
  //   // Ensure the Sidebar is hidden during the initial load of the SSR page on
  //   // smaller screen sizes. Once the page has been rehydrated, the Sidebar
  //   // should render conditionally correctly.
  //   [mqLessThan('l')]: {
  //     display: 'none',
  //   },
  // };

  const isSidebarVisible = windowWidth >= viewportRanges.l[0];

  return (
    <NavigationProvider currentPath={currentPath}>
      <Flex paddingTop={headerHeight} css={flexStyles}>
        {isSidebarVisible && <SidebarNew />}
        <Box flexGrow={1}>
          <ContentContainer role="main">{children}</ContentContainer>
          <Footer center={centerFooter} />
        </Box>
      </Flex>
    </NavigationProvider>
  );
};

export interface LayoutProps {
  children: React.ReactElement;
  location: Location;
  pageContext: {frontmatter: TemplateFrontmatter};
  path: string;
}

const scrollToFragmentElement = () => {
  // This rAF call is a bit of a magic bullet. Without it, a fresh page load of
  // a URL with a tab and heading will not scroll to the heading (measurements
  // will be taken too early) and clicking a simple #id link will display the
  // #id element behind the page header (the custom scrolling behavior below
  // will be overridden by the default browser scrolling behavior).
  requestAnimationFrame(() => {
    const elem = getFragmentElement();
    if (elem) {
      window.scrollTo({
        left: 0,
        top: getElementScrollY(elem),
      });

      // We need to focus the scrolled-to element as per a11y requirements.
      // First, if the element doesn't already have a tabIndex, add a
      // self-destructing tabIndex so the element is focusable (the `once`
      // option for addEventListener would be a simpler way to remove the
      // tabIndex on blur, but it's not supported by IE).
      if (!elem.hasAttribute('tabIndex')) {
        const handleBlur = () => {
          elem.removeAttribute('tabIndex');
          elem.removeEventListener('blur', handleBlur);
        };
        elem.setAttribute('tabIndex', '-1');
        elem.addEventListener('blur', handleBlur);
      }

      // Focus the scrolled-to element. Calling focus alone won't work. The
      // focus can interrupt the smooth scroll to the destination header.
      // setTimeout is unreliable because we don't know how long it will take
      // to scroll to the element. Instead, we use preventScroll. (TODO:
      // preventScroll is not supported in IE or Safari. Address this later,
      // if necessary)
      elem.focus({preventScroll: true});
    }
  });
};

const handlePopState = () => {
  // This scrollToFragmentElement scrolls to the fragment element when the user
  // clicks intra-PAGE links (such as AnchorNav links) or navigates browser
  // history using the back button. Following intra-SITE links to other pages
  // on the site does NOT trigger the popstate event, however. Scrolling for
  // those links is handled separately in the Layout useEffect.
  scrollToFragmentElement();
};

const Layout = ({children, location, pageContext: {frontmatter}, path}: LayoutProps) => {
  const metadata = useSiteMetadata();

  React.useEffect(() => {
    // This scrollToFragmentElement scrolls to the fragment element on a fresh
    // load of the page (i.e., user copy-and-pasted a link to the page into
    // their browser, followed a link from another app or site, or refreshed
    // the page).
    //
    // It also handles scrolling for intra-SITE links. Unlike intra-page links
    // (such as AnchorNav links) which trigger the popstate event, following
    // intra-site links to other pages on the site does NOT trigger the
    // popstate event. For those cases, we perform the scrolling here when
    // location.pathname changes.
    scrollToFragmentElement();
  }, [location.pathname]);

  React.useEffect(() => {
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  const centerFooter = frontmatter ? frontmatter.centerFooter : undefined;
  const suppressTopJump = frontmatter ? frontmatter.suppressTopJump : undefined;

  return (
    <MediaContextProvider>
      <StatusRegionProvider>
        <CanvasProvider>
          <PlatformSwitcherProvider>
            <Global styles={css(fonts)} />
            <Global styles={css(reset)} />
            <Global styles={css(mediaStyles)} />
            <SEO {...frontmatter} path={path} />
            <SkipToContent />
            <Header location={location} siteTitle={metadata.title} />
            <FlexContainer location={location} centerFooter={centerFooter}>
              {children}
            </FlexContainer>
            <Media greaterThanOrEqual="xl">
              <TopJumpLink suppress={suppressTopJump}>Back to Top</TopJumpLink>
            </Media>
            <CookieConsent />
          </PlatformSwitcherProvider>
        </CanvasProvider>
      </StatusRegionProvider>
    </MediaContextProvider>
  );
};

export default Layout;
