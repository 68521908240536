import React from 'react';
import {CSSObject} from '@emotion/styled';
import {default as Combobox, Theme} from 'react-select';
import {colors, inputColors, borderRadius, space, type} from '@workday/canvas-kit-react/tokens';
import IconList from './IconList';
// import IconPopup from './IconPopup.js';
import {IconType, getIconList, searchIcons} from './utils';

const customComboboxTheme = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: borderRadius.m,
  colors: {
    ...theme.colors,
    primary25: colors.blueberry100,
    primary50: colors.blueberry200,
    primary: colors.blueberry400,
    neutral20: inputColors.border,
    neutral30: inputColors.hoverBorder,
    danger: colors.frenchVanilla100,
    dangerLight: inputColors.error.border,
  },
});

const mixinProvided = (styles: CSSObject) => (provided: CSSObject) => ({
  ...provided,
  ...styles,
});

const customComboboxStyles: {
  [component: string]: CSSObject | ((provided: CSSObject) => CSSObject);
} = {
  container: mixinProvided({
    ...type.levels.body.small,
  }),
  multiValue: mixinProvided({
    borderRadius: borderRadius.m,
  }),
  multiValueRemove: mixinProvided({
    borderRadius: `0 ${borderRadius.m} ${borderRadius.m} 0`,
    cursor: 'pointer',
    transition: 'background-color 0.1s ease-out',
  }),
  menu: mixinProvided({
    zIndex: 1000,
  }),
  option: mixinProvided({
    cursor: 'pointer',
  }),
  clearIndicator: mixinProvided({
    cursor: 'pointer',
  }),
  dropdownIndicator: mixinProvided({
    cursor: 'pointer',
  }),
};

const scrollToTop = () =>
  window.scrollTo({
    left: 0,
    top: 0,
  });

type ComboboxOption = {value: string; label: string};

export const SearchableIconList = ({iconType}: {iconType: IconType}) => {
  const [query, setQuery] = React.useState('');
  const [filters, setFilters] = React.useState<ComboboxOption[]>([]);

  const iconList = getIconList(iconType);
  const groupedIcons = searchIcons(query, iconList);

  const search = (query: string, {action}: {action: string}) => {
    if (action === 'input-change' || action === 'set-value') {
      scrollToTop();
      setQuery(query);
    }
  };

  const filter = (categories: ComboboxOption[]) => {
    scrollToTop();
    setFilters(categories);
  };

  let filteredIcons: {[category: string]: typeof iconList} = {};

  if (filters && filters.length > 0) {
    filters.forEach(category => {
      filteredIcons[category.label] = groupedIcons[category.label];
    });
    filteredIcons = Object.keys(filteredIcons)
      .sort()
      .reduce((obj, key) => {
        obj[key] = filteredIcons[key];
        return obj;
      }, {} as typeof groupedIcons);
  } else {
    filteredIcons = groupedIcons;
  }

  return (
    <>
      <Combobox
        inputValue={query}
        onInputChange={search}
        onChange={filter}
        isMulti={true}
        closeMenuOnSelect={false}
        menuPlacement="top"
        placeholder="Search and filter"
        noOptionsMessage={() => 'No matching categories'}
        options={Object.keys(groupedIcons).map(category => ({
          value: category,
          label: category,
        }))}
        theme={customComboboxTheme}
        styles={customComboboxStyles}
        css={{margin: `${space.l} 0 ${space.s}`}}
      />
      <IconList icons={filteredIcons} clearSearch={() => setQuery('')} />
    </>
  );
};

export default SearchableIconList;
