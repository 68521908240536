import React from 'react';
import {withPrefix} from 'gatsby';
import {space, colors, borderRadius, depth, type} from '@workday/canvas-kit-react/tokens';
import {Box} from '@workday/canvas-kit-react/layout';
import {BodyText, Card, Skeleton, Subtext} from '@workday/canvas-kit-react';
import {Pill, StatusIndicator} from '@workday/canvas-kit-preview-react';
import {WPLPattern, WPLSubmission} from '../../utils/types';

export interface SubmissionCardProps {
  submission: WPLSubmission;
}

export interface PatternCardProps {
  id: number;
  title: string;
  category: string;
  length: number;
  href: string;
}

export const SubmissionLoadingCard = ({...elemProps}) => (
  <Card
    padding="m"
    paddingBottom="xxs"
    css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    }}
    {...elemProps}
  >
    <Card.Body>
      <Skeleton.Shape height={142} css={{borderRadius: borderRadius.l}} />
      <Skeleton.Header height={28} css={{marginTop: space.m}} />
      <Skeleton.Text />
    </Card.Body>
    <Box borderTop={`1px solid ${colors.soap400}`} marginTop={86} paddingTop="s">
      <Skeleton.Text
        lineCount={1}
        css={{marginBottom: 0, width: '142px', '& > div': {height: '36px'}}}
      />
    </Box>
  </Card>
);

const renderFirstImage = (images: string) => {
  const image = JSON.parse(images)[0];
  return (
    <img
      src={image.url}
      alt={image.altText}
      height={140}
      width="100%"
      css={{
        border: `1px solid ${colors.frenchVanilla200}`,
        borderRadius: borderRadius.l,
        display: 'block',
        objectFit: 'cover',
      }}
    />
  );
};

export const SubmissionCard = ({submission}: SubmissionCardProps) => (
  // TODO: using Link from gatsby-link spreads a bunch of CSS properties as
  // attributes onto the Card element, figure out why (using <a> in the meantime)
  <Card
    key={submission.id}
    as="a"
    href={withPrefix(`/patterns/examples/${submission.id}`)}
    padding="m"
    paddingBottom="xxs"
    css={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textDecoration: 'none',
      '&:hover': {...depth[2]},
    }}
  >
    <Box>
      {renderFirstImage(submission.images)}
      <Card.Heading marginTop="m">{submission.title}</Card.Heading>
      <Card.Body>
        <Subtext size="small" color="licorice300">
          Product Pillar
        </Subtext>
        <Subtext size="large" fontWeight="medium">
          {submission.product_pillar}
        </Subtext>
        <Subtext size="small" color="licorice300" marginTop="xxs">
          Patterns
        </Subtext>
        <Box>
          {submission.patterns.map((pattern: WPLPattern) => (
            <Pill key={pattern.title} variant="readOnly" marginRight="xxs" marginBottom="xxs">
              <Pill.Label>{pattern.title}</Pill.Label>
            </Pill>
          ))}
        </Box>
      </Card.Body>
    </Box>
    <Box borderTop={`1px solid ${colors.soap400}`} marginTop="xxxs" paddingTop="s">
      <BodyText
        size="small"
        color="blueberry400"
        fontWeight="bold"
        paddingY="xs"
        paddingX="xxs"
        textDecoration="underline"
      >
        View
      </BodyText>
    </Box>
  </Card>
);

export const PatternCard = ({title, category, length, href}: PatternCardProps) => (
  // TODO: using Link from gatsby-link spreads a bunch of CSS properties as
  // attributes onto the Card element, figure out why (using <a> in the meantime)
  <Card as="a" href={href} padding="xs" css={{textDecoration: 'none', '&:hover': {...depth[2]}}}>
    <Card.Heading
      css={{...type.levels.body.small, fontWeight: type.properties.fontWeights.medium}}
      marginBottom="xxxs"
    >
      {title}
    </Card.Heading>
    <Card.Body>
      <StatusIndicator variant="blue" marginBottom="xxxs">
        <StatusIndicator.Label>{category}</StatusIndicator.Label>
      </StatusIndicator>
      <Subtext size="small">
        {length} Product example{length > 1 ? 's' : ''}
      </Subtext>
    </Card.Body>
  </Card>
);

export const PatternLoadingCard = ({...elemProps}) => (
  <Card padding="xs" paddingBottom="xxs" {...elemProps}>
    <Card.Body>
      <Skeleton.Header height={18} css={{marginBottom: space.xxxs}} />
      <Skeleton.Text css={{marginBottom: 0, '& > div': {marginBottom: space.xxxs}}} />
    </Card.Body>
  </Card>
);
