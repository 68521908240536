import React from 'react';
import styled from '@emotion/styled';
import {ColorSet, ColorSetProps} from './ColorSet';
import {space} from '@workday/canvas-kit-react/tokens';

const Grid = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  fontWeight: 600,
  marginTop: space.xs,
});

export const ColorGrid = ({colors}: {colors: {rows: ColorSetProps['set'][]}}) => (
  <Grid className="exclude-anchor-nav">
    {colors.rows.map((set: ColorSetProps['set'], index: number) => {
      return <ColorSet set={set} key={index} />;
    })}
  </Grid>
);

export default ColorGrid;
