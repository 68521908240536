import React from 'react';

import {space} from '@workday/canvas-kit-react/tokens';
import {Keyline} from '../content/Keyline';
import {TemplateFrontmatter} from './types';
import {Media} from '../Media';
import {ContentPreamble} from '../content/ContentPreamble';
import {ContentBody} from '../content/ContentBody';
import {FAQNoticeCard} from '../content/NoticeCard';
import AnchorNav from '../nav/AnchorNav';
import {PackageInfo} from '../content/PackageInfo';

export interface DefaultTemplateProps {
  children: React.ReactNode;
  frontmatter: TemplateFrontmatter;
}

export const DefaultTemplate = ({children, frontmatter}: DefaultTemplateProps) => {
  const [content, setContent] = React.useState<HTMLElement | null>(null);
  const ref = React.useRef<HTMLDivElement>(null);

  // Using [setContent] as the dependency array since setContent is a stable
  // reference (same on every render) which means the effect will only be run
  // once. This is preferred over using an empty dependency array to keep
  // the missing dependency ESLint rule (react-hooks/exhaustive-deps) happy.
  // We don't currently use this ESLint rule in this project, but we may add it
  // in the future.
  React.useEffect(() => {
    setContent(ref.current);
  }, [setContent]);

  return (
    <>
      {frontmatter.preamble && (
        <ContentPreamble>
          {frontmatter.keyline && (
            <Keyline css={{marginTop: 0, marginBottom: space.l}}>{frontmatter.keyline}</Keyline>
          )}
          <ContentPreamble.Title>{frontmatter.title}</ContentPreamble.Title>
          {frontmatter.description && (
            <ContentPreamble.Introduction>{frontmatter.description}</ContentPreamble.Introduction>
          )}
          {frontmatter.component && (
            <PackageInfo
              inPreamble
              figma={frontmatter.component.figma}
              github={frontmatter.component.github}
              storybook={frontmatter.component.storybook}
              package={frontmatter.component.package}
              web={frontmatter.component.web}
            />
          )}
        </ContentPreamble>
      )}
      <ContentBody>
        <Media greaterThanOrEqual="xl">
          <AnchorNav content={content} headingDepth={frontmatter.anchorNavDepth} />
        </Media>
        <div ref={ref}>{children}</div>
        <FAQNoticeCard pageTitle={frontmatter ? frontmatter.title : undefined} />
      </ContentBody>
    </>
  );
};
