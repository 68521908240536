import React from 'react';
import _ from 'lodash';
import {DeprecatedCookieBanner} from './deprecated/cookie-banner';

function getCookie(name: string) {
  const dc = document.cookie;
  const prefix = name + '=';
  let begin = dc.indexOf('; ' + prefix);
  let end;
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) {
      return null;
    }
  } else {
    begin += 2;
    end = document.cookie.indexOf(';', begin);
    if (end == -1) {
      end = dc.length;
    }
  }
  return decodeURI(dc.substring(begin + prefix.length, end));
}

const CookieConsent = (): React.ReactElement => {
  const [gaveConsent, setGaveConsent] = React.useState<boolean>(true);

  React.useEffect(() => {
    const consent = getCookie('mtm_consent');
    setGaveConsent(_.isNil(consent) ? false : true);
  }, []);

  const onCookieAccept = () => {
    window._paq?.push(['rememberConsentGiven']);
    setGaveConsent(true);
  };

  return <DeprecatedCookieBanner onAccept={onCookieAccept} isClosed={gaveConsent} />;
};

export default CookieConsent;
