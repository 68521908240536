import React from 'react';
import {CSSObject} from '@emotion/styled';
import {Flex, FlexProps} from '@workday/canvas-kit-react/layout';
import {space} from '@workday/canvas-kit-react/tokens';
import {
  MQFnSizeParam,
  ViewportSize,
  combineMediaQueries,
  mqAt,
  mqLessThan,
  mqGreaterThanOrEqual,
} from '../../utils/breakpoints';

type Spacing = number | string;

export interface ResponsiveFlexProps extends FlexProps {
  children: React.ReactNode;
  columnIfLessThan: MQFnSizeParam;
  equalChildren?: boolean;
  sSpacing?: Spacing;
  mSpacing?: Spacing;
  lSpacing?: Spacing;
  xlSpacing?: Spacing;
  xxlSpacing?: Spacing;
}

const getResponsiveFlexMediaQuery = (
  size: ViewportSize,
  spacings: {[key in ViewportSize]: Spacing}
) => {
  return {
    [mqAt(size)]: {
      '& > :not(:first-of-type)': {
        marginTop: spacings[size],
        marginLeft: spacings[size],
      },
    },
  };
};

export const ResponsiveFlex = ({
  children,
  columnIfLessThan,
  equalChildren = true,
  sSpacing = space.l,
  mSpacing = space.l,
  lSpacing = space.l,
  xlSpacing = space.l,
  xxlSpacing = space.xl,
  ...elemProps
}: ResponsiveFlexProps) => {
  const styles: CSSObject = {
    ...(equalChildren && {
      '& > *': {
        flex: '1 0 0',
      },
    }),

    // Generate media queries for each viewport size with top and left
    // margins for ResponsiveFlex children...
    ...combineMediaQueries(getResponsiveFlexMediaQuery, {
      s: sSpacing,
      m: mSpacing,
      l: lSpacing,
      xl: xlSpacing,
      xxl: xxlSpacing,
    }),

    // ...and wipe out left margins when flexDirection is column...
    [mqLessThan(columnIfLessThan)]: {
      flexDirection: 'column',
      '& > :not(:first-of-type)': {
        marginLeft: 0,
      },
    },

    // ...or wipe out top margins if flexDirection is row.
    [mqGreaterThanOrEqual(columnIfLessThan)]: {
      '& > :not(:first-of-type)': {
        marginTop: 0,
      },
    },
  };

  return (
    <Flex css={styles} {...elemProps}>
      {children}
    </Flex>
  );
};
