import React from 'react';
import {withPrefix} from 'gatsby';
import {gql, useQuery} from '@apollo/client';
import {SecondaryButton, TertiaryButton} from '@workday/canvas-kit-react/button';
import {Flex, Grid} from '@workday/canvas-kit-react/layout';
import {type, space} from '@workday/canvas-kit-react/tokens';
import {arrowLeftIcon, linkIcon} from '@workday/canvas-system-icons-web';
import {StatusIndicator} from '@workday/canvas-kit-preview-react/status-indicator';
import {Subtext} from '@workday/canvas-kit-react/text';
import {TemplateFrontmatter} from './types';
import {ContentPreamble} from '../content/ContentPreamble';
import {ContentBody} from '../content/ContentBody';
import {FAQNoticeCard} from '../content/NoticeCard';
import Tabs from '../nav/Tabs';
import {useSiteMetadata} from '../../utils/useSiteMetadata';
import {lastPatternTypeKey, persistToSessionStorage} from '../../utils/sessionStorage';
import {WPLPattern} from '../../utils/types';

export type Tab = string | {name: string; internal?: boolean; external?: boolean};

export interface DocumentedPatternTemplateFrontmatter extends TemplateFrontmatter {
  tabs: Tab[];
  internaltabs: string[];
  alias: string[];
  patternID: number;
  published: string;
  updated: string;
}

export interface DocumentedPatternTemplate {
  children: JSX.Element | JSX.Element[];
  frontmatter: DocumentedPatternTemplateFrontmatter;
}

interface GetSubmissionsCountGqlResponse {
  countSubmissions: number;
}

interface GetPatternGqlResponse {
  getPattern: WPLPattern;
}

const GET_PATTERN = gql`
  query GetPattern($where: PatternWhereUniqueInput = null) {
    getPattern(where: $where) {
      id
      title
      category {
        name
      }
    }
  }
`;

const COUNT_SUBMISSIONS = gql`
  query CountSubmission($where: SubmissionWhereInput = null) {
    countSubmissions(where: $where)
  }
`;

export const DocumentedPatternTemplate = ({children, frontmatter}: DocumentedPatternTemplate) => {
  const [submissionCount, setSubmissionCount] = React.useState<number>(0);
  const [pattern, setPattern] = React.useState<WPLPattern | undefined>(undefined);

  useQuery<GetPatternGqlResponse>(GET_PATTERN, {
    variables: {
      where: {id: frontmatter.patternID},
    },
    onCompleted: data => {
      const {getPattern} = data;
      setPattern(getPattern);
    },
    onError: args => {
      console.log('error', args);
    },
  });

  useQuery<GetSubmissionsCountGqlResponse>(COUNT_SUBMISSIONS, {
    variables: {
      where: {
        patterns: {
          some: {
            id: {
              equals: frontmatter.patternID,
            },
          },
        },
      },
    },
    onCompleted: data => {
      const {countSubmissions} = data;
      setSubmissionCount(countSubmissions);
    },
    onError: args => {
      console.log('error', args);
    },
  });

  React.useEffect(() => {
    persistToSessionStorage(lastPatternTypeKey, 'documented');
  }, []);

  const config = useSiteMetadata();

  const tabs = frontmatter.tabs.reduce((runningTabs: string[], tab: Tab) => {
    if (typeof tab === 'string') {
      runningTabs.push(tab);
    } else if (
      tab.name &&
      ((tab.internal === undefined && tab.external === undefined) ||
        (tab.internal && config.internal) ||
        (tab.external && !config.internal))
    ) {
      runningTabs.push(tab.name);
    }

    return runningTabs;
  }, []);

  return (
    <>
      <ContentPreamble bodyHasTabs={true}>
        <Flex justifyContent="space-between">
          <TertiaryButton
            icon={arrowLeftIcon}
            iconPosition="start"
            as="a"
            href={withPrefix('/patterns/patterns')}
          >
            Back
          </TertiaryButton>
          <SecondaryButton
            icon={linkIcon}
            iconPosition="start"
            onClick={() => {
              navigator.clipboard.writeText(window.location.href);
            }}
          >
            Copy Link
          </SecondaryButton>
        </Flex>
        <ContentPreamble.Title>{frontmatter.title}</ContentPreamble.Title>
        {pattern && (
          <Flex alignItems="center" gap="xxs">
            <StatusIndicator variant="blue">
              <StatusIndicator.Label>{pattern.category.name}</StatusIndicator.Label>
            </StatusIndicator>
            <Subtext size="small">{`${submissionCount} Product examples`}</Subtext>
          </Flex>
        )}
        {frontmatter.alias && (
          <Subtext size="small" marginTop={space.xs}>
            Also known as
            {frontmatter.alias.map(alias => (
              <span key={alias}>&nbsp;&quot;{alias}&quot;</span>
            ))}
          </Subtext>
        )}
        {frontmatter.description && (
          <ContentPreamble.Introduction>{frontmatter.description}</ContentPreamble.Introduction>
        )}
        <Grid marginTop={space.xs} gridAutoFlow="row" gridTemplateColumns="1fr 1fr">
          {frontmatter.published && (
            <Flex flexDirection={'column'}>
              <Subtext size="small">Published</Subtext>
              <Subtext size="large" fontWeight={type.properties.fontWeights.medium}>
                {frontmatter.published}
              </Subtext>
            </Flex>
          )}
          {frontmatter.updated && (
            <Flex flexDirection={'column'}>
              <Subtext size="small">Last Updated</Subtext>
              <Subtext size="large" fontWeight={type.properties.fontWeights.medium}>
                {frontmatter.updated}
              </Subtext>
            </Flex>
          )}
        </Grid>
      </ContentPreamble>
      <ContentBody hasTabs={true}>
        {tabs && (
          <Tabs tabNames={tabs} anchorNavDepth={frontmatter.anchorNavDepth}>
            {children}
          </Tabs>
        )}
        <FAQNoticeCard bodyHasTabs={true} pageTitle={frontmatter ? frontmatter.title : undefined} />
      </ContentBody>
    </>
  );
};
