import React from 'react';
import {AppletIcon} from '@workday/canvas-kit-react/icon';
import {benefitsIcon} from '@workday/canvas-applet-icons-web';

export default () => (
  <>
    <AppletIcon icon={benefitsIcon} />
    <AppletIcon icon={benefitsIcon} color={AppletIcon.Colors.Pomegranate} />
    <AppletIcon icon={benefitsIcon} size={60} />
  </>
);
