import React from 'react';
import styled from '@emotion/styled';
import {colors, space} from '@workday/canvas-kit-react/tokens';
import {BodyText} from '@workday/canvas-kit-react';
import {
  exclamationTriangleIcon,
  checkCircleIcon,
  xCircleIcon,
  checkboxIcon,
} from '@workday/canvas-system-icons-web';
import {SystemIcon} from '@workday/canvas-kit-react/icon';
import {CanvasSystemIcon} from '@workday/design-assets-types';

type PracticeTypes = 'do' | 'dont' | 'warn' | 'check';

interface IconConfig {
  icon: CanvasSystemIcon;
  color: string;
}

const getIconConfig = (type: PracticeTypes): IconConfig => {
  switch (type) {
    case 'do':
      return {icon: checkCircleIcon, color: colors.greenApple400};
    case 'dont':
      return {icon: xCircleIcon, color: colors.cinnamon500};
    case 'warn':
      return {icon: exclamationTriangleIcon, color: colors.cantaloupe400};
    case 'check':
      return {icon: checkboxIcon, color: 'rgba(190,97,246,1)'}; // need to use string for cnvs-base-extended-palette-dragon-fruit-300 until new tokens are being used
    default:
      return {icon: checkCircleIcon, color: colors.greenApple400};
  }
};

const Container = styled('ul')({
  listStyleType: 'none',
  paddingLeft: 0,
  marginTop: space.s,
  p: {
    marginTop: 0,
  },
  li: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
});

export interface BestPracticesProps {
  children: React.ReactNode;
  type: PracticeTypes;
}

export const BestPractices = ({type, children, ...elemProps}: BestPracticesProps) => {
  const {icon, color} = getIconConfig(type);
  let listOfBestPractices;

  if (
    React.isValidElement(children) &&
    children.props.children &&
    typeof children.props.children !== 'string'
  ) {
    const nestedChildren = children.props.children.length
      ? children.props.children
      : [children.props.children];

    listOfBestPractices = nestedChildren.map((item: React.ReactElement, i: number) => {
      return (
        <li
          style={{display: 'flex', gap: '8px', marginBottom: space.xxs, alignItems: 'flex-start'}}
          key={i}
        >
          <SystemIcon icon={icon} color={color} />
          <BodyText size="small">{item.props.children}</BodyText>
        </li>
      );
    });
  }
  return <Container {...elemProps}>{listOfBestPractices}</Container>;
};
