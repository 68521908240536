import React from 'react';

import {space} from '@workday/canvas-kit-react/tokens';
import {Keyline} from '../content/Keyline';
import {TemplateFrontmatter} from './types';
import {ContentPreamble} from '../content/ContentPreamble';
import {ContentBody} from '../content/ContentBody';
import PackageInfo from '../content/PackageInfo';
import Tabs from '../nav/Tabs';

// TODO: ComponentTemplate is not being used for the soft launch (no iOS and
// Android content at the time). Leaving it here to use later.

/**
 * ComponentTemplate renders an MDX file with the default tabbed navigation
 * for components: Web (and eventually iOS and Android). ComponentTemplate
 * includes support for all default frontmatter.
 *
 * In order to render an MDX file using ComponentTemplate, you must set
 * `template` to `component` and `component` to an object with the format shown
 * below. You must also include the associated named `<TabPanel>` elements for
 * Web, iOS, and Android in the body of the MDX:
 *
 * ```
 * ---
 * ...
 * template: component
 * component:
 *   {
 *     figma: FIGMA_URL,
 *     github: GITHUB_URL,
 *     storybook: STORYBOOK_URL,
 *     package: NPM_PACKAGE,
 *   }
 * ...
 * ---
 *
 * <TabPanel data-id="Web">
 *   Content for Web tab
 * </TabPanel>
 * <TabPanel data-id="iOS">
 *   Content for iOS tab
 * </TabPanel>
 * <TabPanel data-id="Android">
 *   Content for Android tab
 * </TabPanel>
 * ```
 *
 * Note that you may override the default tabs by defining `tabs` in the
 * frontmatter and adjusting the `<TabPanel>` names accordingly. For example,
 * to omit the iOS and Android tabs for a particular component:
 *
 * ```
 * ---
 * ...
 * component:
 *   {
 *     figma: FIGMA_URL,
 *     github: GITHUB_URL,
 *     storybook: STORYBOOK_URL,
 *     package: NPM_PACKAGE,
 *   }
 * tabs:
 *   - Web
 * ...
 * ---
 *
 * <TabPanel data-id="Web">
 *   Content for Web tab
 * </TabPanel>
 * ```
 */

export interface ComponentTemplateFrontmatter extends TemplateFrontmatter {
  tabs?: string[];
}

export interface ComponentTemplateProps {
  children: JSX.Element | JSX.Element[];
  frontmatter: ComponentTemplateFrontmatter;
}

export const ComponentTemplate = ({
  children,
  frontmatter,
  frontmatter: {tabs = ['Web' /*, 'iOS', 'Android'*/]},
}: ComponentTemplateProps) => (
  <>
    <ContentPreamble bodyHasTabs={true}>
      {frontmatter.keyline && (
        <Keyline css={{marginTop: 0, marginBottom: space.l}}>{frontmatter.keyline}</Keyline>
      )}
      <ContentPreamble.Title>{frontmatter.title}</ContentPreamble.Title>
      {frontmatter.description && (
        <ContentPreamble.Introduction>{frontmatter.description}</ContentPreamble.Introduction>
      )}
      {frontmatter.component && (
        <PackageInfo
          figma={frontmatter.component.figma}
          github={frontmatter.component.github}
          storybook={frontmatter.component.storybook}
          package={frontmatter.component.package}
        />
      )}
    </ContentPreamble>
    <ContentBody hasTabs={true}>
      <Tabs tabNames={tabs} anchorNavDepth={frontmatter.anchorNavDepth}>
        {children}
      </Tabs>
    </ContentBody>
  </>
);
