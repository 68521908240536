import React from 'react';
import styled from '@emotion/styled';
import {gql, useQuery} from '@apollo/client';
import {type, space} from '@workday/canvas-kit-react/tokens';
import {Flex, Grid} from '@workday/canvas-kit-react/layout';
import {Skeleton, SystemIcon} from '@workday/canvas-kit-react';
import {StatusIndicator} from '@workday/canvas-kit-preview-react';
import {
  checkIcon,
  codeIcon,
  dashboardIcon,
  deviceDesktopIcon,
} from '@workday/canvas-system-icons-web';
import {SubmissionCard, SubmissionLoadingCard} from './PatternCards';
import {H4} from '../StyledElements';
import {WPLPattern, WPLSubmission} from '../../utils/types';

export interface ImplementationDetailsProps {
  title: string;
  htmlID: string;
  tags: {
    title: string;
    color: 'blue' | 'gray';
    icon: 'check' | 'code' | 'deviceDesktop' | 'dashboard';
  }[];
  preference: string[];
}

const TitleContainer = styled(Flex)({
  '& > h4': {
    margin: 0,
    paddingRight: space.l,
    '&:hover': {
      paddingRight: 0,
    },
  },
});

const PreferenceContainer = styled(Flex)({
  '& *': {
    whiteSpace: 'nowrap',
  },
});

const Bold = styled('span')({
  fontWeight: type.properties.fontWeights.bold,
});

export const ImplementationDetails = ({
  title,
  tags,
  preference,
  htmlID,
}: ImplementationDetailsProps) => {
  return (
    <div>
      <TitleContainer
        flexDirection={'column'}
        alignItems={'flex-start'}
        justifyContent={'flex-start'}
        margin={`${space.xl} 0 ${space.s}`}
      >
        <H4 id={htmlID}>{title}</H4>
        <Flex
          alignItems={'center'}
          justifyContent={'space-between'}
          gap={space.xs}
          marginTop={space.s}
        >
          {tags.map(tag => {
            const icon =
              tag.icon === 'check'
                ? checkIcon
                : tag.icon === 'code'
                ? codeIcon
                : tag.icon === 'deviceDesktop'
                ? deviceDesktopIcon
                : dashboardIcon;
            return (
              <StatusIndicator key={tag.title} variant={tag.color}>
                <StatusIndicator.Icon icon={icon} />
                <StatusIndicator.Label>{tag.title}</StatusIndicator.Label>
              </StatusIndicator>
            );
          })}
        </Flex>
      </TitleContainer>
      <PreferenceContainer
        alignItems={'center'}
        justifyContent={'flex-start'}
        gap={`0 ${space.xs}`}
        flexWrap={'wrap'}
      >
        <Bold>Preferred because:</Bold>
        {preference.map(text => (
          <Flex as="span" key={text}>
            <SystemIcon icon={checkIcon} />
            <span>{text}</span>
          </Flex>
        ))}
      </PreferenceContainer>
    </div>
  );
};

const LIST_SUBMISSIONS = gql`
  query ListSubmissions($where: SubmissionWhereInput = null) {
    listSubmissions(where: $where) {
      id
      title
      images
      product_pillar
      patterns {
        title
      }
    }
  }
`;

const GET_PATTERN = gql`
  query GetPattern($where: PatternWhereUniqueInput = null) {
    getPattern(where: $where) {
      submissions {
        id
        title
        images
        product_pillar
        patterns {
          title
        }
      }
    }
  }
`;

const CardsGrid = ({submissions}: {submissions: WPLSubmission[] | undefined}) => {
  return submissions ? (
    <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="s" marginTop="m">
      {submissions.map((submission: WPLSubmission) => (
        <SubmissionCard key={submission.id} submission={submission} />
      ))}
    </Grid>
  ) : (
    <Skeleton>
      <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="s">
        {[...Array(4)].map((e, i) => (
          <SubmissionLoadingCard key={i} />
        ))}
      </Grid>
    </Skeleton>
  );
};

export const ProductExampleCards = ({ids}: {ids: string[]}) => {
  const [productExamples, setProductExamples] = React.useState<WPLSubmission[] | undefined>(
    undefined
  );

  useQuery<{listSubmissions: WPLSubmission[]}>(LIST_SUBMISSIONS, {
    variables: {
      where: {id: {in: ids}},
    },
    onCompleted: data => {
      const {listSubmissions} = data;
      setProductExamples(listSubmissions);
    },
    onError: args => {
      console.log('error', args);
    },
  });

  return CardsGrid({submissions: productExamples});
};

export const AllProductExampleCards = ({id}: {id: number}) => {
  const [pattern, setPattern] = React.useState<WPLPattern | undefined>(undefined);

  useQuery<{getPattern: WPLPattern}>(GET_PATTERN, {
    variables: {
      where: {id},
    },
    onCompleted: data => {
      const {getPattern} = data;
      setPattern(getPattern);
    },
    onError: args => {
      console.log('error', args);
    },
  });

  return CardsGrid({submissions: pattern && pattern.submissions});
};
